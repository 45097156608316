import { createAction } from '@reduxjs/toolkit';
import { Product } from '~redux/brand/types';

export type CartItem = {
  id?: string;
  owner: string;
  quantity: number;
  product_id: string;
  product?: Product;
  image?: string;
};

export const addCartStart = createAction('CART/ADD_CART_START');
export const addCartItem = createAction<CartItem>('CART/ADD_CART_ITEM');
export const getCartStart = createAction('CART/GET_CART_START');
export const setCartItems = createAction<CartItem[]>('CART/SET_CART_ITEMS');
export const removeCartStart = createAction('CART/REMOVE_CART_START');
export const removeCartItem = createAction<string>('CART/REMOVE_CART_ITEM');
export const getBrand = createAction<[]>('BULK/GET_BRAND');
export const setProductData = createAction<[]>('BULK/SET_PRODUCT_DATA');
export const setImage = createAction<any>('BULK/SET_IMAGE');
