import { formatDate } from './formatter';
import { AuthUser } from '~redux/auth/actions/authUser';
import { Brand } from '~redux/brand/types';

declare const window: Window['window'] & {
  dataLayer: any[];
};

type GtmEvent = {
  event: string;
  payload: any;
};

export enum GtmEvents {
  SignUp = 'sign_up',
  Login = 'login',
  UserPageView = 'user_page_view',
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  ViewItemList = 'view_item_list',
  SelectItem = 'select_item',
  ViewItem = 'view_item',
  Purchase = 'purchase',
  FollowBrand = 'follow_brand',
  UnfollowBrand = 'unfollow_brand',
}

export const pushEvent = (event: any) => {
  if (window.dataLayer) {
    window.dataLayer.push(event);
  }
};

export const pushGtmEvent = (e: GtmEvent) => {
  pushEvent({
    event: e.event,
    ...e.payload,
  });
};

export type EcommerceEventItem = {
  item_name: string;
  item_id: string;
  price: number;
  item_brand: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_variant?: string;
  item_list_name?: string;
  item_list_id?: string;
  index?: number;
  quantity: number;
};

export const pushAddToCartEvent = (
  items: EcommerceEventItem[],
  user: AuthUser
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushEvent({ ecommerce: null });
  pushGtmEvent({
    event: GtmEvents.AddToCart,
    payload: {
      ecommerce: {
        items,
      },
      user: user.attributes.email,
      user_type: user.attributes.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};

export const pushRemoveFromCartEvent = (
  items: EcommerceEventItem[],
  user: AuthUser
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushEvent({ ecommerce: null });
  pushGtmEvent({
    event: GtmEvents.RemoveFromCart,
    payload: {
      ecommerce: {
        items,
      },
      user: user.attributes.email,
      user_type: user.attributes.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};

export const pushViewItemListEvent = ({
  items,
  user,
  category,
  sub_category,
  brand,
  search_type,
  keywords,
  tags,
  sort,
}: {
  items: EcommerceEventItem[];
  user?: AuthUser;
  category?: string;
  sub_category?: string;
  brand?: string;
  search_type?: string;
  keywords?: string[];
  tags?: string[];
  sort?: string;
}) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushEvent({ ecommerce: null });
  pushGtmEvent({
    event: GtmEvents.ViewItemList,
    payload: {
      ecommerce: {
        items,
      },
      category,
      sub_category,
      brand,
      search_type,
      keywords,
      tags,
      sort,
      user: user?.attributes?.email,
      user_type: user?.attributes?.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};

export const pushSelectItemEvent = (
  items: EcommerceEventItem[],
  user: AuthUser
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushEvent({ ecommerce: null });
  pushGtmEvent({
    event: GtmEvents.SelectItem,
    payload: {
      ecommerce: {
        items,
      },
      user: user.attributes.email,
      user_type: user.attributes.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};

export const pushViewItemEvent = (
  items: EcommerceEventItem[],
  user?: AuthUser
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushEvent({ ecommerce: null });
  pushGtmEvent({
    event: GtmEvents.ViewItem,
    payload: {
      ecommerce: {
        items,
      },
      user: user?.attributes.email,
      user_type: user?.attributes.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};

export const pushPurchaseEvent = (
  orderId: string,
  value: number,
  shipping: number,
  tax: number,
  items: EcommerceEventItem[],
  user: AuthUser
) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushEvent({ ecommerce: null });
  pushGtmEvent({
    event: GtmEvents.Purchase,
    payload: {
      ecommerce: {
        transaction_id: orderId,
        value,
        shipping,
        tax,
        currency: 'JPY',
        items,
      },
      user: user.attributes.email,
      user_type: user.attributes.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};

export const pushFollowBrandEvent = (user: AuthUser, brand: Brand) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushGtmEvent({
    event: GtmEvents.FollowBrand,
    payload: {
      brand: brand.brand_name,
      category: brand.brand_category,
      user: user.attributes.email,
      user_type: user.attributes.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};

export const pushUnfollowBrandEvent = (user: AuthUser, brand: Brand) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  pushGtmEvent({
    event: GtmEvents.UnfollowBrand,
    payload: {
      brand: brand.brand_name,
      category: brand.brand_category,
      user: user.attributes.email,
      user_type: user.attributes.profile,
      timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
    },
  });
};
