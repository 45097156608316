export const searchBrands = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: SearchableBrandSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        brand_owner
        brand_name
        brand_yomigana
        brand_type
        brand_content
        brand_imageKey
        brand_icon_imageKey
        brand_logo_imageKey
        brand_publication
        brand_retail_stores_number
        brand_category
        brand_target_age
        brand_target_gender
        brand_website_url
        brand_work_with_big_box
        brand_address
        brand_minimum_buy
        brand_additional_minimum_buy
        brand_shippinng_fee
        brand_shippinng_fee_criteria
        brand_instagram_account
        brand_facebook_account
        brand_rating
        brand_annual_turnover
        brand_public_status
        brand_howtoknowhomula
        brand_audit_status
        brand_stripe_id
        brand_first_order_fee
        brand_additional_order_fee
        brand_first_order_fee_referral
        brand_additional_order_fee_referral
        brand_direct_order_fee
        createdAt
        updatedAt
        owner
        designed_in
        established
        tagIds
        tags
        brand_collections
        movie_url
        brand_modal_imageKeys
        brand_online_sale
        product_image_reprint
        brand_logo_reprint
        post_to_sns
        allow_direct_shipping
        allow_sell_before_buy
        other_terms
        stockists {
          stockist
          address
        }
        featured_in
        maximum_wholesale_rate
        exclude_zip_code
        is_sale_brand
        hubspot_company_id
        return_to_brand
        terms_of_sample
        auto_message_enabled
        auto_message
      }
      nextToken
      total
    }
  }
`;
