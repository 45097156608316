import { createReducer } from '@reduxjs/toolkit';
import { receiveProducts } from '../../actions/admin';
import { Product } from '~domain/product/Product';

type RequestState = Product[];

const initialState: RequestState = [];

const adminProductsReducer = createReducer<RequestState>(initialState, {
  [receiveProducts.type]: (_, action) => action.payload,
});

export default adminProductsReducer;
