import './styles.modules.scss';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';

type Menu = {
  title: string;
  path: string;
};

type MenuItemProps = {
  title: string;
  brandListPath?: string;
  productListPath?: string;
  subMenu: Menu[];
  expanded?: boolean;
  onMenuClick: () => void;
};

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  brandListPath,
  productListPath,
  subMenu,
  expanded,
  onMenuClick,
}) => {
  const [isOpen, setIsOpen] = useState(!!expanded);
  // const toggle = useCallback(() => {
  //   setIsOpen((prev) => !prev);
  // }, []);

  return (
    <div className="mb-2" styleName="category-burger-menu-item">
      <p>
        <Link
          styleName="menu-title"
          to={productListPath ?? '/'}
          onClick={onMenuClick}
          //@ts-ignore
          disabled={expanded}
        >
          {title}
        </Link>
      </p>
      <Collapse isOpen={isOpen}>
        {brandListPath && (
          <p>
            <Link
              className="text-black-50"
              to={brandListPath}
              onClick={onMenuClick}
            >
              ブランド一覧
            </Link>
          </p>
        )}
        {productListPath && (
          <p>
            <Link
              className="text-black-50"
              to={productListPath}
              onClick={onMenuClick}
            >
              商品一覧
            </Link>
          </p>
        )}
        {subMenu?.map((sub) => (
          <p key={sub.path}>
            <Link className="text-black-50" to={sub.path} onClick={onMenuClick}>
              {sub.title}
            </Link>
          </p>
        ))}
      </Collapse>
    </div>
  );
};

export default MenuItem;
