import { BuyerInfo } from '../../redux/buyer/actions/buyerInfo';
import { IndividualCartItem } from './IndividualCartItem';
import {
  CartSegmentBase,
  CartSegmentJson,
  CartSegmentType,
} from './base/CartSegmentBase';

export class IndividualCartSegment extends CartSegmentBase {
  receivedirectlyAtStore = false;

  private constructor(
    params: CartSegmentType,
    receivedirectlyAtStore: boolean
  ) {
    super(params);
    this.receivedirectlyAtStore = receivedirectlyAtStore;
  }

  static create(
    {
      items,
      ...segment
    }: CartSegmentJson & Pick<CartSegmentType, 'subtotalByBrand'>,
    buyerInfo: BuyerInfo,
    receivedirectlyAtStore = false
  ) {
    return new IndividualCartSegment(
      {
        ...segment,
        buyerInfo,
        items: items.map(IndividualCartItem.create),
      },
      receivedirectlyAtStore
    );
  }

  get shippingFee() {
    return !this.hasPayDirectlyOrderItem ? this.displayShippingFee : 0;
  }

  get displayShippingFee() {
    if (this.receivedirectlyAtStore) {
      return 0;
    }
    return (this.brand.individual_shippinng_fee_criteria ??
      this.brand.brand_shippinng_fee_criteria) > this.subtotalPrice
      ? this.brand.individual_shippinng_fee ?? this.brand.brand_shippinng_fee
      : 0;
  }

  get firstOrderFee() {
    return (
      this.brand.individual_first_order_fee ?? this.brand.brand_first_order_fee
    );
  }

  get additionalOrderFee() {
    return (
      this.brand.individual_additional_order_fee ??
      this.brand.brand_additional_order_fee
    );
  }

  get firstOrderFeeReferral() {
    return (
      this.brand.individual_first_order_fee ?? this.brand.brand_first_order_fee
    );
  }

  get additionalOrderFeeReferral() {
    return (
      this.brand.individual_additional_order_fee ??
      this.brand.brand_additional_order_fee
    );
  }

  get directOrderFee() {
    return this.firstOrderFee ?? this.brand.brand_direct_order_fee;
  }
}
