import { getFlag } from '~view/contexts/FlagsContext';

export const FREE_SHIPPING_PERIOD = new Date('2023-09-30 23:59:59.999');

export const getFreeShippingPeriod = () => {
  const autoUpdateFreeShippingPeriod = getFlag(
    'autoUpdateFreeShippingPeriod',
    false
  );
  if (autoUpdateFreeShippingPeriod) {
    const now = new Date();
    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return nextMonth;
  }
  return FREE_SHIPPING_PERIOD;
};

export const isFreeShipping = (orderDate: Date = new Date()): boolean => {
  const freeShpping = getFlag('isFreeShipping', false);
  return freeShpping || isFreeShippingPeriod(orderDate);
};

export const isFreeShippingPeriod = (orderDate: Date) => {
  return orderDate < getFreeShippingPeriod();
};
