import { AnyAction } from 'redux';
import { setProduct, clearProduct } from '../actions';
import { Product } from '~redux/brand/types';

type ProductItemsState = Product | null;

const initialState: ProductItemsState = null;

const itemReducer = (
  state: ProductItemsState = initialState,
  action: AnyAction
): ProductItemsState => {
  const { payload } = action;
  switch (action.type) {
    case setProduct.type: {
      return payload;
    }
    case clearProduct.type: {
      return initialState;
    }
    default:
      return state;
  }
};

export default itemReducer;
