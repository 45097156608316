import { createAction } from '@reduxjs/toolkit';
import { Product } from '~redux/brand/types';

export const ACTION_BASE_TYPE = 'PRODUCT';

export const setProduct = createAction<Product>(
  `${ACTION_BASE_TYPE}/SET_PRODUCT`
);

export const clearProduct = createAction(`${ACTION_BASE_TYPE}/CLEAR_PRODUCT`);

export const getProductStart = createAction(
  `${ACTION_BASE_TYPE}/GET_PRODUCT_START`
);

export const getSignedProductStart = createAction(
  `${ACTION_BASE_TYPE}/GET_SIGNED_PRODUCT_START`
);
