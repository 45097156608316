import { createReducer } from '@reduxjs/toolkit';
import { receiveRecommendations } from '../actions';
import { Recommendations } from '~domain/personalize/Recommendations';

type PersonalizeState = Recommendations | null;
const initialState: PersonalizeState = null;

const personalizeReducer = createReducer<PersonalizeState>(initialState, {
  [receiveRecommendations.type]: (_, { payload }) => payload,
});

export default personalizeReducer;
