import { CartItem } from './CartItem';
import { CartSegment } from './CartSegment';
import { CartBase, CartJson, CartType } from './base/CartBase';
import { BuyerInfo } from '~redux/buyer/types';

export class Cart extends CartBase {
  private constructor(params: CartType) {
    super(params);
  }

  static create({ items, ...cart }: CartJson, buyerInfo: BuyerInfo) {
    return new Cart({
      ...cart,
      buyerInfo,
      items: items.map(CartItem.create),
    });
  }

  create(...params: Parameters<typeof Cart.create>) {
    return Cart.create(...params);
  }

  createSegment(...params: Parameters<typeof CartSegment.create>) {
    return CartSegment.create(...params);
  }

  createCartItem(...params: Parameters<typeof CartItem.create>) {
    return CartItem.create(...params);
  }

  createCartItemById(id: string) {
    return CartItem.getById(id);
  }

  getBrandMinimumBuy(brandId: string): number {
    const item = this.items.find((item) => item.brand_id === brandId);
    return item?.product.brand.brand_minimum_buy ?? 0;
  }
}
