import { useContext } from 'react';
import { BreakpointContext, BREAKPOINT_CONFIG } from './../contexts/breakpoint';

export const useViewport = (options?: {
  atMost?: keyof typeof BREAKPOINT_CONFIG;
  atLeast?: keyof typeof BREAKPOINT_CONFIG;
}) => {
  const viewport = useContext(BreakpointContext);
  const atMost =
    options?.atMost &&
    BREAKPOINT_CONFIG[options.atMost] >= (viewport.maxWidth ?? 999999);
  const atLeast =
    options?.atLeast &&
    BREAKPOINT_CONFIG[options.atLeast] <= (viewport.minWidth ?? 0);

  return {
    ...viewport,
    atMost,
    atLeast,
  };
};
