import { API, graphqlOperation } from 'aws-amplify';
import { Dispatch } from 'redux';
// import { searchBrandsUnAuth } from '../../../graphql/custom_queries';
import {
  createTag,
  updateTag as updateTagQuery,
  deleteTag,
  updateBrand,
} from '../../../graphql/mutations';
import { getBrand, listTags, searchTags } from '../../../graphql/queries';
import {
  setTag,
  setTagList,
  removeTag as removeTagAction,
  Tag,
} from '../actions';
import { searchBrands } from './graphql';
import { Brand } from '~redux/brand/types';
import { executeQuery } from '~utils/graphql';
import * as Log from '~utils/log';

export const getTagList = (unAuth?: boolean) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const res = await executeQuery(
      searchTags,
      {
        sort: { field: 'tag_name', direction: 'asc' },
      },
      true
    );
    Log.dev(res);
    const {
      data: {
        searchTags: { items },
      },
    } = res;

    // const tagList: Tag[] = await Promise.all(
    //   items.map(async (item: Tag) => {
    //     const res = await executeQuery(
    //       unAuth ? searchBrandsUnAuth : searchBrands,
    //       {
    //         filter: { tagIds: { eq: item.id } },
    //       },
    //       unAuth
    //     );
    //     const {
    //       data: {
    //         searchBrands: { items: brands },
    //       },
    //     } = res;
    //     return { ...item, brands };
    //   })
    // );

    dispatch(setTagList(items));
  };
};

export const getTagsWithBrands = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    const res = await API.graphql<any>(graphqlOperation(listTags));
    const {
      data: {
        listTags: { items },
      },
    } = res;

    const tagList: Tag[] = await Promise.all(
      items.map(async (item: Tag) => {
        const res = await API.graphql<any>(
          graphqlOperation(searchBrands, {
            filter: { tagIds: { eq: item.id } },
          })
        );
        const {
          data: {
            searchBrands: { items: brands },
          },
        } = res;
        return { ...item, brands };
      })
    );

    dispatch(setTagList(tagList));
  };
};

export const removeTag = (tag: Tag) => {
  return async (dispatch: Dispatch): Promise<void> => {
    //ブランドテーブルの更新
    if (tag.brands?.length) {
      await Promise.all(
        tag.brands.map(async ({ id }) => {
          const brandData = await API.graphql<any>(
            graphqlOperation(getBrand, { id })
          );
          const {
            data: { getBrand: brand },
          } = brandData;
          await API.graphql<any>(
            graphqlOperation(updateBrand, {
              input: {
                id: brand.id,
                tagIds: brand.tagIds.filter(
                  (tagId: string) => tagId !== tag.id
                ),
                tags: brand.tags.filter(
                  (tagName: string) => tagName !== tag.tag_name
                ),
              },
            })
          );
        })
      );
    }

    //タグテーブルを削除
    await API.graphql<any>(
      graphqlOperation(deleteTag, { input: { id: tag.id } })
    );

    dispatch(removeTagAction({ id: tag.id }));
  };
};

export const updateTag = (
  prev: Tag,
  tagName: string,
  checkedBrands: string[]
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let tag_id = prev.id;

    //タグを作成
    if (!tag_id) {
      const res = await API.graphql<any>(
        graphqlOperation(createTag, { input: { tag_name: tagName } })
      );
      tag_id = res?.data?.createTag.id;
    }

    const tagRes = await API.graphql<any>(
      graphqlOperation(updateTagQuery, {
        input: { id: tag_id, tag_name: tagName },
      })
    );
    const {
      data: { updateTag: tag },
    } = tagRes;

    let next: Tag = { ...tag, brands: prev.brands ?? [] };

    //削除されたブランドデータを削除
    if (prev.brands?.length) {
      const deletedBrands = prev.brands.filter(
        ({ id }) => !checkedBrands.some((brand) => brand === id)
      );
      await Promise.all(
        deletedBrands.map(async ({ id }) => {
          const brandData = await API.graphql<any>(
            graphqlOperation(getBrand, { id })
          );
          const {
            data: { getBrand: brand },
          } = brandData;
          await API.graphql<any>(
            graphqlOperation(updateBrand, {
              input: {
                id: brand.id,
                tagIds: brand.tagIds.filter(
                  (tagId: string) => tagId !== prev.id
                ),
                tags: brand.tags.filter(
                  (tagName: string) => tagName !== prev.tag_name
                ),
              },
            })
          );
        })
      );
      next = {
        ...tag,
        brands: prev.brands.filter(
          (item: Brand) =>
            !deletedBrands.some(({ id }: Brand) => item.id === id)
        ),
      };
    }

    //追加されたブランドを登録
    const addedBrands = checkedBrands.filter(
      (id) => !prev.brands?.some((brand) => id === brand?.id)
    );

    //ブランドテーブルにタグを追加
    const brands = await Promise.all(
      addedBrands.map(async (id: string) => {
        const brandData = await API.graphql<any>(
          graphqlOperation(getBrand, { id })
        );
        const {
          data: { getBrand: brand },
        } = brandData;
        await API.graphql<any>(
          graphqlOperation(updateBrand, {
            input: {
              id: brand.id,
              tagIds: [...(brand.tagIds ?? []), tag.id],
              tags: [...(brand.tags ?? []), tag.tag_name],
            },
          })
        );
        return brand;
      })
    );

    next.brands = [...(next.brands ?? []), ...brands];

    dispatch(setTag({ id: tag_id, tag: next }));
  };
};
