import qs from 'querystring';
import * as Sentry from '@sentry/core';
import React, { useEffect } from 'react';
import { FullStoryAPI } from 'react-fullstory';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { accountSelector } from '~redux/account/selectors/account';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { buyerInfoSelector } from '~redux/buyer/selectors';
import { formatDate } from '~utils/formatter';
import { GtmEvents, pushGtmEvent } from '~utils/gtm';
import { group, identify } from '~utils/segment';

const LoginLog: React.VFC = () => {
  const authUser = useSelector(authUserSelector);
  const account = useSelector(accountSelector);
  const buyerInfo = useSelector(buyerInfoSelector);
  const history = useHistory();
  const email = qs.parse(history.location.search?.split('?')?.[1])?.c;

  //Google Analytics
  useEffect(() => {
    if (!authUser?.attributes?.email || process.env.NODE_ENV !== 'production') {
      return;
    }
    pushGtmEvent({
      event: GtmEvents.Login,
      payload: {
        method: 'POST',
        user: authUser.attributes.email,
        user_type: authUser.attributes.profile,
        timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
        login_log: `${formatDate(
          new Date(),
          'yyyy-MM-dd HH:mm:ss:SSS',
          true
        )}  ${authUser.attributes.email}`,
      },
    });
  }, [authUser?.attributes?.email]);

  //Segment
  useEffect(() => {
    if (
      !authUser?.attributes?.email ||
      !account ||
      (authUser.attributes.profile === 'buyer' && !buyerInfo)
    ) {
      return;
    }
    identify(
      authUser.attributes.sub,
      authUser.attributes.email,
      account.first_name,
      account.last_name,
      authUser.attributes.profile,
      account.company_name,
      buyerInfo?.shop_name
    );
    group(authUser.attributes.profile);
  }, [
    authUser?.attributes?.sub,
    authUser?.attributes?.email,
    authUser?.attributes?.profile,
    account?.first_name,
    account?.last_name,
    account?.company_name,
    buyerInfo?.shop_name,
  ]);

  //Segment
  useEffect(() => {
    if (!email || typeof email !== 'string' || authUser) {
      return;
    }
    identify(email, email, undefined, undefined, 'buyer');
    group('buyer');
  }, [authUser, email]);

  //Fullstory
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production' || !authUser || !account) {
      return;
    }
    FullStoryAPI('identify', authUser.attributes.sub, {
      email: authUser.attributes.email,
      companyName: account.company_name,
      firstName: account.first_name,
      lastName: account.last_name,
      profile: authUser.attributes.profile,
      shopName: buyerInfo?.shop_name,
    });
  }, [
    authUser?.attributes?.sub,
    authUser?.attributes?.email,
    authUser?.attributes?.profile,
    account?.first_name,
    account?.last_name,
    account?.company_name,
    buyerInfo?.shop_name,
  ]);

  //sentry
  useEffect(() => {
    if (!authUser?.attributes?.email) {
      return;
    }
    Sentry.setUser({ email: authUser.attributes.email });
  }, [authUser?.attributes?.email]);

  return null;
};

export default LoginLog;
