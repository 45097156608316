import { createReducer } from '@reduxjs/toolkit';
import {
  addPaymentMethod,
  PaymentMethod,
  receivePaymentMethods,
  removePaymentMethod,
} from '../actions/buyerInfo';

type PaymentMethodState = PaymentMethod[];

const initialState: PaymentMethodState = [];

const paymentMethodReducer = createReducer<PaymentMethodState>(initialState, {
  [receivePaymentMethods.type]: (state, action) => action.payload,
  [addPaymentMethod.type]: (state, action) => [action.payload, ...state],
  [removePaymentMethod.type]: (state, action) =>
    state.filter(({ id }) => id !== action.payload),
});

export default paymentMethodReducer;
