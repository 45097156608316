import { Storage } from 'aws-amplify';

export type SizeOption =
  | 'small'
  | 'medium'
  | 'large'
  | {
      w?: number;
      h?: number;
      fit?: FitOption;
      format?: 'jpg' | 'png' | 'webp';
    };

export const getImageUrl = async (
  imageKey: string,
  auth?: boolean,
  size?: SizeOption
) => (auth ? Storage.get(imageKey) : getPublicImageUrl(imageKey, size));

export const getPublicImageUrl = (
  imageKey?: string | null,
  size?: SizeOption
) => {
  if (!imageKey) {
    return `https://via.placeholder.com/${
      size === 'small'
        ? 200
        : size === 'medium'
        ? 400
        : size === 'large'
        ? 600
        : size?.w ?? 300
    }x${
      size === 'small'
        ? 200
        : size === 'medium'
        ? 400
        : size === 'large'
        ? 600
        : size?.h ?? 300
    }/f5f5f5?text=No image`;
  }
  const encodedImageKey = imageKey
    .split('/')
    .map((item) => encodeURIComponent(item))
    .join('/');
  const url = `${process.env.REACT_APP_CLOUD_FRONT_DOMAIN}/public/${encodedImageKey}`;
  if (!size) return url;
  if (size === 'small')
    return getResizeImageUrl(url, 200, 200, FitOption.outside);
  if (size === 'medium')
    return getResizeImageUrl(url, 400, 400, FitOption.outside);
  if (size === 'large')
    return getResizeImageUrl(url, 600, 600, FitOption.outside);
  return getResizeImageUrl(url, size.w, size.h, size.fit, size.format);
};

export enum FitOption {
  cover = 'cover',
  inside = 'inside',
  contain = 'contain',
  fill = 'fill',
  outside = 'outside',
}

export const getResizeImageUrl = (
  url: string,
  w?: number,
  h?: number,
  fit: FitOption = FitOption.outside,
  format?: 'jpg' | 'png' | 'webp'
) => {
  if ((!w && !h) || url.includes('text=No image')) {
    return url;
  }

  let query = '';
  if (w) {
    query = `w=${w}`;
  }
  if (h) {
    query = `${query}${query ? '&' : ''}h=${h}`;
  }
  if (fit) {
    query = `${query}${query ? '&' : ''}fit=${fit}`;
  }
  if (format) {
    query = `${query}${query ? '&' : ''}format=${format}`;
  }

  return `${url}?${query}`;
};
