import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Contact = {
  name?: string;
  emailAddress: string;
};

const initialState: Contact[] = [];

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContacts(_, { payload }: PayloadAction<Contact[]>) {
      return payload;
    },
    clearContacts() {
      return initialState;
    },
  },
});

export const { setContacts, clearContacts } = contactsSlice.actions;
export default contactsSlice.reducer;
