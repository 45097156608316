import { createAction } from '@reduxjs/toolkit';
import { Brand } from '~redux/brand/types';

export type Tag = {
  id: string;
  tag_name: string;
  brands?: Brand[];
};

const ACTION_BASE_TYPE = 'TAG';

export const setTagList = createAction<Tag[]>(
  `${ACTION_BASE_TYPE}/SET_TAG_LIST`
);

export const setTag = createAction<{ id: string; tag: Tag }>(
  `${ACTION_BASE_TYPE}/SET_TAG`
);

export const removeTag = createAction<{ id: string }>(
  `${ACTION_BASE_TYPE}/REMOVE_TAG`
);

export const clearTagList = createAction(`${ACTION_BASE_TYPE}/CLEAR_TAG_LIST`);
