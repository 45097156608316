import { createReducer } from '@reduxjs/toolkit';
import { receiveOrderDetails } from '../actions/admin';
import { Order } from '~domain/order/Order';

const initialState: Order | null = null;

const adminOrderDetailsReducer = createReducer(initialState, {
  [receiveOrderDetails.type]: (_, { payload }) => payload,
});

export default adminOrderDetailsReducer;
