import { createReducer } from '@reduxjs/toolkit';
import { BuyerAddress, receiveBuyerAddresses , addBuyerAddress, removeBuyerAddress } from '../actions/buyerInfo';


type BuyerAddressState = BuyerAddress[];

const initialState: BuyerAddressState = [];

const addressReducer = createReducer<BuyerAddressState>(initialState, {
  [receiveBuyerAddresses.type]: (state, action) => action.payload,
  [addBuyerAddress.type]: (state, action) => [action.payload, ...state],
  [removeBuyerAddress.type]: (state, action) =>
    state.filter(({ id }) => id !== action.payload),
});

export default addressReducer;
