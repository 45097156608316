import React from 'react';
import AuthPage from '~view/widgets/AuthPage';

const withAuthPage = (
  Component: React.ComponentType<any>,
  title?: string,
  fullscreen?: boolean
) => {
  return (props: any) => {
    return (
      <AuthPage title={title} fullscreen={fullscreen}>
        <Component {...props} />
      </AuthPage>
    );
  };
};

export default withAuthPage;
