import React from 'react';
import { Route, RouteProps } from 'react-router';
import PublicPage from '../PublicPage';
import withPage from '~view/hoc/withPage';

type PublicRouteProps = {
  title?: string;
  loginLink?: string;
  hideMenu?: boolean;
} & RouteProps;

const PublicRoute: React.FC<PublicRouteProps> = ({
  component,
  render,
  title,
  hideMenu,
  loginLink,
  ...props
}) => {
  return (
    <Route
      {...props}
      component={component && withPage(component, title, loginLink, hideMenu)}
      render={
        render &&
        ((props) => (
          <PublicPage title={title} loginLink={loginLink} hideMenu={hideMenu}>
            {render(props)}
          </PublicPage>
        ))
      }
    />
  );
};

export default PublicRoute;
