import { createReducer } from '@reduxjs/toolkit';
import { clearPickupBrands, PickupBrands, setPickupBrands } from '../actions';

type PickupBrandsState = PickupBrands[];
const initialState: PickupBrandsState = [];

const pickupBrandsReducer = createReducer(initialState, {
  [setPickupBrands.type]: (_, { payload: { items } }) => items,
  [clearPickupBrands.type]: () => [],
});

export default pickupBrandsReducer;
