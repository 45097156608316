import { AnyAction } from 'redux';
import { ADD_PRODUCTS, GET_PRODUCTS_START, SET_PRODUCTS } from '../actions';

type LoadingState = boolean;

const initialState: LoadingState = false;

const productLoadingReducer = (
  state: LoadingState = initialState,
  action: AnyAction
): LoadingState => {
  switch (action.type) {
    case GET_PRODUCTS_START: {
      return true;
    }
    case ADD_PRODUCTS: {
      return false;
    }
    default:
      return state;
  }
};

export default productLoadingReducer;
