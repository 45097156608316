import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import Observable from 'zen-observable-ts';
import { searchThreadsWithMessages } from '../../../graphql/custom_queries';
import {
  createMessage,
  createThread,
  updateMessage,
} from '../../../graphql/mutations';
import { searchMessages, searchThreads } from '../../../graphql/queries';
import {
  onCreateMessage,
  onCreateMessageRecipient,
} from '../../../graphql/subscriptions';
import {
  Message,
  preSendMessage,
  postSendMessage,
  postGetMessages,
  preGetThreads,
  Thread,
  postGetThreads,
  preGetMessages,
  pushMessage,
  postUpdateMessagesOpend,
  pushNewMessages,
  setNextMessagesToken,
} from '../actions';
import { downloadBlob } from '~utils/download';
import { sendMessageNotification } from '~utils/email';
import { getPublicImageUrl } from '~utils/image';
import { getThread } from '~utils/message';

let messagesSub: any;
let threadsSub: any;

export const getThreads = (owner: string, activeThread?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(preGetThreads());

    let threads: Thread[] = [];

    const req = {
      filter: {
        or: [
          { thread_buyer_owner: { eq: owner } },
          { thread_brand_owner: { eq: owner } },
        ],
      },
      sort: { field: 'createdAt', direction: 'asc' },
      limit: 1000,
    };
    const res = await API.graphql<any>(
      graphqlOperation(searchThreadsWithMessages, req)
    );

    if (res?.data?.searchThreads?.items) {
      const { items } = res.data.searchThreads;
      threads = await Promise.all(
        items.map(async (item: any, i: number) => {
          //最終メッセージを取得
          const threadId = item.id;
          const req: any = {
            filter: { thread_id: { eq: threadId } },
            sort: { field: 'createdAt', direction: 'desc' },
            limit: 1,
          };
          const resMessages = await API.graphql<any>(
            graphqlOperation(searchMessages, req)
          );
          let lastMessageCreatedAt = '';
          if (resMessages?.data?.searchMessages?.items?.length) {
            const { items } = resMessages.data.searchMessages;
            lastMessageCreatedAt = items[0].createdAt;
          }
          return {
            id: threadId,
            buyer_owner: item.thread_buyer_owner,
            buyer_name: item.thread_buyer_name,
            brand_owner: item.thread_brand_owner,
            brand_id: item.thread_brand_id,
            brand: {
              ...item.brand,
              iconUrl: item.brand?.brand_icon_imageKey
                ? getPublicImageUrl(item.brand.brand_icon_imageKey)
                : null,
            },
            owners: item.owners,
            createdAt: item.createdAt,
            active: item.id === activeThread,
            newMessages:
              item.messages?.items?.length &&
              item.messages.items.reduce(
                (cnt: number, message: any) =>
                  message.message_owner !== owner && !message.message_is_open
                    ? cnt + 1
                    : cnt,
                0
              ),
            lastMessageCreatedAt: lastMessageCreatedAt,
          };
        })
      );
    }

    dispatch(postGetThreads(threads));
  };
};

export const unsubscribeThreads = () => {
  return async (dispatch: Dispatch) => {
    if (threadsSub) {
      threadsSub.unsubscribe;
    }
  };
};

export const getMessages = (
  thread_id: string,
  owner: string,
  nextToken?: string | null,
  admin?: boolean
) => {
  return async (dispatch: Dispatch) => {
    dispatch(preGetMessages());

    let messages: Message[] = [];
    let nextMessagesToken: string | null = null;

    let req: any = {
      filter: { thread_id: { eq: thread_id } },
      sort: { field: 'createdAt', direction: 'desc' },
      limit: 10,
    };
    if (nextToken) {
      req = { ...req, nextToken };
    }

    const res = await API.graphql<any>(graphqlOperation(searchMessages, req));
    if (res?.data?.searchMessages?.items) {
      const { items, nextToken } = res.data.searchMessages;
      nextMessagesToken = nextToken;
      messages = await Promise.all(
        items.reverse().map(async (item: any) => ({
          id: item.id,
          from: item.message_from,
          content: item.message_content,
          product_name: item.message_product_name,
          is_open: item.message_is_open || false,
          owner: item.message_owner,
          createdAt: item.createdAt,
          files:
            item.message_files?.length &&
            (await Promise.all(
              item.message_files.map(async (file: any) => ({
                ...file,
                signedUrl:
                  file.file_type !== 'application/pdf'
                    ? getPublicImageUrl(file.object_key)
                    : undefined,
              }))
            )),
        }))
      );
    }

    dispatch(setNextMessagesToken(nextMessagesToken));
    dispatch(postGetMessages(messages));

    if (!admin) {
      //メッセージを開封済みに更新
      updateMessagesOpend(
        thread_id,
        messages.filter(
          (message) => message.owner !== owner && !message.is_open
        )
      )(dispatch);
    }
  };
};

export const subscribeMessages = (thread_id: string, owner: string) => {
  return async (dispatch: Dispatch) => {
    messagesSub = (API.graphql(
      graphqlOperation(onCreateMessage, { thread_id })
    ) as Observable<any>).subscribe({
      next: async ({ value }: any) => {
        const data = value.data.onCreateMessage;
        let message: Message = {
          id: data.id,
          content: data.message_content,
          product_name: data.message_product_name,
          is_open: data.message_is_open || false,
          owner: data.message_owner,
          createdAt: data.createdAt,
        };
        if (data.message_files?.length) {
          message = {
            ...message,
            files: await Promise.all(
              data.message_files.map(async (file: any) => ({
                ...file,
                signedUrl:
                  file.file_type !== 'application/pdf'
                    ? getPublicImageUrl(file.object_key)
                    : undefined,
              }))
            ),
          };
        }

        //メッセージを開封済みに更新
        if (message.owner !== owner) {
          updateMessagesOpend(thread_id, [message])(dispatch);
        }

        dispatch(pushMessage(message));
      },
    });
  };
};

export const unsubscribeMessages = () => {
  return async (dispatch: Dispatch) => {
    if (messagesSub) {
      await messagesSub.unsubscribe();
    }
  };
};

export const sendMessage = (
  content: string,
  product_name: string,
  owner: string,
  thread: Thread,
  files?: any[],
  notify = true
) => {
  return async (dispatch: Dispatch) => {
    dispatch(preSendMessage());

    //スレッドの取得
    const _thread = await getThread(
      thread.brand_id,
      thread.brand_owner,
      thread.buyer_owner
    );

    //ファイルアップロード
    let message_files: any[] = [];
    if (files) {
      for (const file of files) {
        const object_key = `messages/${thread.id}/${uuid()}${file.name
          .replace(/\s/g, '-')
          .toLowerCase()}`;
        await Storage.put(object_key, file);
        message_files = [
          ...message_files,
          { object_key, file_name: file.name, file_type: file.type },
        ];
      }
    }

    //メッセージを送信
    const res = await API.graphql<any>(
      graphqlOperation(createMessage, {
        input: {
          thread_id: _thread.id,
          message_content: content,
          message_product_name: product_name,
          message_is_open: false,
          message_owner: owner,
          message_recipient: _thread.owners.filter(
            (o: string) => o !== owner
          )[0],
          message_files,
          owners: _thread.owners,
        },
      })
    );

    const { createMessage: m } = res.data;
    const t = m.thread;
    const message = {
      id: m.id,
      content: m.message_content,
      product_name: m.message_product_name,
      is_open: m.message_is_open,
      files: message_files,
      owner: m.message_owner,
      createdAt: m.createdAt,
      recipient: m.message_recipient,
    };

    //新規メッセージの場合はメールで通知
    if (message && notify) {
      sendMessageNotification(
        (message.owner === t.thread_buyer_owner
          ? t.thread_buyer_name
          : t.brand!.brand_name)!,
        (message.owner === t.thread_buyer_owner
          ? t.brand!.brand_name
          : t.thread_buyer_name)!,
        message.recipient,
        message.content,
        t.id
      );
    }

    dispatch(postSendMessage(message));
  };
};

export const downloadPdf = (objectKey: string, fileName: string) => {
  return async (dispatch: Dispatch) => {
    const result: any = await Storage.get(objectKey, { download: true });
    downloadBlob(
      new Blob([result.Body], { type: 'application/octet-binary' }),
      fileName
    );
  };
};

export const updateMessagesOpend = (thread_id: string, messages: Message[]) => {
  return async (dispatch: Dispatch) => {
    for (const message of messages) {
      await API.graphql<any>(
        graphqlOperation(updateMessage, {
          input: { id: message.id, message_is_open: true },
        })
      );
    }
    dispatch(postUpdateMessagesOpend(thread_id));
  };
};

export const getNewMessages = (owner: string) => async (
  dispatch: Dispatch<any>
) => {
  const res = await API.graphql<any>(
    graphqlOperation(searchMessages, {
      filter: {
        message_recipient: { eq: owner },
        message_is_open: { eq: false },
      },
    })
  );
  if (res?.data?.searchMessages?.items?.length) {
    dispatch(pushNewMessages(res.data.searchMessages.items));
  }
};

let newMessagesSub: any;
export const subscribeNewMessages = (owner: string) => (
  dispatch: Dispatch<any>
) => {
  if (newMessagesSub) {
    return;
  }
  newMessagesSub = (API.graphql(
    graphqlOperation(onCreateMessageRecipient, {
      message_recipient: owner,
    })
  ) as Observable<any>).subscribe({
    next: ({
      value: {
        data: { onCreateMessageRecipient },
      },
    }: any) => {
      dispatch(pushNewMessages([onCreateMessageRecipient.id]));
    },
  });
};

export const unsubscribeNewMessages = () => async (dispatch: Dispatch<any>) => {
  if (newMessagesSub) {
    newMessagesSub.unsubscribe();
    newMessagesSub = undefined;
  }
};
