import {
  loadStripe as load,
  StripeConstructorOptions,
  StripeError,
} from '@stripe/stripe-js';

export const loadStripe = (options?: StripeConstructorOptions) =>
  load(
    process.env.REACT_APP_STRIPE_API_KEY ??
      'pk_test_51IJUpeFNnXut5lCMIAcY692FWnbIbS7OLDIfPW7FoLqbMq4djd8kqTQulbPKdc8ewa6t4uTHYT6D673hxlWIV4ov00So6vJ4Zx', // TODO dotenv
    options
  );

export const getPaymentIntent = async (
  clientSecret: string,
  options?: StripeConstructorOptions
) => {
  const stripe = await loadStripe(options);
  const paymentIntent = await stripe?.retrievePaymentIntent(clientSecret);
  return paymentIntent?.paymentIntent;
};

export type DeclineCode =
  | 'authentication_required' //取引に認証が必要なため、カードは拒否されました。
  | 'approve_with_id' // 支払いを承認することはできません。
  | 'call_issuer' // カードは不明な理由で拒否されました。
  | 'card_not_supported' // カードはこのタイプの購入をサポートしていません。
  | 'card_velocity_exceeded' // お客様は、カードで利用可能な残高またはクレジットの制限を超えています。
  | 'currency_not_supported' // カードは指定された通貨をサポートしていません。
  | 'do_not_honor' // カードは不明な理由で拒否されました。
  | 'do_not_try_again' // カードは不明な理由で拒否されました。
  | 'duplicate_transaction' // ごく最近、同じ金額とクレジットカード情報の取引が提出されました。
  | 'expired_card' // カードの有効期限が切れています。
  | 'fraudulent' // Stripeが不正であると疑ったため、支払いは拒否されました。
  | 'generic_decline' // カードは不明な理由で拒否されました。
  | 'incorrect_number' // カード番号が間違っています。
  | 'incorrect_cvc' // CVC番号が正しくありません。
  | 'incorrect_pin' // 入力したPINが正しくありません。この拒否コードは、カードリーダーで行われた支払いにのみ適用されます。
  | 'incorrect_zip' // 郵便番号が正しくありません。
  | 'insufficient_funds' // カードの資金が不足しているため、購入を完了できません。
  | 'invalid_account' // カード、またはカードが接続されているアカウントが無効です。
  | 'invalid_amount' // お支払い金額が無効であるか、許可されている金額を超えています。
  | 'invalid_cvc' // 	CVC番号が正しくありません。
  | 'invalid_expiry_month' // 有効期限は無効です。
  | 'invalid_expiry_year' // 有効期限は無効です。
  | 'invalid_number' // カード番号が間違っています。
  | 'invalid_pin' // 入力したPINが正しくありません。この拒否コードは、カードリーダーで行われた支払いにのみ適用されます。
  | 'issuer_not_available' // カード発行会社に連絡できなかったため、支払いを承認できませんでした。
  | 'lost_card' // カードの紛失が報告されたため、支払いは拒否されました。
  | 'merchant_blacklist' // Stripeユーザーのブロックリストの値と一致するため、支払いは拒否されました。
  | 'new_account_information_available' // カード、またはカードが接続されているアカウントが無効です。
  | 'no_action_taken' // カードは不明な理由で拒否されました。
  | 'not_permitted' // 支払いは許可されていません。
  | 'offline_pin_required' // PINが必要なため、カードは拒否されました。
  | 'pickup_card' // カードを使用してこの支払いを行うことはできません（紛失または盗難が報告されている可能性があります）。
  | 'pin_try_exceeded' // PINの許容試行回数を超えました。
  | 'processing_error' // カードの処理中にエラーが発生しました。
  | 'reenter_transaction' // 不明な理由により、発行者は支払いを処理できませんでした。
  | 'restricted_card' // カードを使用してこの支払いを行うことはできません（紛失または盗難が報告されている可能性があります）。
  | 'revocation_of_all_authorizations' // カードは不明な理由で拒否されました。
  | 'revocation_of_authorization' // カードは不明な理由で拒否されました。
  | 'security_violation' // カードは不明な理由で拒否されました。
  | 'service_not_allowed' // カードは不明な理由で拒否されました。
  | 'stolen_card' // カードの盗難が報告されたため、支払いは拒否されました。
  | 'stop_payment_order' // カードは不明な理由で拒否されました。
  | 'testmode_decline' // ストライプテストカード番号が使用されました。
  | 'transaction_not_allowed' // カードは不明な理由で拒否されました。
  | 'try_again_later' // カードは不明な理由で拒否されました。
  | 'withdrawal_count_limit_exceeded'; // お客様は、カードで利用可能な残高またはクレジットの制限を超えています。

const ERROR_MESSAGE: Partial<Record<DeclineCode, string>> = {
  card_velocity_exceeded: `クレジットカードの限度額を超えた注文となっております。
  数量を限度額内の数量に修正をお願いします。`,
  expired_card: 'カードの有効期限が切れています。',
  incorrect_number: 'カード番号が間違っています。',
  incorrect_cvc: 'CVC番号が正しくありません。',
  invalid_amount:
    'お支払い金額が無効であるか、許可されている金額を超えています。',
  invalid_cvc: 'CVC番号が正しくありません。',
  invalid_expiry_month: '有効期限(月)は無効です。',
  invalid_expiry_year: '有効期限(年)は無効です。',
  invalid_number: 'カード番号が間違っています。',
  withdrawal_count_limit_exceeded: `クレジットカードの限度額を超えた注文となっております。
  数量を限度額内の数量に修正をお願いします。`,
  try_again_later:
    'カードは不明な理由で拒否されました。時間を開けて再度お試しください。',
  fraudulent: '使用されたカードが不正である可能性があるため拒否されました。',
  card_not_supported: `このクレジットカードはサポートしていません。
  詳しくは管理者に問い合わせてください。`,
};

const DEFAULT_ERROR_MESSAGE = '決済に失敗しました。';

export const resolveError = (declineCode?: DeclineCode) => {
  console.log('declineCode:', declineCode);
  const errorMessage = declineCode && ERROR_MESSAGE[declineCode];
  if (!errorMessage) {
    return DEFAULT_ERROR_MESSAGE;
  }
  return errorMessage;
};
