/* eslint-disable no-async-promise-executor */
import { API, graphqlOperation } from 'aws-amplify';
import { Dispatch } from 'redux';
import * as mutations from '../../../graphql/mutations';
import { payFailed, payStart, paySuccess } from '../actions';
import { ChargeStatusType } from '../types';
import { Payment } from '~domain/payment/Payment';
import { Order } from '~redux/buyer/types';
// import { cancelOrder } from '~redux/order/thunk';
import { warn } from '~utils/log';
import { resolveError } from '~utils/stripe';

export const charge = (
  orderId: string,
  paymentIntentId: string,
  order: Order
) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(payStart());

    try {
      const response = await API.graphql<any>(
        graphqlOperation(mutations.capturePayment, {
          paymentIntentId,
        })
      );
      const {
        data: { capturePayment },
      } = response;

      if (capturePayment.error_code) {
        const errorMessage = resolveError(capturePayment.error_code);
        throw new Error(errorMessage);
      }
      if (!response || response.errors) {
        const errorMessage = resolveError();
        throw new Error(errorMessage);
      }
    } catch (e) {
      dispatch(payFailed());
      throw e;
    }

    // 決済ステータスを登録
    await API.graphql<any>(
      graphqlOperation(mutations.createChargeStatus, {
        input: {
          order_id: orderId,
          status: ChargeStatusType.charged,
          owners: [order.order_owner, order.brand?.brand_owner],
        },
      })
    );

    dispatch(paySuccess());
  };
};

export const extendPayment = (order: Order) => {
  return async (dispatch: Dispatch<any>): Promise<Payment> => {
    dispatch(payStart());

    try {
      //決済情報を更新
      const payment = await Payment.create({
        stripeClientSecret: order.stripe_client_secret,
        stripePaymentId: order.stripe_payment_id,
        stripePaymentMethodId: order.payment_method_id,
        orderId: order.id,
        orderOwner: order.order_owner,
      });
      const next = await payment.update({
        isPostpayment: false,
        isExtend: true,
      });

      dispatch(paySuccess());

      return next;
    } catch (e) {
      //更新失敗時はオーダーキャンセル
      // dispatch(cancelOrder(order));
      dispatch(payFailed());
      warn(e as Error);
      throw e;
    }
  };
};

export const cancelPayment = (paymentId: string) => {
  return async (dispatch: Dispatch<any>): Promise<string> => {
    dispatch(payStart());

    try {
      const res = await API.graphql<any>(
        graphqlOperation(mutations.cancelPayment, {
          paymentId,
        })
      );
      if (!res || !res.data || res.errors) {
        throw new Error('決済情報の取消に失敗しました。');
      }
      const clientSecret = res.data.cancelPayment.client_secret;
      dispatch(paySuccess());
      return clientSecret;
    } catch (e) {
      dispatch(payFailed());
      throw e;
    }
  };
};

export const chargeReturnFee = (stripeId: string, paymentMethodId: string) => {
  return async (dispatch: Dispatch<any>): Promise<string> => {
    dispatch(payStart());

    try {
      const res = await API.graphql<any>(
        graphqlOperation(mutations.chargeReturnFee, {
          stripeId,
          paymentMethodId,
        })
      );

      if (!res || !res.data || res.errors) {
        throw new Error('返品手数料の決済に失敗しました。');
      }

      dispatch(paySuccess());

      return res.data.chargeReturnFee;
    } catch (e) {
      dispatch(payFailed());
      throw e;
    }
  };
};
