import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { Dispatch } from 'redux';
import * as mutations from '../../../graphql/mutations';
import { CartItemBase } from './CartItemBase';
import { BASE_DIRECT_ORDER_FEE, CONSUMPTION_TAX_RATE } from '~constants/trade';
import { Entity } from '~core/domain/Entity';
import { Inventory } from '~domain/inventory/Inventory';
import { Order as OrderModel } from '~domain/order/Order';
import { BillPayment } from '~domain/payment/BillPayment';
import { PaymentIntent } from '~domain/payment/PaymentIntent';
import * as Points from '~domain/point/Points';
import * as queries from '~graphql/queries';
import { Brand, Product } from '~redux/brand/types';
import { BuyerInfo } from '~redux/buyer/actions/buyerInfo';
import {
  PaymentStatusType,
  PaymentTermType,
  PaymentType,
} from '~redux/buyer/types';
import { Coupon, DiscountType } from '~types/api';
import { getWorkingDay } from '~utils/date';
import {
  sendOrderNotificationToSupplier,
  sendOrderReceiptToBuyer,
} from '~utils/email';
import { executeQuery } from '~utils/graphql';
import { warn } from '~utils/log';
import { hasPastOrder, isPostpayment } from '~utils/order';
import { calculatePrice, calculateTax } from '~utils/price';
import { getProductTypeString } from '~utils/product';
import { getFlag } from '~view/contexts/FlagsContext';

export type CartSegmentJson = {
  brand: Product['brand'];
  isPreorder: boolean;
  isDirect: boolean;
  isConsign?: boolean;
  items: Parameters<typeof CartItemBase.create>[0][];
};

export type CartSegmentType = {
  brand: Product['brand'];
  isPreorder: boolean;
  buyerInfo: BuyerInfo;
  isDirect: boolean;
  isConsign?: boolean;
  items: CartItemBase[];
  subtotalByBrand: number;
};

export abstract class CartSegmentBase extends Entity<CartSegmentType> {
  constructor(params: CartSegmentType) {
    super(params);
  }

  abstract get shippingFee(): number;
  abstract get displayShippingFee(): number;
  abstract get firstOrderFee(): number;
  abstract get additionalOrderFee(): number;
  abstract get directOrderFee(): number;
  abstract get firstOrderFeeReferral(): number;
  abstract get additionalOrderFeeReferral(): number;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static create(segment: CartSegmentJson, buyerInfo: BuyerInfo) {
    throw new Error('Not implemented');
  }

  get groupedItems() {
    const grouped = this.items.reduce(
      (prev, item) => ({
        ...prev,
        [item.product_id]: [...(prev[item.product_id] ?? []), item],
      }),
      {} as Record<string, CartItemBase[]>
    );
    return Object.values(grouped);
  }

  get shippingDate() {
    if (this.isPreorder) {
      const preorderShippingDate = this.items[0].product
        .product_preorder_shipping_date;
      return preorderShippingDate
        ? moment(preorderShippingDate).format('YYYY/MM/DD')
        : '未定';
    }

    const min = this.items.reduce((prev, item) => {
      if (prev < (item.product.product_estimated_ship_date_min ?? 1)) {
        return item.product.product_estimated_ship_date_min;
      }

      return prev;
    }, 1);

    const max = this.items.reduce((prev, item) => {
      if (prev < (item.product.product_estimated_ship_date_max ?? 1)) {
        return item.product.product_estimated_ship_date_max;
      }

      return prev;
    }, 1);

    const from = getWorkingDay(new Date(), min);
    const to = getWorkingDay(new Date(), max);

    if (min === max) {
      return moment(from).format('YYYY/MM/DD');
    }

    return `${moment(from).format('YYYY/MM/DD')} - ${moment(to).format(
      'YYYY/MM/DD'
    )}`;
  }

  get subtotalPrice() {
    return this.items.reduce(
      (prevPrice, cartItem) =>
        prevPrice + cartItem.product.unitPrice * cartItem.quantity,
      0
    );
  }

  get taxRate() {
    return CONSUMPTION_TAX_RATE;
  }

  get tax() {
    const total = this.subtotalPrice + this.shippingFee;
    const tax = calculateTax(total, this.taxRate);

    return tax;
  }

  get totalPrice() {
    const total = this.subtotalPrice + this.shippingFee + this.tax;

    return total;
  }

  get totalRetailPrice() {
    return this.items.reduce(
      (prevPrice, cartItem) =>
        prevPrice + cartItem.product.product_retail_price * cartItem.quantity,
      0
    );
  }

  get payDirectlyOrderItems() {
    return this.items.filter(({ is_pay_directly }) => is_pay_directly);
  }

  get notPayDirectlyOrderItems() {
    return this.items.filter(({ is_pay_directly }) => !is_pay_directly);
  }

  get hasPayDirectlyOrderItem() {
    return this.items.some(({ is_pay_directly }) => is_pay_directly);
  }

  get hasNotPayDirectlyOrderItem() {
    return this.items.some(({ is_pay_directly }) => !is_pay_directly);
  }

  async isFirstOrder() {
    if (
      this.hasPayDirectlyOrderItem ||
      this.brand.id === process.env.REACT_APP_HOMULA_BRAND_ID
    ) {
      return false;
    }

    // 過去のオーダー取得（一日前以前）
    const hasPast = await hasPastOrder({
      buyerId: this.buyerInfo.account_id,
      brandId: this.brand.id,
    });

    return !hasPast;
  }

  async getOrderFeeRate() {
    if (this.hasPayDirectlyOrderItem) {
      return 0;
    }

    const isFirstOrder = await this.isFirstOrder();
    const isReferral = this.buyerInfo.referral_brand_id === this.brand.id;

    if (this.isDirect) {
      return (
        (isFirstOrder
          ? this.firstOrderFeeReferral
          : this.additionalOrderFeeReferral) ?? BASE_DIRECT_ORDER_FEE
      );
    }

    if (isReferral) {
      return this.isConsign
        ? this.additionalOrderFeeReferral
        : isFirstOrder
        ? this.firstOrderFeeReferral
        : this.additionalOrderFeeReferral;
    }

    return this.isConsign
      ? this.additionalOrderFee
      : isFirstOrder
      ? this.firstOrderFee
      : this.additionalOrderFee;
  }

  // 在庫数確認
  async inventoryCountCheck() {
    const inventories = await Promise.all(
      this.items
        .filter((item) => item.product.product_preorder !== 1) // 予約注文の場合は在庫数チェックしない
        .map(async (item) => {
          const inventory = await Inventory.createByProductId(
            item.product_id,
            item.product_type_id
          );

          if (
            inventory?.inventory !== undefined &&
            inventory?.inventory !== null
          ) {
            return {
              product: item.product,
              productType: item.productType,
              valid: inventory.inventory >= item.quantity,
            };
          }

          return {
            product: item.product,
            productType: item.productType,
            valid: true,
          };
        })
    );

    return inventories;
  }

  async checkout(
    dispatch: Dispatch<any>,
    buyerInfo: BuyerInfo,
    attachedPoints?: Points.AttachedPoint[],
    campaignCode?: string,
    coupon?: Coupon,
    options?: {
      memo?: string;
      receivedirectlyAtStore?: boolean;
    }
  ) {
    const inventories = await this.inventoryCountCheck();
    if (!inventories.every((inventory) => inventory.valid)) {
      const targetProducts = inventories
        .filter((inventory) => !inventory.valid)
        .map((inventory) => {
          if (inventory.productType) {
            const typeName = `（${getProductTypeString(
              inventory.productType
            )}）`;
            return `${inventory.product.product_name}${typeName}`;
          }

          return inventory.product.product_name;
        });
      throw Error(
        `${targetProducts[0]}の在庫数が不足しています。注文数量を変更してください。`
      );
    }
    const brand = this.brand;
    const isPreorder = this.isPreorder;
    const paymentTerm = this.hasPayDirectlyOrderItem
      ? PaymentTermType.direct
      : buyerInfo.payment_type === PaymentType.bill
      ? PaymentTermType.bill
      : isPreorder
      ? buyerInfo.primary_payment_term === PaymentTermType.factoring
        ? PaymentTermType.factoredPreorder
        : PaymentTermType.preorder
      : buyerInfo.primary_payment_term;

    if (paymentTerm === null) {
      throw Error('支払方法を選択してください。');
    }

    const points =
      attachedPoints?.reduce((prev, point) => prev + point.points, 0) ?? 0;
    const discountAmount =
      (coupon?.discount_type === DiscountType.AMOUNT
        ? coupon.discount_value
        : coupon?.discount_type === DiscountType.PERCENTAGE
        ? Math.min(
            Math.round(this.totalPrice * ((coupon?.discount_value ?? 0) / 100)),
            coupon.maximum_amount ?? 0
          )
        : 0) ?? 0;
    const price = this.totalPrice - discountAmount - points;

    let paymentIntentId,
      clientSecret = undefined;

    //カード支払いの場合のみstripeをcall
    if (
      ![
        PaymentTermType.direct,
        PaymentTermType.factoring,
        PaymentTermType.factoredPreorder,
      ].includes(paymentTerm) &&
      buyerInfo.payment_type !== PaymentType.bill && // 請求書払い
      price > 0
    ) {
      const paymentIntent = await PaymentIntent.register(
        {
          price,
          isPostpayment: isPostpayment(paymentTerm),
        },
        buyerInfo
      );
      await paymentIntent.authorize(dispatch);
      paymentIntentId = paymentIntent.stripePaymentIntentId;
      clientSecret = paymentIntent.stripeClientSecret;
    }

    let authorizationId = undefined;

    //請求書払いはオーソリゼーションを作成
    if (paymentTerm === PaymentTermType.factoring && price > 0) {
      const billing = BillPayment.create({
        customerId: buyerInfo.mf_customer_id!,
      });
      try {
        const auth = await billing.authorization(
          `${this.buyerInfo.id}${this.brand.id}${moment().format(
            'YYYYMMDDHHmmssSSS'
          )}`,
          price
        );
        authorizationId = auth?.id;
      } catch (e) {
        console.log(e);
      }
    }

    const order = await this.createOrder({
      buyerInfo,
      paymentTerm,
      paymentIntentId,
      clientSecret,
      paymentMethodId: buyerInfo.primary_payment_method_id!,
      shippingAddressId: buyerInfo.primary_shipping_address_id!,
      points,
      campaignCode,
      authorizationId,
      coupon,
      discountAmount,
      memo: options?.memo,
      receivedirectlyAtStore: options?.receivedirectlyAtStore,
    });

    //在庫引当
    await Inventory.reservation(order.toJson().orderproducts);

    //サプライヤーへ注文通知を送信
    sendOrderNotificationToSupplier(
      brand as Brand,
      order.toJson(),
      this.items,
      order.totalPrice,
      buyerInfo
    );

    //バイヤーへ注文受付通知を送信
    sendOrderReceiptToBuyer(order.toJson(), this.items, order.totalPrice);

    return order;
  }

  private async createOrder({
    buyerInfo,
    paymentMethodId,
    paymentIntentId,
    clientSecret,
    shippingAddressId,
    paymentTerm,
    points,
    campaignCode,
    authorizationId,
    coupon,
    discountAmount,
    memo,
    receivedirectlyAtStore,
  }: {
    buyerInfo: BuyerInfo;
    paymentMethodId: string;
    paymentIntentId?: string;
    clientSecret?: string;
    shippingAddressId: string;
    paymentTerm: PaymentTermType;
    points: number;
    campaignCode?: string;
    authorizationId?: string;
    coupon?: Coupon;
    discountAmount?: number;
    memo?: string;
    receivedirectlyAtStore?: boolean;
  }) {
    const shippingAddress = receivedirectlyAtStore
      ? undefined
      : (
          await API.graphql<any>(
            graphqlOperation(queries.getShippingAddress, {
              id: shippingAddressId,
            })
          )
        )?.data.getShippingAddress;

    const order = await OrderModel.register({
      brand_id: this.brand.id,
      brand_owner: this.brand.brand_owner,
      campaign_code: campaignCode,
      payment_term: paymentTerm,
      stripe_payment_id: paymentIntentId,
      stripe_client_secret: clientSecret,
      stripe_payment_method_id: paymentMethodId,
      fee: await this.getOrderFeeRate(),
      is_direct: this.isDirect,
      is_consign: this.isConsign,
      tax: this.taxRate * 100,
      first_order: await this.isFirstOrder(),
      invoice_shipping_fee: this.displayShippingFee,
      shipping_fee: this.shippingFee,
      shipping_zip_code: !receivedirectlyAtStore
        ? shippingAddress.zip_code
        : '',
      shipping_address: !receivedirectlyAtStore
        ? `${shippingAddress.prefecture}${shippingAddress.city}${
            shippingAddress.building ?? ''
          }`
        : '',
      shipping_name: !receivedirectlyAtStore
        ? shippingAddress.name
        : '直接受け取り',
      shipping_phone_number: !receivedirectlyAtStore
        ? shippingAddress.phone_number
        : '',
      shipping_date:
        this.isPreorder && this.shippingDate !== '未定'
          ? moment(this.shippingDate).format('YYYY-MM-DD')
          : undefined,
      order_points: points,
      order_owner: buyerInfo.account_id,
      mf_authorization_id: authorizationId,
      coupon_id: coupon?.id,
      discount_amount: discountAmount,
      memo,
      owners: [this.brand.brand_owner, buyerInfo.account_id],
      orderproducts: this.items.map((cartItem) => ({
        product_id: cartItem.product.id,
        product_type_id: cartItem.product_type_id,
        order_product_quantity: cartItem.quantity,
        order_product_price: calculatePrice(
          cartItem.product.product_retail_price,
          cartItem.product.wholesaleRate
        ),
        order_product_wholesale_rate: cartItem.product.wholesaleRate,
        order_product_payment_status: PaymentStatusType.unconfirmed,
        order_product_payment_method: paymentTerm,
        discount_percentage:
          coupon?.discount_type !== DiscountType.AMOUNT
            ? coupon?.discount_value
            : undefined,
        owners: [this.brand.brand_owner, buyerInfo.account_id],
      })),
    });

    const enableLogiless = getFlag('enableLogiless', false);
    const brandsRegisterSalesOrderWithLogilessFlag = getFlag(
      'brandsRegisterSalesOrderWithLogiless',
      '{}'
    );
    const brandsRegisterSalesOrderWithLogiless = Object.entries(
      JSON.parse(brandsRegisterSalesOrderWithLogilessFlag)
    )
      .filter(([, flag]) => flag)
      .map(([brandId]) => brandId);

    if (
      enableLogiless &&
      brandsRegisterSalesOrderWithLogiless.includes(order.brand_id)
    ) {
      // Logilessに登録
      // brandsRegisterSalesOrderWithLogilessのbrandIdに一致するブランドのみ登録する
      try {
        // Logilessの受注登録
        await executeQuery(mutations.orderLogilessProduct, {
          orderIds: [order.id],
        });
      } catch (e) {
        warn(new Error('logiless連携：受注登録に失敗しました。'));
      }
    }

    return order;
  }

  toJson(): CartSegmentJson {
    return {
      brand: this.brand,
      isPreorder: this.isPreorder,
      isDirect: this.isDirect,
      items: this.items.map((item) => item.toJson()),
    };
  }
}
