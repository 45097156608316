import { createSelector } from 'reselect';
import { RootState } from '../../reducer';

const messageSelector = (state: RootState) => state.messages;

export const threadsSelector = createSelector(
  messageSelector,
  (messages) => messages.threads
);

export const activeThreadSelector = createSelector(
  threadsSelector,
  (threads) => {
    for (const thread of threads) {
      if (thread.active) return thread;
    }
    return null;
  }
);

export const messagesSelector = createSelector(
  messageSelector,
  (messages) => messages.messages
);

export const messagesDatesSelector = createSelector(
  messagesSelector,
  (messages) =>
    messages.reduce((dates: string[], message) => {
      const date = new Date(message.createdAt);
      if (dates.includes(date.toLocaleDateString('ja'))) {
        return dates;
      }
      return [...dates, date.toLocaleDateString('ja')];
    }, [])
);

export const loadingSelector = createSelector(
  messageSelector,
  (messages) => messages.loading
);

export const threadsLoadingSelector = createSelector(
  loadingSelector,
  (loading) => loading.isThreadsLoading
);

export const messagesLoadingSelector = createSelector(
  loadingSelector,
  (loading) => loading.isMessagesLoading
);

export const newMessagesSelector = createSelector(
  messageSelector,
  (messages) => messages.newMessages
);

export const nextMessagesTokenSelector = createSelector(
  messageSelector,
  (message) => message.nextToken
);
