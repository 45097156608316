import * as Sentry from '@sentry/core';

export const error = (err: Error, extra?: { [key: string]: unknown }) => {
  console.error(err);
  try {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      Sentry.captureException(err, { extra });
    });
  } catch (e) {
    console.error(e);
  }
};

export const warn = (err: Error, extra?: { [key: string]: unknown }) => {
  console.error(err);
  try {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      Sentry.captureException(err, { extra });
    });
  } catch (e) {
    console.error(e);
  }
};

export const dev = (obj: any) => {
  try {
    if (process.env.NODE_ENV === 'development') {
      console.log(obj);
    }
  } catch (e) {}
};

export const debug = (...obj: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...obj);
  }
};
