import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReturnStatusType } from '~redux/buyer/types';
import { RootState } from '~redux/reducer';
import { ReturnDetailReadModel, ReturnStatus } from '~types/api';

const RETURN_STATUS_MAP = {
  [ReturnStatusType.applied]: ReturnStatus.CONFIRMED,
  [ReturnStatusType.noReturns]: ReturnStatus.NOT_RETURNED,
  [ReturnStatusType.inRequest]: ReturnStatus.UNCONFIRMED,
  [ReturnStatusType.returnedToBrand]: ReturnStatus.RETURNED_TO_BRAND,
};

export const RETURN_STATUS_LABEL = {
  [ReturnStatus.UNCONFIRMED]: '未確認',
  [ReturnStatus.CONFIRMED]: '確認済',
  [ReturnStatus.RETURNED_TO_BRAND]: 'ブランドへ返品',
  [ReturnStatus.NOT_RETURNED]: '返品なし',
};

type ReturnByIdState = {
  byId: Record<string, ReturnDetailReadModel>;
  isLoading: boolean;
  nextToken?: string;
};

const initialState: ReturnByIdState = {
  byId: {},
  isLoading: false,
};

const returnByIdSlice = createSlice({
  name: 'return/byId',
  initialState,
  reducers: {
    fetchedReturnProducts: (state) => {
      state.isLoading = true;
    },
    recievedReturnProducts: (
      state,
      {
        payload: { items, nextToken },
      }: PayloadAction<{ items: ReturnDetailReadModel[]; nextToken?: string }>
    ) => {
      items.forEach((item) => (state.byId[item.id!] = item));
      state.nextToken = nextToken;
      state.isLoading = false;
    },
    clearedReturnProducts: () => initialState,
    updatedReturnProducts: (
      state,
      {
        payload: items,
      }: PayloadAction<
        { id: string; status: ReturnStatusType; quantity?: number }[]
      >
    ) => {
      const date = new Date();
      items.forEach((item) => {
        const returnProduct = state.byId[item.id];
        returnProduct.status = RETURN_STATUS_MAP[item.status];
        returnProduct.status_label = RETURN_STATUS_LABEL[returnProduct.status];
        returnProduct.return_quantity = item.quantity;
        returnProduct.return_amount =
          returnProduct.order_price! *
          (item.quantity ?? 0) *
          (1 + returnProduct.tax_rate! / 100);
        returnProduct.return_date = date.toISOString();
      });
    },
  },
});

export default returnByIdSlice.reducer;

export const {
  fetchedReturnProducts,
  recievedReturnProducts,
  clearedReturnProducts,
  updatedReturnProducts,
} = returnByIdSlice.actions;

export const selectReturnByIdState = (state: RootState) =>
  state.returnProduct.byId;

export const selectReturnProducts = createSelector(
  selectReturnByIdState,
  (state) => Object.values(state.byId)
);

export const selectReturnProductsNextToken = createSelector(
  selectReturnByIdState,
  (state) => state.nextToken
);

export const selectReturnProductsIsLoading = createSelector(
  selectReturnByIdState,
  (state) => state.isLoading
);

export const makeSelectReturnProductById = (id: string) =>
  createSelector(selectReturnByIdState, (state) => state.byId[id]);
