/**
 * Combine Reducers Redux Data
 */
// @ts-ignore
import { IntlReducer as ReducersIntl } from 'react-redux-multilingual';
import { combineReducers } from 'redux';

// Create Custome Reducers
import accountReducer from './account/reducers/account';
import { adminReducer } from './admin/reducer';
import authReducer from './auth/reducers';
import blogReducer from './blog/reducers';
import brandReducer from './brand/reducers';
import brandCollectionReducer from './brandCollection/reducers';
import bulkRegistrationReducer from './bulkRegistration/reducers';
import buyerReducer from './buyer/reducers';
import cartReducer from './cart/reducers';
import cms from './cms';
import collectionReducer from './collection/reducers';
import { contactReducer } from './contact';
import contactsReducer from './contacts';
import coupon from './coupon';
import favoriteReducer from './favorite/reducers';
import favoriteListReducer from './favoritelist/reducers';
import favoriteProductsReducer from './favoriteproduct/reducers';
import feedbackReducer from './feedback/reducers';
import individualReducer from './individual';
import inventory from './inventory';
import menuReducer from './menu/reducers';
import messageReducer from './messages/reducers';
import orderReducer from './order/reducers';
import paymentReducer from './payment/reducers';
import payments from './payments';
import personalizeReducer from './personalize/reducers';
import pickupReducer from './pickup/reducers';
import point from './points';
import productReducer from './product/reducers';
import productsReducer from './products/reducers';
import { reducer as project } from './project';
import referralMessage from './referralMessage';
import referral from './referrals';
import requestReducer from './request/reducers';
import returnProduct from './return';
import returnsReducer from './returns/reducers';
import { reducer as review } from './review';
import search from './search';
import supplierGroupReducer from './supplierGroup';
import tagReducer from './tag/reducers';
import ui from './ui';

export const rootReducer = combineReducers({
  admin: adminReducer,
  request: requestReducer,
  auth: authReducer,
  product: productReducer,
  products: productsReducer,
  brand: brandReducer,
  buyer: buyerReducer,
  cart: cartReducer,
  bulkregistration: bulkRegistrationReducer,
  messages: messageReducer,
  order: orderReducer,
  returns: returnsReducer,
  feedback: feedbackReducer,
  payment: paymentReducer,
  account: accountReducer,
  pickup: pickupReducer,
  tag: tagReducer,
  brandCollection: brandCollectionReducer,
  blog: blogReducer,
  menu: menuReducer,
  favorite: favoriteReducer,
  collection: collectionReducer,
  contacts: contactsReducer,
  referral,
  referralMessage,
  point,
  cms,
  personalize: personalizeReducer,
  inventory,
  ReducersIntl,
  favoriteList: favoriteListReducer,
  favoriteProduct: favoriteProductsReducer,
  coupon,
  returnProduct,
  search,
  review,
  project,
  payments,
  individual: individualReducer,
  supplierGroup: supplierGroupReducer,
  contact: contactReducer,
  ui,
});

export type RootState = {
  admin: ReturnType<typeof adminReducer>;
  request: ReturnType<typeof requestReducer>;
  auth: ReturnType<typeof authReducer>;
  product: ReturnType<typeof productReducer>;
  products: ReturnType<typeof productsReducer>;
  brand: ReturnType<typeof brandReducer>;
  buyer: ReturnType<typeof buyerReducer>;
  cart: ReturnType<typeof cartReducer>;
  bulkregistration: ReturnType<typeof bulkRegistrationReducer>;
  messages: ReturnType<typeof messageReducer>;
  ReducersIntl: any;
  order: ReturnType<typeof orderReducer>;
  returns: ReturnType<typeof returnsReducer>;
  feedback: ReturnType<typeof feedbackReducer>;
  payment: ReturnType<typeof paymentReducer>;
  account: ReturnType<typeof accountReducer>;
  pickup: ReturnType<typeof pickupReducer>;
  tag: ReturnType<typeof tagReducer>;
  blog: ReturnType<typeof blogReducer>;
  brandCollection: ReturnType<typeof brandCollectionReducer>;
  menu: ReturnType<typeof menuReducer>;
  favorite: ReturnType<typeof favoriteReducer>;
  collection: ReturnType<typeof collectionReducer>;
  contacts: ReturnType<typeof contactsReducer>;
  referral: ReturnType<typeof referral>;
  referralMessage: ReturnType<typeof referralMessage>;
  point: ReturnType<typeof point>;
  cms: ReturnType<typeof cms>;
  personalize: ReturnType<typeof personalizeReducer>;
  favoriteList: ReturnType<typeof favoriteListReducer>;
  favoriteProduct: ReturnType<typeof favoriteProductsReducer>;
  inventory: ReturnType<typeof inventory>;
  coupon: ReturnType<typeof coupon>;
  returnProduct: ReturnType<typeof returnProduct>;
  search: ReturnType<typeof search>;
  review: ReturnType<typeof review>;
  project: ReturnType<typeof project>;
  payments: ReturnType<typeof payments>;
  individual: ReturnType<typeof individualReducer>;
  supplierGroup: ReturnType<typeof supplierGroupReducer>;
  contact: ReturnType<typeof contactReducer>;
  ui: ReturnType<typeof ui>;
};
