import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { Dispatch } from 'redux';
import * as customQueries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import * as subscriptions from '../../../graphql/subscriptions';
import {
  getOrdersStart,
  setOrder,
  cancelOrderStart,
  setIsOrderCancelable,
  pushNewOrder,
  receiveOrderAccount,
  getOrderAccountStart,
  pushOrders,
  setNextOrdersToken,
  setReturnDueDate,
  setOrders,
  receiveOrderProducts,
  receiveOrderProductsForCSV,
} from '../actions';
import { canceledOrder } from '../reducers/byId';
import { Order, Order as OrderModel } from './../../../domain/order/Order';
import {
  OrderStatusType as OrderStatusTypeJson,
  Order as OrderJson,
  OrderProduct as OrderProductJson,
  PaymentTermType as PaymentTermTypeJson,
  OrderStatusType,
} from './../../buyer/types';
import { extendPayment, cancelPayment } from './../../payment/thunk';
import { Inventory } from '~domain/inventory/Inventory';
import { ListOrderProductParams } from '~domain/order/OrderProduct';
import { BillPayment } from '~domain/payment/BillPayment';
import { ProductType } from '~domain/product/ProductType';
import { Account } from '~redux/account/actions/account';
import { sendMessage } from '~redux/messages/thunk';
import { request } from '~redux/request/thunk';
import {
  CreateReturnInventoryLogMutation,
  CreateReturnInventoryLogMutationVariables,
  OrderDetailReadModel,
  OrderReadModel,
  SearchableOrderDetailReadModelFilterInput,
  SearchableOrderDetailReadModelSortableFields,
  SearchableSortDirection,
  SearchOrderDetailReadModelsQuery,
  SearchOrderDetailReadModelsQueryVariables,
  StockMode,
  StockType,
} from '~types/api';
import {
  sendOrderCancelByBuyer,
  sendOrderCancelBySupplier,
  sendOrderCancelBySystem,
} from '~utils/email';
import { formatCurrency } from '~utils/formatter';
import { executeQuery } from '~utils/graphql';
import { getPublicImageUrl } from '~utils/image';
import { getOrderStatus } from '~utils/order';
import { calculateTax } from '~utils/price';
import { getProductTypeString } from '~utils/product';
import { orderCancelled } from '~utils/segment';
import { getPaymentIntent } from '~utils/stripe';

type Options = {
  filter?: any;
  sort?: any;
  limit?: number;
  nextToken?: string;
};

export const getOrders = ({
  owner,
  filter,
  nextToken,
  limit = 10,
}: {
  owner: string;
  filter?: any;
  nextToken?: string;
  limit?: number;
}) => {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(
      getOrdersWithProducts({
        filter: { order_owner: { eq: owner }, ...(filter ?? {}) },
        sort: { field: 'createdAt', direction: 'desc' },
        limit,
        nextToken,
      })
    );
  };
};

export const getBrandOrders = (brandOwner: string, next?: string) => {
  return async (dispatch: Dispatch<any>) => {
    const res = await executeQuery(
      customQueries.listOrdersByBrandOwnerWithProduct,
      {
        brand_owner: brandOwner,
        sortDirection: 'DESC',
        limit: 50,
        nextToken: next,
      }
    );

    const {
      data: {
        listOrdersByBrandOwner: { items, nextToken },
      },
    } = res;

    dispatch(setNextOrdersToken(nextToken));

    const orderModels: Order[] = await Promise.all(
      items.map(async (item: any) =>
        Order.create(
          {
            ...item,
            buyer: item.buyer.items[0],
            chargeStatuses: item.chargeStatuses?.items,
            orderproducts: item.orderproducts?.items?.map((item: any) => ({
              ...item,
              orderstatus: item.orderstatus.items
                .map((status: any) => status)
                ?.sort((prev: any, next: any) =>
                  prev.createdAt > next.createdAt ? -1 : 1
                ),
            })),
            returnproducts: item.returnproducts?.items,
            reportProducts: item.reportProducts?.items,
            reviews: item.reviews.items,
          },
          true,
          false
        )
      )
    );

    if (next) {
      dispatch(pushOrders(orderModels));
    } else {
      dispatch(setOrders(orderModels));
    }
  };
};

export const getOrderProductIds = async (
  options: Options
): Promise<{ brand_id: string; createdAt: Date }[]> => {
  const res = await API.graphql(
    graphqlOperation(customQueries.searchOrderProductIds, options)
  );
  return res.data.searchOrders?.items;
};

export const getOrdersWithProducts = (options: Options) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(getOrdersStart);

    const res = await API.graphql<any>(
      graphqlOperation(customQueries.searchOrdersWithProducts, options)
    );

    const {
      data: {
        searchOrders: { nextToken },
      },
    } = res;

    const orders: OrderJson[] = await Promise.all(
      res?.data?.searchOrders?.items?.map(async (order: any) => {
        const buyer = order.buyer?.items[0];
        const returnproducts = order?.returnproducts?.items?.map(
          (item: any) => item
        );
        const chargeStatuses = [...order.chargeStatuses.items];
        const reportProducts = order.reportProducts?.items;
        const orderproducts = await Promise.all(
          order?.orderproducts?.items?.map(async (item: any) => ({
            ...item,
            orderstatus: item?.orderstatus?.items
              ?.map((status: any) => status)
              ?.sort((prev: any, next: any) =>
                prev.createdAt > next.createdAt ? -1 : 1
              ),
            product: {
              ...item?.product,
              imageKeys: item?.product?.imageKeys?.items?.map(
                (item: any) => item
              ),
              imageUrl: item.productType?.imageKey
                ? getPublicImageUrl(item.productType.imageKey)
                : getPublicImageUrl(
                    item.product?.imageKeys?.items[0]?.imageKey
                  ),
            },
            reportProducts: item.reportProducts.items,
          }))
        );

        return {
          ...order,
          buyer,
          returnproducts,
          chargeStatuses,
          orderproducts,
          reportProducts,
          reviews: order.reviews.items,
        };
      })
    );

    dispatch(setNextOrdersToken(nextToken));

    const orderModels = await Promise.all(
      orders.map(async (order) => await Order.create(order))
    );

    if (options.nextToken) {
      dispatch(pushOrders(orderModels));
    } else {
      dispatch(setOrders(orderModels));
    }
  };
};

export const getOrder = (orderId: string) => {
  return async (dispatch: Dispatch<any>): Promise<OrderJson> => {
    dispatch(getOrdersStart);

    const res = await API.graphql<any>(
      graphqlOperation(customQueries.getOrderWithProducts, {
        id: orderId,
      })
    );

    const orderData = res?.data?.getOrder;
    const buyer = orderData.buyer?.items[0];
    const returnproducts = orderData?.returnproducts?.items?.map(
      (item: any) => item
    );
    const orderproducts: OrderProductJson[] = await Promise.all(
      orderData?.orderproducts?.items?.map(async (item: any) => ({
        ...item,
        orderstatus: item.orderstatus?.items
          ?.map((status: any) => status)
          ?.sort((prev: any, next: any) =>
            prev.createdAt > next.createdAt ? -1 : 1
          ),
        product: {
          ...item.product,
          imageKeys: item.product?.imageKeys?.items?.map((item: any) => item),
          imageUrl: getPublicImageUrl(
            item.productType?.imageKey ??
              item.product.imageKeys?.items[0]?.imageKey
          ),
        },
        reportProducts: item.reportProducts?.items,
      }))
    );

    const order = {
      ...orderData,
      chargeStatuses: orderData?.chargeStatuses?.items,
      buyer,
      returnproducts,
      orderproducts: orderproducts.sort((a, b) =>
        (a.product.product_number ?? '') > (b.product.product_number ?? '')
          ? 1
          : (a.productType?.color ?? '') > (b.productType?.color ?? '')
          ? 1
          : (a.productType?.size ?? '') > (b.productType?.size ?? '')
          ? 1
          : -1
      ),
      reportProducts: orderData?.reportProducts?.items,
      reviews: orderData?.reviews?.items,
    };

    const orderModel = await Order.create(order);

    dispatch(setOrder(orderModel));

    return order;
  };
};

export const checkOrder = (orderId: string) => {
  return async (dispatch: Dispatch<any>) => {
    let order = await getOrder(orderId)(dispatch);

    // オーダーステータスを確認済みに更新
    if (getOrderStatus(order) === OrderStatusTypeJson.unconfirmed) {
      const status: any = [];
      await Promise.all(
        order.orderproducts.map(async (op, i) => {
          const res = await API.graphql<any>(
            graphqlOperation(mutations.createOrderStatus, {
              input: {
                order_product_id: op.id,
                status: OrderStatusTypeJson.confirmed,
                owners: order.owners,
              },
            })
          );
          if (res?.data?.createOrderStatus) {
            status[i] = res.data.createOrderStatus;
          }
        })
      );

      order = {
        ...order,
        orderproducts: order.orderproducts.map((op, i) => ({
          ...op,
          orderstatus: [status[i], ...op.orderstatus],
        })),
      };

      const orderModel = await Order.create(order);

      dispatch(setOrder(orderModel));
    }

    // 出荷払いかつ未発送のオーダーのみ期限チェックを実行
    if (
      !order.stripe_client_secret ||
      order.payment_term !== PaymentTermTypeJson.outgoingFreight ||
      ![
        OrderStatusTypeJson.unconfirmed,
        OrderStatusTypeJson.confirmed,
      ].includes(getOrderStatus(order))
    ) {
      return;
    }

    // オーソリ期限確認
    const clientSecret = order.stripe_client_secret;
    const paymentIntent = await getPaymentIntent(clientSecret);

    // オーソリ期限切れの場合は再作成
    if (paymentIntent?.status === 'canceled') {
      const payment = await extendPayment(order)(dispatch);
      order = {
        ...order,
        stripe_client_secret: payment.stripeClientSecret,
        stripe_payment_id: payment.stripePaymentId,
      };

      const orderModel = await Order.create(order);
      dispatch(setOrder(orderModel));
    }
  };
};

export const commitOrder = (
  order: Order,
  shippingDate: string,
  carrier: string,
  trackingNumber: string,
  shippingFee?: number
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const newOrder = await order.shipping({
        shippingDate,
        carrier,
        trackingNumber,
        shippingFee,
      });

      dispatch(setOrder(newOrder));

      // 委託販売セットの場合はReturnInventoryLogにログを残す
      if (order.brand_id === process.env.REACT_APP_HOMULA_BRAND_ID) {
        await Promise.all(
          order.orderproducts.map(async (orderproduct) => {
            const inventory = await Inventory.createByProductId(
              orderproduct.product_id,
              orderproduct.product_type_id
            );

            // 在庫登録
            const sku = `homula_${
              orderproduct.product_type_id ?? orderproduct.product_id
            }`;
            const logVariables: CreateReturnInventoryLogMutationVariables = {
              input: {
                inventory_id: inventory?.id!,
                order_id: orderproduct.order_id,
                brand_id: orderproduct.product.product_brand_id,
                product_id: orderproduct.product.id,
                sku,
                stock_type: StockType.DESTOCK,
                stock_mode: StockMode.AUTO,
                stock_date: moment().toISOString(),
                quantity: orderproduct.quantity,
              },
            };
            (await API.graphql<any>(
              graphqlOperation(mutations.createReturnInventoryLog, logVariables)
            )) as {
              data: CreateReturnInventoryLogMutation;
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
      throw new Error(
        error.message ??
          '決済処理に失敗しました。管理者に問い合わせてください。'
      );
    }
  };
};

export const editOrder = (
  {
    orderId,
    account,
    edit_reason,
    editReason,
    shipping_name,
    shipping_zip_code,
    shipping_address,
    shipping_phone_number,
    shipping_fee,
    tax,
    price,
    changedProducts,
    additionalProducts,
  }: {
    orderId: OrderJson['id'];
    account: Account;
    edit_reason: string;
    editReason: string;
    shipping_name?: string;
    shipping_zip_code?: string;
    shipping_address?: string;
    shipping_phone_number?: string;
    shipping_fee?: number;
    tax?: number;
    price?: number;
    changedProducts?: {
      id: string;
      quantity?: number;
      wholesale_rate?: number;
    }[];
    additionalProducts: {
      quantity: number;
      wholesaleRate?: number;
      productTypes: ProductType;
    }[];
  },
  callback?: () => void
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      // 最新のオーダー情報取得
      const {
        data: {
          searchOrders: { items: orders },
        },
      } = await API.graphql<any>(
        graphqlOperation(customQueries.searchOrdersWithProducts, {
          filter: {
            id: { eq: orderId },
          },
        })
      );
      const orderData = orders[0];
      const order = await OrderModel.create({
        ...orderData,
        buyer: orderData.buyer.items[0],
        orderproducts: orderData.orderproducts.items,
        chargeStatuses: orderData.chargeStatuses.items,
        returnproducts: orderData.returnproducts.items,
      });
      await order.edit(
        {
          edit_reason,
          editReason,
          shippingName: shipping_name,
          shippingZipCode: shipping_zip_code,
          shippingAddress: shipping_address,
          shippingPhoneNumber: shipping_phone_number,
          shippingFee: shipping_fee,
          tax,
          price,
          changedProducts: changedProducts?.map(
            ({ wholesale_rate, ...changedProduct }) => ({
              ...changedProduct,
              wholesaleRate: wholesale_rate,
            })
          ),
          additionalProducts,
        },
        account,
        dispatch
      );
      dispatch(getOrder(order.id));
      callback?.();
    } catch (error) {
      console.log(error);
      throw new Error(error.message ?? '更新処理に失敗しました。');
    }
  };
};

export const supplierCancel = (
  order: OrderJson,
  reason: string,
  comment: string,
  account: Account
) => {
  return async (dispatch: Dispatch) => {
    try {
      //決済情報の取消
      let stripe_client_secret;
      if (order.stripe_payment_id) {
        stripe_client_secret = await cancelPayment(order.stripe_payment_id)(
          dispatch
        );
      }

      if (order.mf_authorization_id) {
        const bill = BillPayment.create();
        await bill.cancelAuthorization(order.mf_authorization_id);
      }

      //Orderテーブルを更新
      if (stripe_client_secret) {
        await API.graphql<any>(
          graphqlOperation(mutations.updateOrder, {
            input: {
              id: order.id,
              stripe_client_secret,
            },
          })
        );
      }

      //OrderProductテーブルを更新
      await Promise.all(
        order.orderproducts.map(async (item) => {
          await API.graphql<any>(
            graphqlOperation(mutations.createOrderStatus, {
              input: {
                order_product_id: item.id,
                status: OrderStatusTypeJson.canceledBySupplier,
                owners: order.owners,
              },
            })
          );
        })
      );

      //在庫更新
      await Inventory.reservation(
        order.orderproducts.map((op) => ({
          ...op,
          order_product_quantity: op.order_product_quantity * -1,
        }))
      );

      //ポイントを返還
      // if ((order.order_points ?? 0) > 0) {
      //   await Point.createInstance({
      //     account_id: order.order_owner,
      //     history_type: PointHistoryType.chargeByOrderCancel,
      //     point: order.order_points!,
      //     order_id: order.id,
      //   }).update();
      // }

      //メッセージを送信
      await API.graphql<any>(
        graphqlOperation(mutations.createOrderCancelReason, {
          input: {
            order_id: order.id,
            cancel_reason: reason,
            feedback_comment: comment,
          },
        })
      );

      const message = `以下のオーダーがキャンセルされました。
  
・注文番号：${order.id}
・注文商品：
${order.orderproducts.reduce(
  (msg, item) => `${msg}　${item.product.product_name} x ${
    item.order_product_quantity
  }（${formatCurrency(
    (item.order_product_quantity as number) *
      (item.order_product_price as number)
  )}）
`,
  ''
)}
・キャンセル理由：
${comment}
`;

      await API.graphql<any>(
        graphqlOperation(mutations.createOrderCancelReason, {
          input: {
            order_id: order.id,
            cancel_reason: reason,
            feedback_comment: comment,
          },
        })
      );

      await sendMessage(
        message,
        '',
        account.id,
        {
          buyer_owner: order.buyer?.account_id!,
          brand_id: order.brand_id,
          brand_owner: order.brand?.brand_owner!,
          owners: order.owners,
        },
        undefined,
        false
      )(dispatch);

      //メールを送信
      sendOrderCancelBySupplier(order, order.orderproducts, comment);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
};

export const buyerCancel = (order: OrderReadModel) => {
  return async (dispatch: Dispatch) => {
    try {
      //キャンセル可否チェック
      const res = await API.graphql<any>(
        graphqlOperation(queries.isOrderCancelable, { orderId: order.id })
      );
      if (!res?.data?.isOrderCancelable) {
        throw new Error('このオーダーはキャンセルできません。');
      }

      //決済情報の取消
      let stripe_client_secret;
      if (order.stripe_payment_id) {
        stripe_client_secret = await cancelPayment(order.stripe_payment_id)(
          dispatch
        );
      }

      if (order.mf_authorization_id) {
        const bill = BillPayment.create();
        await bill.cancelAuthorization(order.mf_authorization_id);
      }

      //Orderテーブルを更新
      if (stripe_client_secret) {
        await API.graphql<any>(
          graphqlOperation(mutations.updateOrder, {
            input: {
              id: order.id,
              stripe_client_secret,
            },
          })
        );
      }

      //OrderProductテーブルを更新
      await Promise.all(
        order.details?.items?.map(async (detail) => {
          await API.graphql<any>(
            graphqlOperation(mutations.createOrderStatus, {
              input: {
                order_product_id: detail?.id,
                status: OrderStatusTypeJson.canceledByBuyer,
                owners: order.owners,
              },
            })
          );
        }) ?? []
      );

      //在庫更新
      await Inventory.reservation(
        order.details!.items!.map((detail) => ({
          id: detail?.id!,
          order_product_quantity: detail?.quantity! * -1,
        }))
      );

      //ポイントの返還
      // if ((order.order_points ?? 0) > 0) {
      //   await Point.createInstance({
      //     account_id: order.order_owner,
      //     history_type: PointHistoryType.chargeByOrderCancel,
      //     point: order.order_points!,
      //     order_id: order.id,
      //   }).update();
      //   dispatch(addPoint(order.order_points!));
      // }

      //メールを送信
      sendOrderCancelByBuyer(order);

      dispatch(canceledOrder(order.id!));

      //tracking
      orderCancelled({
        order_id: order.id,
        currency: 'JPY',
        products: order.details!.items!.map((item) => ({
          brand: order.brand!.brand_name!,
          category: `${item!.product!.product_category}/${
            item!.product!.product_subcategory
          }`,
          image_url: getPublicImageUrl(item!.product_image_key),
          name: item!.product_name!,
          price: item!.price!,
          product_id: item!.product_id!,
          quantity: item!.quantity!,
          sku: item!.product_number!,
          url: `${document.location.protocol}//${
            document.location.host
          }/productdetail/${item!.product_id}`,
          variant: item!.productType
            ? getProductTypeString(item!.productType)
            : undefined,
        })),
        revenue: order.order_subtotal! + order.shipping_fee!,
        shipping: order.shipping_fee!,
        tax: calculateTax(
          order.order_subtotal! + order.shipping_fee!,
          order.tax_rate! / 100
        ),
        subtotal: order.order_subtotal!,
        total: order.order_amount!,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const saveShippingDate = (order: Order, shippingDate: string) => {
  return async (dispatch: Dispatch) => {
    const {
      data: { updateOrder: newOrder },
    } = await API.graphql<any>(
      graphqlOperation(mutations.updateOrder, {
        input: {
          id: order.id,
          shipping_date: shippingDate,
        },
      })
    );
    const orderModel = await Order.create(
      {
        ...order.toJson(),
        shipping_date: newOrder.shipping_date,
      },
      true
    );

    dispatch(setOrder(orderModel));
  };
};

export const cancelOrder = (order: OrderJson) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(
      request({
        startAction: cancelOrderStart,
        do: async () => {
          await Promise.all(
            order.orderproducts.map(async (orderProduct) => {
              await API.graphql<any>(
                graphqlOperation(mutations.createOrderStatus, {
                  input: {
                    order_product_id: orderProduct.id,
                    status: OrderStatusTypeJson.canceledByStripe,
                    owners: order.owners,
                  },
                })
              );
            })
          );

          //ポイントを返還
          // if ((order.order_points ?? 0) > 0) {
          //   await Point.createInstance({
          //     account_id: order.order_owner,
          //     history_type: PointHistoryType.chargeByOrderCancel,
          //     point: order.order_points!,
          //     order_id: order.id,
          //   }).update();
          // }

          //メールを送信
          sendOrderCancelBySystem(order, order.orderproducts);

          return order.order_owner;
        },
        completeAction: getOrders,
      })
    );
  };
};

export const getIsOrderCancelable = (orderId: string) => {
  return async (dispatch: Dispatch<any>) => {
    const res = await API.graphql<any>(
      graphqlOperation(queries.isOrderCancelable, { orderId })
    );
    dispatch(
      setIsOrderCancelable({
        orderId,
        isCancelable: res?.data?.isOrderCancelable ?? false,
      })
    );
  };
};

export const getReturnDueDate = (orderId: string) => {
  return async (dispatch: Dispatch<any>) => {
    const res = await API.graphql<any>(
      graphqlOperation(queries.getReturnDueDate, { orderId })
    );
    dispatch(
      setReturnDueDate({
        orderId,
        returnDueDate: res?.data?.getReturnDueDate?.dueDate,
      })
    );
  };
};

export const getNewOrders = (owner: string) => async (
  dispatch: Dispatch<any>
) => {
  const res = await API.graphql<any>(
    graphqlOperation(queries.getNewOrders, { owner })
  );
  if (res?.data?.getNewOrders?.length) {
    dispatch(pushNewOrder(res.data.getNewOrders));
  }
};

let orderSubscription: any;
export const subscribeOrders = (owner: string) => (dispatch: Dispatch<any>) => {
  if (orderSubscription) {
    return;
  }
  orderSubscription = API.graphql<any>(
    graphqlOperation(subscriptions.onCreateBrandOrder, { brand_owner: owner })
  ).subscribe({
    next: ({
      value: {
        data: { onCreateBrandOrder },
      },
    }: any) => {
      dispatch(pushNewOrder(onCreateBrandOrder.id));
    },
  });
};

export const unsubscribeOrders = () => (dispatch: Dispatch<any>) => {
  if (orderSubscription) {
    orderSubscription.unsubscribe();
    orderSubscription = undefined;
  }
};

export const getOrderAccount = (accountId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(
      request({
        startAction: getOrderAccountStart,
        do: async () => {
          const {
            data: { getAccount: account },
          } = await API.graphql<any>(
            graphqlOperation(customQueries.getAccountWithShopImages, {
              id: accountId,
            })
          );
          return {
            ...account,
            buyerInfo: {
              ...account.buyerInfo.items[0],
              shop_images: account.buyerInfo.items[0]?.shop_images?.items,
            },
          };
        },
        completeAction: receiveOrderAccount,
      })
    );
  };
};

export const getOrdersByYM = (
  owner: string,
  year: number,
  month: number,
  isConsign?: boolean
) => async (dispatch: Dispatch<any>) => {
  //月初日
  const date = new Date();
  date.setDate(1);
  date.setFullYear(year);
  date.setMonth(month - (isConsign ? 2 : 1));

  const res1 = await executeQuery(queries.searchOrderStatuss, {
    filter: {
      createdAt: {
        gte: moment(date).startOf('month').toISOString(),
        lte: moment(date).endOf('month').toISOString(),
      },
      owners: { eq: owner },
      status: { eq: OrderStatusType.shipped },
    },
    limit: 1000,
  });

  const {
    data: {
      searchOrderStatuss: { items: statuses },
    },
  } = res1;

  const orderIds = [
    ...new Set(
      statuses.map((status: any) => status.orderProduct.order_id) as string[]
    ),
  ];

  const res2 = await executeQuery(customQueries.getOrdersWithProducts, {
    filter: {
      or: orderIds.map((id: string) => ({ id: { eq: id } })),
    },
  });

  const {
    data: {
      searchOrders: { items },
    },
  } = res2;

  const orders = items
    .filter(
      (order: any) =>
        (isConsign && order.is_consign) || (!isConsign && !order.is_consign)
    )
    .map((order: any) => ({
      ...order,
      buyer: order.buyer.items[0],
      orderproducts: order.orderproducts.items?.map((item: any) => ({
        ...item,
        orderstatus: item.orderstatus?.items,
      })),
      returnproducts: [],
    }));

  dispatch(
    setOrders(
      await Promise.all(
        orders.map((order: OrderJson) => OrderModel.create(order, true))
      )
    )
  );
};

export const getOrderProducts = (params: ListOrderProductParams) => {
  return async (dispatch: Dispatch<any>) => {
    const { owner, filterValues, isForCSV, nextToken } = params;

    const filter: SearchableOrderDetailReadModelFilterInput = {
      supplier_id: { eq: owner },
    };
    if (filterValues) {
      filter.and = [];
    }
    if (filterValues?.startDate) {
      filter.and!.push({
        createdAt: {
          gte: moment(filterValues.startDate).startOf('date').toISOString(),
        },
      });
    }
    if (filterValues?.endDate) {
      filter.and!.push({
        createdAt: {
          lte: moment(filterValues.endDate).endOf('date').toISOString(),
        },
      });
    }

    const res = await executeQuery<
      SearchOrderDetailReadModelsQuery,
      SearchOrderDetailReadModelsQueryVariables
    >(queries.searchOrderDetailReadModels, {
      filter,
      sort: {
        field: SearchableOrderDetailReadModelSortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      },
      limit: isForCSV ? 1000 : 100,
      nextToken,
    });

    if (!isForCSV) {
      dispatch(
        receiveOrderProducts({
          list: (res.data?.searchOrderDetailReadModels?.items ??
            []) as OrderDetailReadModel[],
          nextToken: res.data?.searchOrderDetailReadModels?.nextToken ?? null,
        })
      );
      return;
    }

    dispatch(
      receiveOrderProductsForCSV({
        csvData: (res.data?.searchOrderDetailReadModels?.items ??
          []) as OrderDetailReadModel[],
      })
    );
  };
};
