// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-AuthPage-___styles-modules__fullscreen-app{height:100vh;min-height:0;display:flex;flex-direction:column;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"fullscreen-app": "src-view-widgets-AuthPage-___styles-modules__fullscreen-app"
};
module.exports = exports;
