import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { browserHistory } from '../../history';

import { ScrollController } from './ScrollController';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { authenticatingSelector } from '~redux/auth/selectors/authenticating';
import {
  AVAILABLE_PROJECT,
  initializedProject,
  ProjectType,
} from '~redux/project';
import AdminRoutes from '~view/pages/admin/routes';
import BuyerRoutes from '~view/pages/buyer/routes';
import IndividualRoutes from '~view/pages/individual/routes';
import SupplierRoutes from '~view/pages/supplier/routes';
import AccessLog from '~view/widgets/AccessLog';
import Authenticator from '~view/widgets/Authenticator';
import FullStoryLogger from '~view/widgets/FullStoryLogger';
import HubSpotTracker from '~view/widgets/HubSpotTracker';
import LoadingContainer from '~view/widgets/LoadingContainer';
import LoginLog from '~view/widgets/LoginLog';
import SignIn from '~view/widgets/SignIn';
import SignUp from '~view/widgets/SignUp';

//pages
const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const isAuthLoading = useSelector(authenticatingSelector);
  const project = window.location.hostname.split('.')[0];
  // const project = 'mag';
  useEffect(() => {
    if (!project || !AVAILABLE_PROJECT.includes(project as ProjectType)) {
      return;
    }
    dispatch(initializedProject({ projectId: project as ProjectType }));
  }, [project]);

  return (
    <Suspense fallback={<LoadingContainer isLoading />}>
      <Router history={browserHistory}>
        <Authenticator />
        <ScrollController />

        {isAuthLoading && <LoadingContainer isLoading />}
        {user?.attributes.profile !== 'individual' && (
          <>
            <BuyerRoutes />
            <SupplierRoutes />
            <AdminRoutes />
          </>
        )}
        <IndividualRoutes />

        <SignUp />
        <SignIn />

        <LoginLog />
        <AccessLog />
        <FullStoryLogger />
        <HubSpotTracker />
      </Router>
    </Suspense>
  );
};

export default Routes;
