import { createSlice } from '@reduxjs/toolkit';

type brandNameType = string | null;

const brandNameSlice = createSlice({
  initialState: null as brandNameType,
  name: 'brand/brandName',
  reducers: {
    setBrandName: (state, { payload }) => payload,
    clearBrandName: () => null,
  },
});

export const { setBrandName, clearBrandName } = brandNameSlice.actions;
export default brandNameSlice.reducer;
