import { Switch } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { updateBrand } from '~graphql/mutations';
import { searchBrands } from '~graphql/queries';
import { accountSelector } from '~redux/account/selectors/account';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { Brand } from '~types/api';
import { executeQuery } from '~utils/graphql';
import { useGraphql } from '~view/hooks/graphql';
import FieldWithValidity from '~view/widgets/FieldWithValidity';
import LoadingContainer from '~view/widgets/LoadingContainer';

const AutoReply: React.FC = () => {
  const user = useSelector(authUserSelector);
  const account = useSelector(accountSelector);
  const [brands, fetchData, isLoading] = useGraphql<Brand[]>(
    searchBrands,
    (data) => data.searchBrands.items
  );
  const data = useMemo(() => {
    const brand = brands?.find(
      (brand) => brand.auto_message_enabled || brand.auto_message
    );
    if (!brand) {
      return {
        auto_message_enabled: false,
        auto_message: `お世話になっております。${account?.company_name}の${account?.last_name}です。
この度はご注文いただきありがとうございます。
通常2〜3営業日で対応可否や在庫状況を確認させていただきますので少々お待ちください。
今後ともどうぞよろしくお願いいたします。`,
      };
    }
    return {
      auto_message_enabled: brand.auto_message_enabled,
      auto_message: brand.auto_message,
    };
  }, [brands, account]);

  const validationSchema = useMemo(
    () =>
      Yup.lazy((values) =>
        Yup.object().shape({
          auto_message: values.auto_message_enabled
            ? Yup.string()
                .required('メッセージを入力してください。')
                .max(1000, 'メッセージは1000文字以内で入力してください')
            : Yup.string().max(
                1000,
                'メッセージは1000文字以内で入力してください'
              ),
        })
      ),
    []
  );

  const [message, setMessage] = useState('保存する');
  const history = useHistory();
  const handleSubmit = useCallback(
    async (values, formik) => {
      if (!user || !brands) return;
      await Promise.all(
        brands.map(
          async (brand) =>
            await executeQuery(updateBrand, {
              input: {
                id: brand.id,
                auto_message_enabled: values.auto_message_enabled,
                auto_message: values.auto_message,
              },
            })
        )
      );
      formik.setSubmitting(false);
      setMessage('保存しました！');
      setTimeout(() => history.push('/home'), 500);
    },
    [user, brands]
  );

  useEffect(() => {
    if (!user) return;
    fetchData({
      filter: { brand_owner: { eq: user.attributes.sub } },
      limit: 1000,
    });
  }, [user]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty, isSubmitting }) => (
          <Form>
            <div className="mb-4">
              <h3 className="mb-0">メッセージ自動送信</h3>
              <div>
                注文受付時にバイヤーへ送信するメッセージを設定できます。
              </div>
            </div>
            <div className="p-6 bg-white">
              <div className="mb-4">
                <label className="mr-2 font-weight-bold">
                  注文受付時にバイヤーへ自動でメッセージを送信する
                </label>
                <Switch
                  color="primary"
                  checked={!!values.auto_message_enabled}
                  onClick={() => {
                    setFieldValue(
                      'auto_message_enabled',
                      !values.auto_message_enabled
                    );
                  }}
                />
              </div>
              <div className="mb-4">
                <label className="mr-2 font-weight-bold">
                  自動送信メッセージ
                </label>
                <FieldWithValidity
                  as="textarea"
                  name="auto_message"
                  rows={5}
                  disabled={!values.auto_message_enabled}
                  style={{ resize: 'none' }}
                />
              </div>
              <Button type="submit" disabled={!dirty || isSubmitting}>
                {isSubmitting && <Spinner size="sm" className="mr-2" />}
                {message}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </LoadingContainer>
  );
};

export default AutoReply;
