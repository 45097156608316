import { AnyAction } from 'redux';
import { RECEIVE_NEXT_TOKEN, CLEAR_NEXT_TOKEN } from '../actions';

type NextTokenState = string | null;

const initialState: NextTokenState = null;

const productsNextTokenReducer = (
  state: NextTokenState = initialState,
  action: AnyAction
): NextTokenState => {
  const { payload } = action;
  switch (action.type) {
    case RECEIVE_NEXT_TOKEN: {
      return payload;
    }
    case CLEAR_NEXT_TOKEN: {
      return initialState;
    }
    default:
      return state;
  }
};

export default productsNextTokenReducer;
