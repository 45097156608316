import { createAction } from '@reduxjs/toolkit';
import { Brand, Product } from '~redux/brand/types';

export type FavoriteBrand = {
  id: string;
  account_id: string;
  brand_id: string;
  brand: Brand;
  product?: { items: Product[] };
};

const ACTION_BASE_TYPE = 'FAVORITE';

export const setFavoriteBrands = createAction<FavoriteBrand[]>(
  `${ACTION_BASE_TYPE}/SET_FAVORITE_BRANDS`
);

export const clearFavoriteBrands = createAction(
  `${ACTION_BASE_TYPE}/CLEAR_FAVORITE_BRANDS`
);

export const appendFavoriteBrand = createAction<FavoriteBrand>(
  `${ACTION_BASE_TYPE}/APPEND_FAVORITE_BRAND`
);

export const removeFavoriteBrand = createAction<string>(
  `${ACTION_BASE_TYPE}/REMOVE_FAVORITE_BRAND`
);

export const setFavoriteBrand = createAction<{ id: string; brand_id: string }>(
  `${ACTION_BASE_TYPE}/SET_FAVORITE_BRAND`
);
