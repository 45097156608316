import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, Nav } from 'reactstrap';

type CategoryNavLinkProps = {
  displayName: string;
  productListPath: string;
  brandListPath: string;
  categories: Record<string, { path: string }>;
};

const CategoryNavMenu: React.FC<CategoryNavLinkProps> = ({
  displayName,
  productListPath,
  brandListPath,
  categories,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const submenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const submenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  return (
    <Nav navbar>
      <Dropdown
        nav
        inNavbar
        onMouseEnter={submenuOpen}
        onMouseLeave={submenuClose}
        toggle={toggle}
        isOpen={isMenuOpen}
      >
        <Link
          aria-haspopup="true"
          to={productListPath}
          className="nav-link"
          aria-expanded="true"
        >
          {displayName}
        </Link>
        <DropdownMenu>
          <DropdownItem
            tag={Link}
            className={`nav-item ${
              document.location.pathname === brandListPath ? 'active' : ''
            }`}
            to={brandListPath}
          >
            ブランド一覧
          </DropdownItem>
          <DropdownItem
            tag={Link}
            className={`nav-item ${
              document.location.pathname === productListPath ? 'active' : ''
            }`}
            to={productListPath}
          >
            商品一覧
          </DropdownItem>
          {Object.entries(categories).map(([key, value]) => (
            <DropdownItem
              key={`sub-category-${key}`}
              tag={Link}
              className={`nav-item ${
                document.location.pathname === value.path ? 'active' : ''
              }`}
              to={value.path}
            >
              {key}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

export default CategoryNavMenu;
