import './styles.scss';
import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
//@ts-ignore
import HubspotForm from 'react-hubspot-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Modal, Spinner } from 'reactstrap';
import { signin } from '~redux/auth/reducers/signin';
import {
  referralIdSelector,
  signUpPathSelector,
} from '~redux/auth/selectors/signup';

type SignUpModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSignup?: (email: string) => void;
};

const SignUpModal: React.FC<SignUpModalProps> = ({
  isOpen,
  toggle,
  onSignup,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExist, setIsExist] = useState(false);
  const nextPath = useSelector(signUpPathSelector);
  const referralId = useSelector(referralIdSelector);

  const checkExistUser = useCallback(async (email: string) => {
    try {
      await Auth.confirmSignUp(email, 'x', {
        forceAliasCreation: false,
      });
    } catch (e: any) {
      if (e.code === 'UserNotFoundException') {
        return false;
      }
      return true;
    }
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      if (
        event.data.type !== 'hsFormCallback' ||
        event.data.eventName !== 'onFormSubmit' ||
        event.data.id !== process.env.REACT_APP_HUBSPOT_SIGNUP_FORM_ID
      ) {
        return;
      }
      const email =
        event.data.data.email ??
        event.data.data?.find((data: any) => data.name === 'email')?.value;
      if (await checkExistUser(email)) {
        setIsExist(true);
        alert(
          '入力されたメールアドレスはすでに登録されています。下部のログインボタンを押してログインしてください。'
        );
        return;
      }
      if (onSignup) onSignup(email);
      history.push(
        `/sign-up?email=${email}${
          referralId ? `&referral_id=${encodeURIComponent(referralId)}` : ''
        }`
      );
    },
    [referralId]
  );

  const login = useCallback(() => {
    dispatch(signin(nextPath));
    toggle();
  }, [nextPath]);

  useEffect(() => {
    window.addEventListener('message', handleSubmit);
    return () => {
      window.removeEventListener('message', handleSubmit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (isExist) {
      setIsExist(false);
    }
  }, [isExist]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} contentClassName="signup-modal">
      {isExist ? (
        <Spinner />
      ) : (
        <HubspotForm
          portalId="9130201"
          formId={process.env.REACT_APP_HUBSPOT_SIGNUP_FORM_ID}
          loading={<Spinner />}
          onFormSubmit={() => null}
        />
      )}
      <div className="border-top pt-4">
        <p>すでにアカウントをお持ちの場合はこちら</p>
        <Button outline onClick={login} block>
          ログイン
        </Button>
      </div>
    </Modal>
  );
};

export default SignUpModal;
