import qs from 'querystring';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import AuthPage from '../AuthPage';
import BuyerCases from '../BuyerCases';
import HomeFaqBuyers from '../HomeFaqBuyers';
import MagPage from '../MagPage';
import PublicPage from '../PublicPage';
import { accountSelector } from '~redux/account/selectors/account';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { Brand } from '~redux/brand/types';
import { buyerInfoSelector } from '~redux/buyer/selectors';
import { getBuyerInfo } from '~redux/buyer/thunk';
import { clearedCartItems } from '~redux/cart/actions';
import { clearedOrderedBrands } from '~redux/cart/reducers/orderedBrands';
import { getCart } from '~redux/cart/thunk';
import { clearedContacts, fetchContacts } from '~redux/contact';
import { clearedCoupons } from '~redux/coupon/slices/byId';
import { clearedFavoriteProductList } from '~redux/favorite/reducers/product';
import { clearedPoints } from '~redux/points/slice/byId';
import { selectProjectId } from '~redux/project';
import { clearedReviews } from '~redux/review';
import { fetchOrderedBrands } from '~usecases/buyer/cart';
import { fetchCoupons } from '~usecases/buyer/coupon';
import { fetchFavoriteProductList } from '~usecases/buyer/favorite/product';
import { getBuyerPoints } from '~usecases/buyer/point';
import { fetchReviews } from '~usecases/buyer/reviews';

const RedirectToSignup = React.memo(() => {
  const user = useSelector(authUserSelector);
  const params = useMemo(() => qs.parse(location.search?.substring(1)), [
    location.search,
  ]);
  return (
    <Redirect
      to={`/sign-up?email=${user!.attributes.email}&${qs.stringify({
        ...params,
        location: params.address,
      })}`}
    />
  );
});

type BuyerPageProps = {
  title?: string;
  loginLink?: string;
  unAuth?: boolean;
  direct?: boolean;
  directBrand?: Brand;
  contactId?: string;
};

const BuyerPage: React.FC<BuyerPageProps> = ({
  title,
  loginLink,
  unAuth,
  direct,
  directBrand,
  contactId,
  children,
}) => {
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);
  const account = useSelector(accountSelector);
  const buyerInfo = useSelector(buyerInfoSelector);
  const projectId = useSelector(selectProjectId);
  const sellOnlyDirect = useMemo(
    () => direct && directBrand?.sell_only_direct,
    [direct, directBrand]
  );

  useEffect(() => {
    if (!authUser || buyerInfo) {
      return;
    }
    dispatch(getBuyerInfo(authUser.attributes.sub));
  }, [authUser, buyerInfo]);

  useEffect(() => {
    if (!authUser) return;
    dispatch(fetchCoupons({ accountId: authUser.attributes.sub }));
    return () => {
      dispatch(clearedCoupons());
    };
  }, [authUser]);

  useEffect(() => {
    if (!buyerInfo) return;
    dispatch(getCart(buyerInfo));
    return () => {
      dispatch(clearedCartItems());
    };
  }, [buyerInfo]);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    dispatch(fetchOrderedBrands(authUser.attributes.sub));
    return () => {
      dispatch(clearedOrderedBrands());
    };
  }, [authUser]);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    dispatch(getBuyerPoints({ accountId: authUser.attributes.sub }));
    return () => {
      dispatch(clearedPoints());
    };
  }, [authUser]);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    dispatch(fetchFavoriteProductList({ accountId: authUser.attributes.sub }));
    return () => {
      dispatch(clearedFavoriteProductList());
    };
  }, [authUser]);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    dispatch(fetchReviews({ accountId: authUser.attributes.sub }));
    return () => {
      dispatch(clearedReviews());
    };
  }, [authUser]);

  useEffect(() => {
    if (!buyerInfo) {
      return;
    }
    dispatch(fetchContacts({ buyerId: buyerInfo.id }));
    return () => {
      dispatch(clearedContacts());
    };
  }, [authUser, buyerInfo]);

  if (authUser && authUser.attributes.profile !== 'buyer') {
    return <Redirect to="/home" />;
  }

  if (authUser && authUser.attributes.profile === 'buyer' && !account) {
    return <RedirectToSignup />;
  }

  if (projectId === 'mag') {
    return <MagPage title={title}>{children}</MagPage>;
  }

  if (!unAuth || authUser) {
    return <AuthPage title={title}>{children}</AuthPage>;
  }

  return (
    <PublicPage
      title={title}
      loginLink={loginLink}
      direct={direct}
      directBrand={directBrand}
      contactId={contactId}
    >
      {children}
      {!sellOnlyDirect && location.pathname !== '/' && (
        <>
          {/* <div className="border-bottom">&nbsp;</div> */}
          <BuyerCases />
          <HomeFaqBuyers />
        </>
      )}
    </PublicPage>
  );
};

export default BuyerPage;
