import { combineReducers } from 'redux';
import brandListReducer from './brandList';
import pickupBrandsReducer from './pickupBrands';

export const pickupReducer = combineReducers({
  pickupBrands: pickupBrandsReducer,
  brandList: brandListReducer,
});

export default pickupReducer;
