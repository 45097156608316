import { createReducer } from '@reduxjs/toolkit';
import { setIsOrderCancelable } from '../actions';

const initialState: { [orderId: string]: boolean } = {};

const isOrderCancelableReducer = createReducer(initialState, {
  [setIsOrderCancelable.type]: (state, { payload }) => ({
    ...state,
    [payload.orderId]: payload.isCancelable,
  }),
});

export default isOrderCancelableReducer;
