import { API, graphqlOperation } from 'aws-amplify';
import { sendEmail as sendEmailMutation } from '../graphql/mutations';
import { getAccount } from './account';
import { formatCurrency, formatDate } from './formatter';
import { calculateTax } from './price';
import { getProductTypeString } from './product';
import { Order } from '~domain/order/Order';
import {
  ReportProduct,
  ReportProductTypeLabel,
  ReportRequestTypeLabel,
} from '~domain/order/ReportProduct';
import { CartItemBase } from '~domain/order/base/CartItemBase';
import { ProductType } from '~domain/product/ProductType';
import { Brand } from '~redux/brand/types';
import {
  BuyerInfo,
  Order as OrderJson,
  OrderProduct as OrderProductJson,
  ReturnProduct as ReturnProductJson,
} from '~redux/buyer/types';
import { OrderReadModel } from '~types/api';

export enum EmailTemplates {
  OrderNotification = 'order-notification', //注文通知（TOサプライヤー）
  OrderReceipt = 'order-receipt', //注文通知（TOバイヤー）
  OrderApproval = 'order-approval', //発送通知（TOバイヤー）
  OrderCancel = 'order-cancel', //キャンセル通知
  MessageNotification = 'message-notification', //新着メッセージ通知
  ReturnOrderNotification = 'return-order-notification', //返品申請通知（TOサプライヤー、アドミン）
  OrderReportNotification = 'order-report-notification', //不良品欠品通知（TOサプライヤー、アドミン）
  OrderUpdate = 'order-update', //注文更新通知（TOバイヤー）
  SupplierInvitation = 'supplier-invitation', //ブランド招待メール
  BuyerInvitation = 'buyer-invitation', //バイヤー招待メール
  ReviewNotification = 'review-notification', //レビュー投稿通知
}

type EmailOptions = {
  bccAddresses?: string[];
  bccAccountIds?: string[];
  sendToAdminBcc?: boolean;
  ccAddresses?: string[];
  ccAccountIds?: string[];
  toAddresses?: string[];
  toAccountIds?: string[];
  source?: string;
  sourceAccountId?: string;
  templateName: string;
  templateData: any;
  replyToAddresses?: string[];
};

export const sendEmail = async (options: EmailOptions): Promise<string> => {
  const res = await API.graphql<any>(
    graphqlOperation(sendEmailMutation, {
      options: {
        ...options,
        templateData: JSON.stringify(options.templateData),
      },
    })
  );
  return res.data.sendEmail;
};

//注文通知(TOサプライヤー)
export const sendOrderNotificationToSupplier = async (
  brand: Brand,
  order: OrderJson,
  items: CartItemBase[],
  totalPrice: number,
  buyer: BuyerInfo
) => {
  const supplier = await getAccount(brand.brand_owner);
  const res = await sendEmail({
    toAccountIds: [brand.brand_owner],
    templateName: EmailTemplates.OrderNotification,
    templateData: {
      brandName: brand.brand_name,
      brandOwnerName: `${supplier.last_name}${supplier.first_name}`,
      buyerName: buyer.shop_name,
      address: order.shipping_address,
      orderId: order.id,
      orderPrice: formatCurrency(totalPrice),
      products: items.map((item) => ({
        product: `${item.product.product_name}${
          item.productType ? `　${getProductTypeString(item.productType)}` : ''
        }<br />【数量：${item.quantity}、掛け率：${
          item.product.is_open_price
            ? 'オープン価格'
            : `${item.product.wholesaleRate}%`
        }、販売価格：${formatCurrency(item.product.unitPrice)}】`,
      })),
    },
    sendToAdminBcc: true,
  });
  console.log(res);
  return res;
};

//注文受付通知(TOバイヤー)
export const sendOrderReceiptToBuyer = async (
  order: OrderJson,
  items: CartItemBase[],
  totalPrice: number
) => {
  const buyer = await getAccount(order.order_owner);
  const res = await sendEmail({
    toAccountIds: [buyer.id],
    templateName: EmailTemplates.OrderReceipt,
    templateData: {
      buyerName: buyer.company_name,
      buyerOwnerName: `${buyer.last_name}${buyer.first_name}`,
      brandName: order.brand!.brand_name,
      orderId: order.id,
      orderPrice: formatCurrency(totalPrice),
      products: items.map((item) => ({
        product: `${item.product.product_name}${
          item.productType ? `　${getProductTypeString(item.productType)}` : ''
        }<br />【数量：${item.quantity}、販売価格：${formatCurrency(
          item.product.unitPrice
        )}】`,
      })),
    },
    sendToAdminBcc: true,
  });
  console.log(res);
  return res;
};

//発送通知(TOバイヤー)
export const sendOrderApprovalToBuyer = async (
  order: Order,
  orderProducts: OrderProductJson[]
) => {
  const buyer = await getAccount(order.order_owner);
  const subtotal = orderProducts.reduce(
    (price, item) =>
      price + item.order_product_price * item.order_product_quantity,
    0
  );
  const totalPrice =
    subtotal +
    order.shipping_fee +
    calculateTax(subtotal + order.shipping_fee, order.tax / 100);
  const dueDate = order.isReturnable ? order.returnDueDate : undefined;
  const res = await sendEmail({
    toAccountIds: [buyer.id],
    sendToAdminBcc: true,
    templateName: EmailTemplates.OrderApproval,
    templateData: {
      buyerName: buyer.company_name,
      buyerOwnerName: `${buyer.last_name}${buyer.first_name}`,
      brandName: order.brand!.brand_name,
      orderId: order.id,
      orderPrice: formatCurrency(totalPrice),
      shippingDate: formatDate(new Date(order.shipping_date), 'yyyy/MM/dd'),
      returnDueDate: dueDate
        ? `・返品申請期限：${formatDate(new Date(dueDate), 'yyyy/MM/dd')}`
        : '',
      carrier: order.carrier,
      trackingNumber: order.tracking_number,
      products: orderProducts.map((item) => ({
        product: `${item.product.product_name}${
          item.productType ? `　${getProductTypeString(item.productType)}` : ''
        }<br />【数量：${item.order_product_quantity}、掛け率：${
          item.product.is_open_price
            ? 'オープン価格'
            : `${item.order_product_wholesale_rate}%`
        }、販売価格：${formatCurrency(item.order_product_price)}】`,
      })),
    },
  });
  console.log(res);
  return res;
};

//新着メッセージ通知
export const sendMessageNotification = async (
  sender: string,
  recipient: string,
  recipientId: string,
  message: string,
  thread: string
) => {
  const res = await sendEmail({
    toAccountIds: [recipientId],
    sendToAdminBcc: true,
    templateName: EmailTemplates.MessageNotification,
    templateData: {
      sender,
      recipient,
      message,
      thread,
    },
  });
  console.log(res);
  return res;
};

//注文キャンセル通知
export const sendOrderCancelNotification = async (
  orderId: string,
  orderProducts: {
    productName: string;
    productType?: string;
    quantity: number;
    isOpenPrice?: boolean;
    wholesaleRate: number;
    price: number;
  }[],
  toAccountId: string,
  canceller: string,
  comment: string,
  orderPath: string
) => {
  const res = await sendEmail({
    toAccountIds: [toAccountId],
    sendToAdminBcc: true,
    templateName: EmailTemplates.OrderCancel,
    templateData: {
      canceller,
      comment,
      orderId,
      products: orderProducts.map((item) => ({
        product: `${item.productName}${item.productType ?? ''}<br />【数量：${
          item.quantity
        }、掛け率：${
          item.isOpenPrice ? 'オープン価格' : `${item.wholesaleRate}%`
        }、販売価格：${formatCurrency(item.price)}】`,
      })),
      orderPath,
    },
  });
  console.log(res);
  return res;
};

//サプライヤーキャンセル通知
export const sendOrderCancelBySupplier = async (
  order: OrderJson,
  orderProducts: OrderProductJson[],
  comment: string
) => {
  const res = await sendOrderCancelNotification(
    order.id,
    orderProducts.map((op) => ({
      productName: op.product.product_name!,
      productType: op.productType
        ? getProductTypeString(op.productType)
        : undefined,
      quantity: op.order_product_quantity,
      price: op.order_product_price,
      wholesaleRate: op.order_product_wholesale_rate,
      isOpenPrice: op.product.is_open_price!,
    })),
    order.order_owner,
    `${order.brand?.brand_name}　様`,
    `・キャンセル理由：<br/>${comment.replaceAll('\n', '<br/>')}`,
    `/Account/Orderhistory/${order.id}`
  );
  return res;
};

//バイヤーキャンセル通知
export const sendOrderCancelByBuyer = async (order: OrderReadModel) => {
  const buyer = await getAccount(order.buyer_id!);
  const res = await sendOrderCancelNotification(
    order.id!,
    order.details?.items?.map((item) => ({
      productName: item?.product_name!,
      productType: item?.productType
        ? getProductTypeString(item.productType)
        : undefined,
      quantity: item?.quantity!,
      price: item?.price!,
      wholesaleRate: item?.wholesale_rate!,
      isOpenPrice: !!item?.product?.is_open_price,
    })) ?? [],
    order.brand?.brand_owner!,
    `${buyer.company_name}　様`,
    '',
    `/Orderdetail/${order.id}`
  );
  return res;
};

//システムキャンセル通知
export const sendOrderCancelBySystem = async (
  order: OrderJson,
  orderProducts: OrderProductJson[]
) => {
  const res = await sendOrderCancelNotification(
    order.id,
    orderProducts.map((op) => ({
      productName: op.product.product_name!,
      productType: op.productType
        ? getProductTypeString(op.productType)
        : undefined,
      quantity: op.order_product_quantity!,
      price: op.order_product_price!,
      wholesaleRate: op.order_product_wholesale_rate!,
      isOpenPrice: op.product.is_open_price!,
    })),
    order.brand?.brand_owner!,
    `システム`,
    '・キャンセル理由：<br/>有効期限切れにより注文がキャンセルされました。<br/>注文内容をご確認いただき、ご対応ください。',
    `/Orderdetail/${order.id}`
  );
  return res;
};

//返品申請通知
export const sendReturnOrderNotification = async (
  buyerInfo: BuyerInfo,
  order: OrderJson,
  orderProducts: OrderProductJson[],
  returnProducts: ReturnProductJson[],
  feedback: string
) => {
  const buyer = await getAccount(order.order_owner);
  const supplier = await getAccount(order.brand?.brand_owner!);
  const returnPrice = returnProducts.reduce((price, item) => {
    return price + item.return_product_price * item.return_quantity;
  }, 0);

  const res = await sendEmail({
    toAccountIds: [supplier.id],
    sendToAdminBcc: true,
    templateName: EmailTemplates.ReturnOrderNotification,
    templateData: {
      orderId: order.id,
      buyerName: buyerInfo.shop_name ?? buyer.company_name,
      products: returnProducts.map((item) => {
        const op = orderProducts.find(({ id }) => id === item.order_product_id);
        return {
          product: `${op?.product?.product_name}${
            op?.productType ? `　${getProductTypeString(op.productType)}` : ''
          }<br />【返品数量：${item.return_quantity}、返品理由：${
            item.returnReason
          }】`,
        };
      }),
      returnPrice: formatCurrency(returnPrice),
      brandName: order.brand?.brand_name,
      feedback,
    },
  });
  console.log(res);
  return res;
};

//注文変更通知(TOバイヤー)
export const sendOrderUpdateNotification = async ({
  order,
  updateReason,
  changedProducts,
  shipping_name,
  shipping_zip_code,
  shipping_address,
  shipping_phone_number,
  shipping_fee,
  tax,
  price,
  additionalProducts,
}: {
  order: OrderJson;
  updateReason: string;
  changedProducts?: OrderProductJson[];
  shipping_name?: string;
  shipping_zip_code?: string;
  shipping_address?: string;
  shipping_phone_number?: string;
  shipping_fee?: number;
  tax?: number;
  price?: number;
  additionalProducts?: {
    quantity: number;
    productTypes: ProductType;
  }[];
}) => {
  const res = await sendEmail({
    toAccountIds: [order.order_owner],
    templateName: EmailTemplates.OrderUpdate,
    templateData: {
      brandName: order.brand?.brand_name,
      orderId: order.id,
      shippingAddress:
        shipping_address || shipping_zip_code
          ? `・お届け先住所：${shipping_zip_code ?? order.shipping_zip_code}　${
              shipping_address ?? order.shipping_address
            }`
          : '',
      shippingName: shipping_name ? `・お届け先名：${shipping_name}` : '',
      shippingPhoneNumber: shipping_phone_number
        ? `・お電話番号：${shipping_phone_number}`
        : '',
      shippingFee:
        shipping_fee !== undefined
          ? `・送料：${formatCurrency(shipping_fee)}`
          : '',
      price:
        price !== undefined ? `・変更後合計金額：${formatCurrency(price)}` : '',
      changedProduct_title: changedProducts?.length ? '・変更された商品' : '',
      products: changedProducts?.map((item) => ({
        product: `${item.product.product_name}${
          item.productType ? `　${getProductTypeString(item.productType)}` : ''
        }<br />【数量：${item.order_product_quantity}、掛け率：${
          item.product.is_open_price
            ? 'オープン価格'
            : item.order_product_wholesale_rate
        }%、販売価格：${formatCurrency(item.order_product_price)}】`,
      })),
      additionalProductsTitle: additionalProducts?.length
        ? '・追加された商品'
        : '',
      additionalProducts: additionalProducts?.map((additionalProduct) => {
        return `${additionalProduct.productTypes.product.product_name}${
          additionalProduct.productTypes.type &&
          `　${getProductTypeString(additionalProduct.productTypes.toJson()!)}`
        }<br />【数量：${additionalProduct.quantity}、掛け率：${
          additionalProduct.productTypes.product.is_open_price
            ? 'オープン価格'
            : `${additionalProduct.productTypes.product.wholesaleRate}%`
        }、販売価格：${formatCurrency(
          additionalProduct.productTypes.product.unitPrice
        )}】`;
      }),
      updateReason,
    },
    sendToAdminBcc: true,
  });
  console.log(res);
  return res;
};

//サプライヤー招待メール
export const sendSupplierInvitation = async (
  from: string,
  subject: string,
  message: string,
  shop_name: string,
  to: string
) => {
  const res = await sendEmail({
    source: from,
    toAddresses: [to],
    templateName: EmailTemplates.SupplierInvitation,
    templateData: {
      subject,
      message,
      shop_name,
      encodedEmail: encodeURIComponent(to),
    },
    sendToAdminBcc: true,
  });
  console.log(res);
  return res;
};

//バイヤー招待メール
export const sendBuyerInvitation = async (
  from: string,
  subject: string,
  message: string,
  brandId: string,
  brand_name: string,
  to: string
) => {
  const res = await sendEmail({
    source: from,
    toAddresses: [to],
    templateName: EmailTemplates.BuyerInvitation,
    templateData: {
      subject,
      message,
      brandId,
      brand_name,
      encodedEmail: encodeURIComponent(to),
    },
    sendToAdminBcc: true,
  });
  console.log(res);
  return res;
};

//不良品／欠品報告通知
export const sendReport = async (
  order: OrderReadModel,
  reportProducts: ReportProduct[]
) => {
  const buyer = await getAccount(order.buyer_id!);
  const supplier = await getAccount(order.supplier_id!);

  const res = await sendEmail({
    toAccountIds: [supplier.id],
    sendToAdminBcc: true,
    templateName: EmailTemplates.OrderReportNotification,
    templateData: {
      orderId: order.id,
      buyerName: buyer.company_name,
      products: reportProducts.map((item) => ({
        product: `${item.orderproduct!.product!.product_name}${
          item.orderproduct!.productType
            ? `　${getProductTypeString(item.orderproduct!.productType)}`
            : ''
        }<br />【内容：${ReportProductTypeLabel[item.report_type]}、数量：${
          item.report_quantity
        }、コメント：${item.report_comment}】`,
      })),
      requestType: ReportRequestTypeLabel[reportProducts[0].request_type],
      brandName: order.brand?.brand_name,
    },
  });
  console.log(res);
  return res;
};

//レビュー投稿通知
export const sendReviewNotification = async (
  buyerName: string,
  brandOwner: string,
  brandName: string,
  orderId: string
) => {
  const res = await sendEmail({
    toAccountIds: [brandOwner],
    sendToAdminBcc: true,
    templateName: EmailTemplates.ReviewNotification,
    templateData: {
      buyerName,
      brandName,
      orderId,
    },
  });
  console.log(res);
  return res;
};
