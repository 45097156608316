import React from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../../layouts/footer/Footer';
import ErrorBoundary from '../ErrorBoundary';
import LoadingContainer from '../LoadingContainer';
import MagFooter from '../MagFooter';
import MagHeader from '../MagHeader';
import { authenticatingSelector } from '~redux/auth/selectors/authenticating';
import { Brand } from '~redux/brand/types';
import { useTitle } from '~view/hooks/title';
import Head from '~view/pages/common/Head';

type PageProps = {
  title?: string;
  loginLink?: string;
  hideMenu?: boolean;
  direct?: boolean;
  directBrand?: Brand;
  contactId?: string;
};

const MagPage: React.FC<PageProps> = ({ title, children }) => {
  const isAuthenticating = useSelector(authenticatingSelector);

  useTitle(title);

  if (isAuthenticating) {
    return <LoadingContainer isLoading />;
  }

  return (
    <>
      <Head />
      <MagHeader />
      <ErrorBoundary>{children}</ErrorBoundary>
      <MagFooter />
    </>
  );
};

export default MagPage;
