import { createReducer } from '@reduxjs/toolkit';
import { receiveChargeBatchTargets } from '../actions';
import { Order } from '~redux/buyer/types';

type ChargeBatchState = Order[];

const initialState: ChargeBatchState = [];

export const chargeBatchReducer = createReducer<ChargeBatchState>(
  initialState,
  (builder) =>
    builder.addCase(receiveChargeBatchTargets, (state, action) => {
      return action.payload;
    })
);
