import { createReducer } from '@reduxjs/toolkit';
import { setFeedback } from '../actions';

const initialState = '';

const feedbackReducer = createReducer(initialState, {
  [setFeedback.type]: (_, { payload }) => payload,
});

export default feedbackReducer;
