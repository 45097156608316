import React, { useEffect } from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { authUserSelector } from '~redux/auth/selectors/authUser';

const HubSpotTracker: React.FC = () => {
  const { setPathPageView, setIdentity, setTrackPageView } = useTrackingCode();
  const authUser = useSelector(authUserSelector);
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//js.hs-scripts.com/9130201.js';
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!authUser) {
      return;
    }
    setTrackPageView();
    setIdentity(authUser.attributes.email);
    setPathPageView(location.pathname);
  }, [authUser, location.pathname]);

  return null;
};

export default HubSpotTracker;
