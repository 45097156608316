import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, Nav, Navbar } from 'reactstrap';

const Translation: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [language, setLanguage] = useState<'JA' | 'EN'>();

  const submenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const submenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  const translate = useCallback((language) => {
    if (language === 'EN') {
      window.location.hash = '#googtrans(ja|en)';
    } else {
      window.location.hash = '#googtrans(ja|ja)';
    }
    window.location.reload();
  }, []);

  useEffect(() => {
    if (window.location.hash === '#googtrans(ja|ja)') {
      setLanguage('JA');
      return;
    }
    if (window.location.hash === '#googtrans(ja|en)') {
      setLanguage('EN');
      return;
    }
    if (window.navigator.language !== 'ja') {
      translate('EN');
      return;
    }
  }, []);

  return (
    <Nav>
      <Dropdown
        nav
        inNavbar
        onMouseEnter={submenuOpen}
        onMouseLeave={submenuClose}
        toggle={toggle}
        isOpen={isMenuOpen}
      >
        <a
          aria-haspopup="true"
          className="nav-link p-0"
          aria-expanded="true"
          translate="no"
        >
          {language ?? 'JA'}
        </a>
        <DropdownMenu>
          <DropdownItem
            className={`nav-item`}
            onClick={() => translate('JA')}
            translate="no"
          >
            JA
          </DropdownItem>
          <DropdownItem
            className={`nav-item`}
            onClick={() => translate('EN')}
            translate="no"
          >
            EN
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

export default Translation;
