import { createSlice } from '@reduxjs/toolkit';
import { TFeaturedContent } from '~domain/cms/FeaturedContent';

const featuredContentSlice = createSlice({
  initialState: null as TFeaturedContent | null,
  name: 'featuredContent',
  reducers: {
    setFeaturedContent: (state, { payload }) => payload,
    clearFeaturedContent: () => null,
  },
});

export const {
  setFeaturedContent,
  clearFeaturedContent,
} = featuredContentSlice.actions;

export default featuredContentSlice.reducer;
