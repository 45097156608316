import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~redux/reducer';
import { FavoriteProduct, FavoriteProductList } from '~types/api';

type FavoriteProductListState = {
  listId: string;
  listName: string;
  productIds: string[];
  imageKeys: string[];
};

type FavoriteProductState = {
  id: string;
  productId: string;
  listId: string;
};

type State = {
  byListId: Record<string, FavoriteProductListState>;
  byProductId: Record<string, FavoriteProductState>;
  isLoading: boolean;
};

const initialState: State = {
  byListId: {},
  byProductId: {},
  isLoading: false,
};

const favoriteProductSlice = createSlice({
  name: 'favorite/product',
  initialState,
  reducers: {
    fetchedFavoriteProductList: (state) => {
      state.isLoading = true;
    },
    recievedFavoriteProductList: (
      state,
      { payload }: PayloadAction<FavoriteProductList[]>
    ) => {
      payload.forEach((list) => {
        state.byListId[list.id!] = {
          listId: list.id!,
          listName: list.list_name!,
          productIds: (list.products?.items
            ?.filter((item) => item?.product)
            .map((item) => item?.product_id) ?? []) as string[],
          imageKeys: (list.products?.items
            ?.filter((item) => item?.product?.imageKeys?.items?.length)
            .map((item) => item!.product?.imageKeys?.items?.[0]?.imageKey) ??
            []) as string[],
        };
        list.products?.items
          ?.filter((item) => item?.product)
          .forEach((product) => {
            state.byProductId[product?.product_id!] = {
              id: product?.id!,
              productId: product?.product_id!,
              listId: list.id!,
            };
          });
      });
      state.isLoading = false;
    },
    updatedFavoriteProductList: (
      state,
      {
        payload: { listId, list },
      }: PayloadAction<{ listId: string; list: Partial<FavoriteProductList> }>
    ) => {
      if (!state.byListId[listId]) {
        return state;
      }
      state.byListId[listId].listName = list.list_name!;
    },
    removedFavoriteProductList: (state, { payload }: PayloadAction<string>) => {
      delete state.byListId[payload];
    },
    addedFavoriteProduct: (
      state,
      { payload }: PayloadAction<FavoriteProduct>
    ) => {
      if (state.byListId[payload.favorite_list_id!]) {
        state.byListId[payload.favorite_list_id!].productIds.unshift(
          payload.product_id!
        );
      }
      state.byProductId[payload.product_id!] = {
        id: payload?.id!,
        productId: payload?.product_id!,
        listId: payload.favorite_list_id!,
      };
    },
    removedFavoriteProduct: (
      state,
      {
        payload: { listId, productId },
      }: PayloadAction<{ listId: string; productId: string }>
    ) => {
      if (state.byListId[listId]) {
        state.byListId[listId].productIds = state.byListId[
          listId
        ].productIds.filter((id) => id !== productId);
      }
      delete state.byProductId[productId];
    },
    clearedFavoriteProductList: () => initialState,
  },
});

export const {
  fetchedFavoriteProductList,
  recievedFavoriteProductList,
  updatedFavoriteProductList,
  removedFavoriteProductList,
  addedFavoriteProduct,
  removedFavoriteProduct,
  clearedFavoriteProductList,
} = favoriteProductSlice.actions;

export const { reducer } = favoriteProductSlice;

export const selectFavoriteProductState = (state: RootState) =>
  state.favorite.product;
export const selectFavoriteProductLists = createSelector(
  selectFavoriteProductState,
  (state) => Object.values(state.byListId)
);
export const selectIsFavoriteProductListLoading = createSelector(
  selectFavoriteProductState,
  (state) => state.isLoading
);
export const makeSelectFavoriteProductListState = (listId: string) =>
  createSelector(
    selectFavoriteProductState,
    (state) => state.byListId[listId] ?? {}
  );
export const makeSelectFavoriteProductListName = (listId: string) =>
  createSelector(
    makeSelectFavoriteProductListState(listId),
    (state) => state.listName
  );
export const makeSelectFavoriteProductListImageKeys = (listId: string) =>
  createSelector(
    makeSelectFavoriteProductListState(listId),
    (state) => state.imageKeys
  );
export const makeSelectFavoriteProductListProducts = (listId: string) =>
  createSelector(
    makeSelectFavoriteProductListState(listId),
    (state) => state.productIds
  );
export const makeSelectFavoriteProductListIdByProduct = (productId: string) =>
  createSelector(
    selectFavoriteProductLists,
    (state) => state.find((list) => list.productIds.includes(productId))?.listId
  );
export const makeSelectIsSavedByProduct = (productId: string) =>
  createSelector(
    makeSelectFavoriteProductListIdByProduct(productId),
    (listId) => !!listId
  );
export const makeSelectFavoriteProductIds = (listId: string) =>
  createSelector(selectFavoriteProductState, (state) =>
    Object.values(state.byProductId)
      .filter((product) => product.listId === listId)
      .map((product) => product.id)
  );
export const makeSelectFavoriteProductId = (productId: string) =>
  createSelector(
    selectFavoriteProductState,
    (state) => state.byProductId[productId]?.id
  );
