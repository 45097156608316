import { Auth } from '@aws-amplify/auth';
import { Dispatch } from 'redux';
// import awsmobile from '../../../../src/aws-exports';
import { getAccount } from '../../../graphql/queries';
import { browserHistory } from '../../../history';
import { AuthUser, clearAuthUser, receiveAuthUser } from '../actions/authUser';
import { clearAccount, setAccount } from '~redux/account/actions/account';
import { clearBuyerInfo } from '~redux/buyer/actions/buyerInfo';
import { executeQuery } from '~utils/graphql';

export const signIn = (
  email: string,
  password: string,
  nextPath?: string,
  auth?: AuthUser,
  metadata?: any
) => async (dispatch: Dispatch) => {
  const user = auth ?? (await Auth.signIn(email, password));
  const {
    data: { getAccount: account },
  } = await executeQuery(getAccount, { id: user.username });
  dispatch(setAccount(account));
  if (!auth) {
    dispatch(receiveAuthUser(user));
  }
  setTimeout(() => browserHistory.push(nextPath ?? '/home'), 100);
};

export const customSignIn = (
  email: string,
  token: string,
  nextPath?: string
) => async (dispatch: Dispatch) => {
  // Auth.configure({
  //   ...awsmobile,
  //   authenticationFlowType: 'CUSTOM_AUTH',
  // });
  const user = await Auth.signIn(email);
  const res = await Auth.sendCustomChallengeAnswer(user, token);
  const {
    data: { getAccount: account },
  } = await executeQuery(getAccount, { id: res.attributes.sub });
  dispatch(
    setAccount({
      ...account,
      buyerInfo: account.buyerInfo?.items?.[0],
      supplierInfo: account.supplierInfo?.items?.[0],
    })
  );
  dispatch(receiveAuthUser(res));
  setTimeout(() => browserHistory.push(nextPath ?? '/home'), 100);
};

export const signOut = () => async (dispatch: Dispatch) => {
  await Auth.signOut();
  dispatch(clearAuthUser());
  dispatch(clearAccount());
  dispatch(clearBuyerInfo());
  setTimeout(() => browserHistory.push('/'), 100);
};
