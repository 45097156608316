import { API, graphqlOperation } from 'aws-amplify';
import { Entity } from '~core/domain/Entity';
import { ProductBase } from '~domain/product/base/ProductBase';
import * as customQueries from '~graphql/custom_queries';
import { ProductType } from '~redux/brand/types';
import { CartItem as CartItemJson } from '~redux/cart/types';
import { getPublicImageUrl } from '~utils/image';

export type CartItemType = {
  id: string;
  quantity: number;
  product_id: string;
  product_type_id?: string;
  product: ProductBase;
  productType?: ProductType;
  image?: string;
  owner: string;
  brand_id?: string;
  is_direct?: boolean;
  is_pay_directly?: boolean;
  is_consign?: boolean;
  saved_for_later?: boolean | null;
  collection_id?: string;
};

export abstract class CartItemBase extends Entity<CartItemType> {
  constructor(params: CartItemType) {
    super(params);
  }

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  static create(params: CartItemJson): CartItemBase {
    throw new Error('Not implemented');
  }

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async getById(id: string): Promise<CartItemBase> {
    const res = await API.graphql<any>(
      graphqlOperation(customQueries.getShopCartWithImagekey, {
        id,
      })
    );
    const response = res.data!.getShopCart;

    return this.create({
      id: response.id,
      owner: response.shopcart_owner,
      quantity: response.shopcart_quantity,
      product_id: response.product_id,
      product_type_id: response.product_type_id,
      product: response.product,
      productType: response.producttype,
      image: getPublicImageUrl(
        response.producttype?.imageKey ??
          response.product?.imageKeys?.items?.[0]?.imageKey
      ),
      brand_id: response.brand_id,
      is_direct: response.is_direct,
      is_pay_directly: response.is_pay_directly,
      is_consign: response.is_consign,
      saved_for_later: response.saved_for_later,
    });
  }

  get totalPrice() {
    return this.product.unitPrice * this.quantity;
  }

  toJson(): CartItemJson {
    return {
      id: this.id,
      quantity: this.quantity,
      product_id: this.product_id,
      product_type_id: this.product_type_id,
      product: this.product.toJson(),
      productType: this.productType,
      brand_id: this.brand_id,
      is_direct: this.is_direct,
      is_pay_directly: this.is_pay_directly,
      is_consign: this.is_consign,
      image: this.image,
      owner: this.owner,
      saved_for_later: this.saved_for_later,
      collection_id: this.collection_id,
    };
  }
}
