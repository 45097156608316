// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-IndividualPageLayout-Header-___styles-modules__header{position:sticky;top:0;display:flex;flex-direction:row;justify-content:space-between;align-items:center;background-color:#fff;border-bottom:1px solid #e8e8e8;z-index:999}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__logo{width:128px}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__logo>a{display:flex;align-items:center}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__icons{display:flex}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__icons>*{display:flex;justify-content:center;align-items:center;padding:.5rem !important}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown>nav{padding:0 !important}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown>nav>div>div>div>a{padding:0 !important;margin:0 !important}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown a{color:inherit;background:none;border:none !important}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown a:active{color:inherit;background:none}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown .src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown-items>div{min-width:180px}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__exist-new-messages-link{font-weight:bold}.src-view-widgets-IndividualPageLayout-Header-___styles-modules__exist-new-messages-link::before{content:\"・\";color:#000;font-size:1.5rem}", ""]);
// Exports
exports.locals = {
	"header": "src-view-widgets-IndividualPageLayout-Header-___styles-modules__header",
	"logo": "src-view-widgets-IndividualPageLayout-Header-___styles-modules__logo",
	"icons": "src-view-widgets-IndividualPageLayout-Header-___styles-modules__icons",
	"dropdown": "src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown",
	"dropdown-items": "src-view-widgets-IndividualPageLayout-Header-___styles-modules__dropdown-items",
	"exist-new-messages-link": "src-view-widgets-IndividualPageLayout-Header-___styles-modules__exist-new-messages-link"
};
module.exports = exports;
