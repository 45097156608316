/**
 *  Footer Main
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import './footer.scss';
import logo from '../../assets/images/futura_homula_logo_white.svg';
import { authUserSelector } from '~redux/auth/selectors/authUser';

const Footer = () => {
  const auth = useSelector(authUserSelector);

  return (
    <footer className="p-4 p-md-5 border-top">
      <Container>
        <div>
          <a href="/home">
            <img
              src={logo}
              alt=""
              style={{
                width: '150px',
                height: '40px',
                objectFit: 'cover',
                objectPosition: '-20px',
              }}
            />
          </a>
        </div>
        <div>
          <p className="text-black-50">online wholesale made for you</p>
          <div className="flex flex-wrap">
            {/* <div className="mr-4">
                <a href="#" target="_blank">
                  <img
                    src={require(`../../assets/images/home-service/sns-youtube-wt.svg`)}
                    alt=""
                  />
                </a>
              </div>
              <div className="mr-4">
                <a href="#" target="_blank">
                  <img
                    src={require(`../../assets/images/home-service/sns-twitter-wt.svg`)}
                    alt=""
                  />
                </a>
              </div>
              <div className="mr-4">
                <a href="#" target="_blank">
                  <img
                    src={require(`../../assets/images/home-service/sns-facebook-wt.svg`)}
                    alt=""
                  />
                </a>
              </div> */}
            {/* <div className="mr-4">
                <a
                  href="https://www.instagram.com/homula.official"
                  target="_blank"
                >
                  <img
                    src={require(`../../assets/images/home-service/sns-instagram-wt.svg`)}
                    alt=""
                  />
                </a>
              </div> */}
            {/* <div className="mr-4">
                <a href="#" target="_blank">
                  <img
                    src={require(`../../assets/images/home-service/sns-note-wt.svg`)}
                    alt=""
                  />
                </a>
              </div> */}
          </div>

          <div className="d-flex flex-wrap">
            <div className="mr-5">
              <p className="font-weight-bold">Support</p>
              <p>
                <a
                  href="/terms/buyertermsofservice"
                  target="_blank"
                  rel="noreferrer"
                >
                  バイヤー利用規約
                </a>
              </p>
              <p>
                <a
                  href="/terms/suppliertermsofservice"
                  target="_blank"
                  rel="noreferrer"
                >
                  サプライヤー利用規約
                </a>
              </p>
              <p>
                <a href="/terms/privacypolicy" target="_blank" rel="noreferrer">
                  プライバシーポリシー
                </a>
              </p>
              <p>
                <a href="/terms/returnpolicy" target="_blank" rel="noreferrer">
                  返品・引取りポリシー
                </a>
              </p>
            </div>

            <div className="mr-5">
              <p className="font-weight-bold">Contact</p>
              <p>
                <a
                  href="https://www.instagram.com/homula.official"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </p>
              <p>
                <a
                  href="https://blog.homula.jp"
                  target="_blank"
                  rel="noreferrer"
                >
                  ブログ
                </a>
              </p>
              <p>
                <a href="/material/supplier" target="_blank" rel="noreferrer">
                  出店資料請求
                </a>
              </p>
              <p>
                <a
                  href={
                    !auth || auth?.attributes.profile === 'buyer'
                      ? `https://share.hsforms.com/11dqCMmpCRnKideE1Qs-jKQ5fowp${
                          auth?.attributes?.email
                            ? `?email=${encodeURIComponent(
                                auth?.attributes?.email
                              )}`
                            : ''
                        }`
                      : 'https://corp.homula.jp/contact-1'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  お問い合わせ
                </a>
              </p>
            </div>

            <div className="mr-5">
              <p className="font-weight-bold">Company</p>
              <p>
                <a href="https://www.homula.jp/company">会社情報</a>
              </p>
              <p>
                <a href="https://www.homula.jp/recruit">採用情報</a>
              </p>
            </div>

            <div className="align-self-end ml-md-auto text-black-50">
              homula All Rights Reserved.
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
