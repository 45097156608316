import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import * as queries from '../graphql/queries';
import {
  Order as OrderJson,
  OrderProduct as OrderProductJson,
  OrderStatusType as OrderStatusTypeJson,
  PaymentTermType as PaymentTermTypeJson,
} from '~redux/buyer/types';
import { ChargeStatusType } from '~redux/payment/types';

// deprecated
export const getOrderChargeStatus = (
  order: OrderJson
): ChargeStatusType | undefined => {
  if (!order?.chargeStatuses?.length) {
    return undefined;
  }
  // 最新のステータスを返す
  return [...order.chargeStatuses].sort(({ updatedAt: a }, { updatedAt: b }) =>
    moment(b).diff(a)
  )[0]?.status;
};

// deprecated
export const getOrderStatus = (order: OrderJson) => {
  //未確認がある場合
  if (
    order.orderproducts.some(
      (orderproduct) =>
        getOrderProductStatus(orderproduct) === OrderStatusTypeJson.unconfirmed
    )
  ) {
    //未確認
    return OrderStatusTypeJson.unconfirmed;
  }

  //確認済みがある場合
  if (
    order.orderproducts.some(
      (orderproduct) =>
        getOrderProductStatus(orderproduct) === OrderStatusTypeJson.confirmed
    )
  ) {
    //確認済
    return OrderStatusTypeJson.confirmed;
  }

  //出荷済みがある場合
  if (
    order.orderproducts.some(
      (orderproduct) =>
        getOrderProductStatus(orderproduct) === OrderStatusTypeJson.shipped
    )
  ) {
    //出荷済み
    return OrderStatusTypeJson.shipped;
  }

  //キャンセルがある場合
  if (
    order.orderproducts.some(
      (orderproduct) =>
        getOrderProductStatus(orderproduct) ===
        OrderStatusTypeJson.canceledByStripe
    )
  ) {
    // Stripeキャンセル
    return OrderStatusTypeJson.canceledByStripe;
  }

  //キャンセルがある場合
  if (
    order.orderproducts.some(
      (orderproduct) =>
        getOrderProductStatus(orderproduct) ===
        OrderStatusTypeJson.canceledByBuyer
    )
  ) {
    // バイヤーキャンセル
    return OrderStatusTypeJson.canceledByBuyer;
  }

  //キャンセルがある場合
  if (
    order.orderproducts.some(
      (orderproduct) =>
        getOrderProductStatus(orderproduct) ===
        OrderStatusTypeJson.canceledBySupplier
    )
  ) {
    // サプライヤーキャンセル
    return OrderStatusTypeJson.canceledBySupplier;
  }

  //上記以外はデフォルト（未払い）
  return OrderStatusTypeJson.default;
};

export const getOrderProductStatus = (orderproduct: OrderProductJson) => {
  if (!orderproduct.orderstatus?.length) {
    return OrderStatusTypeJson.unconfirmed;
  }

  if (
    orderproduct.orderstatus.some(
      ({ status }) => status === OrderStatusTypeJson.default
    )
  ) {
    // デフォルト（未払い）
    return OrderStatusTypeJson.default;
  }

  // Stripeキャンセル
  if (
    orderproduct.orderstatus.some(
      ({ status }) => status === OrderStatusTypeJson.canceledByStripe
    )
  ) {
    return OrderStatusTypeJson.canceledByStripe;
  }

  //バイヤーキャンセル
  if (
    orderproduct.orderstatus.some(
      ({ status }) => status === OrderStatusTypeJson.canceledByBuyer
    )
  ) {
    return OrderStatusTypeJson.canceledByBuyer;
  }

  //サプライヤーキャンセル
  if (
    orderproduct.orderstatus.some(
      ({ status }) => status === OrderStatusTypeJson.canceledBySupplier
    )
  ) {
    //サプライヤーキャンセル
    return OrderStatusTypeJson.canceledBySupplier;
  }

  // 出荷済みがある場合
  if (
    orderproduct.orderstatus.some(
      ({ status }) => status === OrderStatusTypeJson.shipped
    )
  ) {
    // 出荷済み
    return OrderStatusTypeJson.shipped;
  }

  // 確認済みがある場合
  if (
    orderproduct.orderstatus.some(
      ({ status }) => status === OrderStatusTypeJson.confirmed
    )
  ) {
    // 確認済
    return OrderStatusTypeJson.confirmed;
  }

  // その他の場合
  // 未確認
  return OrderStatusTypeJson.unconfirmed;
};

export const hasPastOrder = async ({
  buyerId,
  brandId,
}: {
  buyerId: string;
  brandId: string;
}) => {
  // 過去のオーダー取得（一日前以前）
  const {
    data: {
      searchOrders: { items },
    },
  } = await API.graphql<any>(
    graphqlOperation(queries.searchOrders, {
      filter: {
        order_owner: { eq: buyerId },
        brand_id: { eq: brandId },
        createdAt: { lt: moment().add(-1, 'day').endOf('day') },
      },
      limit: 1,
    })
  );

  return items.length > 0;
};

// 後払い・プレオーダーの場合trueを返す
export const isPostpayment = (
  paymentTerm: PaymentTermTypeJson
): paymentTerm is Exclude<
  PaymentTermTypeJson,
  PaymentTermTypeJson.postpayment | PaymentTermTypeJson.preorder
> =>
  paymentTerm === PaymentTermTypeJson.postpayment ||
  paymentTerm === PaymentTermTypeJson.preorder;

export const isCanceled = (order: OrderJson) =>
  [
    OrderStatusTypeJson.canceledByBuyer,
    OrderStatusTypeJson.canceledByStripe,
    OrderStatusTypeJson.canceledBySupplier,
    OrderStatusTypeJson.default,
  ].includes(getOrderStatus(order));

export const isShipped = (order: OrderJson) =>
  getOrderStatus(order) === OrderStatusTypeJson.shipped;

export const isCharged = (order: OrderJson) =>
  getOrderChargeStatus(order) === ChargeStatusType.charged;

export const isUnCharged = (order: OrderJson) =>
  getOrderChargeStatus(order) === ChargeStatusType.uncharged;

export const getChargeDate = (order: OrderJson) => {
  if (isCanceled(order)) {
    //キャンセル済
    return undefined;
  }

  const shippedStatus = order.orderproducts
    .find((op) =>
      op.orderstatus.some(
        ({ status }) => status === OrderStatusTypeJson.shipped
      )
    )
    ?.orderstatus?.find(({ status }) => status === OrderStatusTypeJson.shipped);

  //未発送の場合
  if (!shippedStatus) {
    //発送予定日（未入力時は未定）
    return !order.shipping_date || isPostpayment(order.payment_term)
      ? undefined
      : new Date(order.shipping_date);
  }

  const chargedStatus = order.chargeStatuses?.find(
    ({ status }) => status === ChargeStatusType.charged
  );

  //チャージ済みの場合
  if (chargedStatus) {
    //チャージ日
    return new Date(chargedStatus.createdAt);
  }

  //出荷日＋60日
  const chargeDate = new Date(shippedStatus.createdAt);
  chargeDate.setDate(chargeDate.getDate() + 60);
  return chargeDate;
};
