import { AnyAction } from 'redux';
import {
  RECEIVE_BRAND_DETAILS,
  ADD_BRAND_PRODUCTS,
  CLEAR_ALL_BRAND_PRODUCTS,
  RECEIVE_BRAND_PRODUCTS_NEXT_TOKEN,
  CLEAR_BRAND_PRODUCTS_NEXT_TOKEN,
  CLEAR_BRAND_DETAILS,
  SET_BRAND_PRODUCTS,
} from '../actions/details';

import { BrandDetails, Product } from '../types';

type BrandItemsState = {
  info: BrandDetails | null;
  products: Product[];
  nextToken: string | null;
  lastItems: Product[];
  lastItemsIndex: number;
};

const initialState: BrandItemsState = {
  info: null,
  products: [],
  nextToken: null,
  lastItems: [],
  lastItemsIndex: 0,
};

const detailsReducer = (
  state: BrandItemsState = initialState,
  action: AnyAction
): BrandItemsState => {
  const { payload } = action;
  switch (action.type) {
    case RECEIVE_BRAND_DETAILS:
      return {
        ...state,
        info: payload,
      };
    case CLEAR_BRAND_DETAILS:
      return {
        ...state,
        info: null,
      };
    case SET_BRAND_PRODUCTS:
      return {
        ...state,
        products: payload,
        lastItems: payload,
        lastItemsIndex: state.products.length,
      };
    case ADD_BRAND_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...payload],
        lastItems: payload,
        lastItemsIndex: state.products.length,
      };
    case CLEAR_ALL_BRAND_PRODUCTS:
      return {
        ...state,
        products: initialState.products,
        lastItems: initialState.lastItems,
        lastItemsIndex: initialState.lastItemsIndex,
      };
    case RECEIVE_BRAND_PRODUCTS_NEXT_TOKEN:
      return {
        ...state,
        nextToken: payload,
      };
    case CLEAR_BRAND_PRODUCTS_NEXT_TOKEN:
      return {
        ...state,
        nextToken: initialState.nextToken,
      };
    default:
      return state;
  }
};

export default detailsReducer;
