import algoliasearch from 'algoliasearch';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import {
  BasicDoc,
  Configure,
  connectStateResults,
  Hit,
  HitsProvided,
  InstantSearch,
  InstantSearchProps,
  StateResultsProvided,
} from 'react-instantsearch-core';
import { useSelector } from 'react-redux';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { setIndexName, setQueryId } from '~utils/segment';

type InstantSearchHits<T = any> = HitsProvided<
  Hit<
    T & {
      __queryID?: string;
      __position?: number;
    }
  >
>;

export const InstantSearchResultsContext = createContext<
  StateResultsProvided<BasicDoc> & InstantSearchHits
>({} as StateResultsProvided<BasicDoc> & InstantSearchHits);

const InstantSearchStateResultsProvider: React.FC<
  StateResultsProvided<BasicDoc>
> = React.memo(({ children, ...props }) => {
  if (
    props.searchResults?.hits.length &&
    props.searchResults.hits[0].__queryID
  ) {
    setQueryId(props.searchResults.hits[0].__queryID);
  }
  return (
    <InstantSearchResultsContext.Provider
      value={{ ...props, hits: props.searchResults?.hits ?? [] }}
    >
      {children}
    </InstantSearchResultsContext.Provider>
  );
});

const ConnectedInstantSearchResultsProvider = connectStateResults(
  InstantSearchStateResultsProvider
);

const InstantSearchProvider: React.FC<
  Omit<InstantSearchProps, 'searchClient'> & {
    query?: string;
    hitsPerPage?: number;
    filters?: any;
    facetFilters?: (string | string[])[];
    attributesToRetrieve?: string[];
    length?: number;
    offset?: number;
    distinct?: boolean;
  }
> = React.memo(
  ({
    children,
    indexName,
    query,
    hitsPerPage,
    filters,
    facetFilters,
    attributesToRetrieve,
    length,
    offset,
    distinct,
  }) => {
    const searchClient = useMemo(
      () =>
        algoliasearch(
          process.env.REACT_APP_ALGOLIA_APPLICATION_ID!,
          process.env.REACT_APP_ALGOLIA_SEARCH_KEY!
        ),
      []
    );
    const user = useSelector(authUserSelector);

    useEffect(() => {
      setIndexName(indexName);
    }, [indexName]);

    return (
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure
          query={query}
          hitsPerPage={hitsPerPage}
          filters={filters}
          facetFilters={facetFilters}
          attributesToRetrieve={attributesToRetrieve}
          userToken={user?.attributes.sub}
          length={length}
          offset={offset}
          clickAnalytics
          distinct={distinct}
        />
        <ConnectedInstantSearchResultsProvider>
          {children}
        </ConnectedInstantSearchResultsProvider>
      </InstantSearch>
    );
  }
);

export default InstantSearchProvider;

export const useInstantSearch = <T,>(): InstantSearchHits<T> &
  StateResultsProvided<BasicDoc> => {
  return useContext(InstantSearchResultsContext);
};
