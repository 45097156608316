import './styles.scss';
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';
import menuJson from '~constants/menu.json';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { featuredContentsSelector } from '~redux/cms/selectors';
import { tagListSelector } from '~redux/tag/selectors';
import { getTagList } from '~redux/tag/thunk';

const CategoryBurgerMenu: React.FC = () => {
  const dispatch = useDispatch();
  const featuredContents = useSelector(featuredContentsSelector);
  const tagList = useSelector(tagListSelector);
  const authUser = useSelector(authUserSelector);
  const [isOpen, setIsOpen] = useState(false);

  const menu = useMemo(() => {
    return Object.entries(menuJson['2']);
  }, []);

  const onMenuClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    // dispatch(getMenuList());
    // dispatch(getBrandCollections(!authUser));
    dispatch(getTagList(!authUser));
  }, [authUser]);

  return (
    <Menu
      className="category-burger-menu"
      isOpen={isOpen}
      onStateChange={({ isOpen }) => setIsOpen(isOpen)}
      customBurgerIcon={<AiOutlineMenu />}
    >
      {authUser && (
        <p className="font-weight-bold">
          <Link to="/sale">限定セール</Link>
        </p>
      )}
      <p>
        <Link to="/list/new-products/all">新着アイテム</Link>
      </p>
      {menu.map(([key, value]) => (
        <MenuItem
          key={key}
          title={key}
          productListPath={value.product_list_path}
          brandListPath={value.brand_list_path}
          subMenu={Object.entries(value.categories).map(([key, value]) => ({
            title: key,
            path: value.path,
          }))}
          onMenuClick={onMenuClick}
        />
      ))}
      {authUser && !!featuredContents?.length && (
        <MenuItem
          key="features"
          title="シーズン特集"
          subMenu={featuredContents.map((content) => ({
            title: content.title,
            path: `/features/${content.id}`,
          }))}
          onMenuClick={onMenuClick}
          expanded
        />
      )}
      {!!tagList?.length && (
        <MenuItem
          key="tags"
          title="ブランドタグ"
          subMenu={tagList.map((tag) => ({
            title: tag.tag_name,
            path: `/list/search?t=brand&s=&tag=${encodeURIComponent(
              tag.tag_name
            )}`,
          }))}
          onMenuClick={onMenuClick}
          expanded
        />
      )}
    </Menu>
  );
};

export default CategoryBurgerMenu;
