import { executeQuery } from './graphql';
import { createThread } from '~graphql/mutations';
import { listThreadsByBrand } from '~graphql/queries';

export const getThread = async (
  brandId: string,
  brandOwner: string,
  buyerOwner: string
) => {
  //スレッドの取得
  const res1 = await executeQuery(listThreadsByBrand, {
    thread_brand_id: brandId,
    filter: {
      thread_buyer_owner: { eq: buyerOwner },
    },
    limit: 1000,
  });

  //スレッドがない場合は新規作成
  if (res1?.data?.listThreadsByBrand?.items?.length) {
    return res1.data.listThreadsByBrand.items[0];
  } else {
    const res2 = await executeQuery(createThread, {
      input: {
        thread_buyer_owner: buyerOwner,
        thread_brand_id: brandId,
        thread_brand_owner: brandOwner,
        owners: [buyerOwner, brandOwner],
      },
    });
    if (res2?.data?.createThread) {
      return res2?.data?.createThread;
    }
  }
};
