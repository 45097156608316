import { createReducer } from '@reduxjs/toolkit';
import { receiveAllBrands } from '../../actions/admin';
import { Brand } from '../../types';

type RequestState = Brand[];

const initialState: RequestState = [];

const adminAllBrandsReducer = createReducer<RequestState>(initialState, {
  [receiveAllBrands.type]: (_, action) => action.payload,
});

export default adminAllBrandsReducer;
