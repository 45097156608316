import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { makeBrandProductSelector } from '~redux/brand/selectors';
import { Product } from '~redux/brand/types';
import {
  makeSelectCartItemsByBrand,
  makeSelectCartItemsByProduct,
} from '~redux/cart/selectors';
import { CartItem } from '~redux/cart/types';
import { productItemSelector } from '~redux/product/selectors';
import { RootState } from '~redux/reducer';
import { Inventory } from '~types/api';

type TInventory = {
  id?: string;
  sku?: string;
  brand_id?: string;
  product_id?: string;
  product_type_id?: string | null;
  inventory?: number | null;
  isContinueSelling?: boolean;
  isOutOfStock?: boolean;
  availableInventory?: number;
};

type InventoryState = {
  byId: Record<string, TInventory>;
  byBrand: Record<
    string,
    {
      bySku: Record<string, { id: string }>;
    }
  >;
  byProduct: Record<string, { ids: string[] }>;
  isLoading: boolean;
};

const initialState: InventoryState = {
  byId: {},
  byBrand: {},
  byProduct: {},
  isLoading: false,
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    fetchedInventories: (state) => {
      state.isLoading = true;
    },
    recievedInventories: (
      state,
      { payload }: PayloadAction<Omit<Inventory, '__typename'>[]>
    ) => {
      payload.forEach((inventory) => {
        const isContinueSelling = !!inventory.product?.continue_selling;
        const isOutOfStock =
          (inventory.product?.out_of_stock ||
            inventory.productType?.out_of_stock) ??
          false;
        const quantity = isOutOfStock ? 0 : inventory.inventory;
        state.byId[inventory.id!] = {
          id: inventory.id,
          sku: inventory.sku,
          brand_id: inventory.brand_id,
          product_id: inventory.product_id,
          product_type_id: inventory.product_type_id,
          isContinueSelling,
          isOutOfStock,
          inventory: quantity,
        };
        if (!state.byBrand[inventory.brand_id!]) {
          state.byBrand[inventory.brand_id!] = { bySku: {} };
        }
        state.byBrand[inventory.brand_id!].bySku[inventory.sku!] = {
          id: inventory.id!,
        };
        if (!state.byProduct[inventory.product_id!]) {
          state.byProduct[inventory.product_id!] = {
            ids: [],
          };
        }
        state.byProduct[inventory.product_id!].ids.push(inventory.id!);
      });
      state.isLoading = false;
    },
    decreaseInventory: (state, { payload: { brandId, sku, quantity } }) => {
      const id = state.byBrand[brandId]?.bySku[sku]?.id;
      if (id && state.byId[id]?.inventory != null) {
        state.byId[id].inventory! -= quantity;
        if (
          state.byId[id].inventory! <= 0 &&
          !state.byId[id].isContinueSelling
        ) {
          state.byId[id].isOutOfStock = true;
        }
      }
    },
    clearedInventory: () => initialState,
  },
});

export const {
  fetchedInventories,
  recievedInventories,
  decreaseInventory,
  clearedInventory,
} = inventorySlice.actions;

export default inventorySlice.reducer;

export const selectInventoryState = (state: RootState) => state.inventory;
export const selectIsInventoryLoading = createSelector(
  selectInventoryState,
  (state) => state.isLoading
);
export const makeSelectInventoryBySku = (brandId: string, sku: string) =>
  createSelector(selectInventoryState, (state) => {
    return state.byId[state.byBrand[brandId]?.bySku[sku]?.id] ?? {};
  });
export const makeSelectInventoryQtyBySku = (brandId: string, sku: string) =>
  createSelector(
    makeSelectInventoryBySku(brandId, sku),
    (inventory) => inventory.inventory
  );
export const makeSelectIsOutOfStockBySku = (brandId: string, sku: string) =>
  createSelector(
    makeSelectInventoryBySku(brandId, sku),
    (inventory) => inventory.isOutOfStock ?? false
  );
export const selectAvailableInventory = (
  inventory: TInventory,
  cartItems: CartItem[]
) => {
  if (inventory?.inventory == null || inventory?.isContinueSelling) {
    return;
  }
  const cartItem = cartItems?.find(
    (item) =>
      item.product_id === inventory.product_id &&
      (!inventory.product_type_id ||
        item.product_type_id === inventory.product_type_id)
  );
  return inventory.inventory - (cartItem?.quantity ?? 0);
};
export const makeSelectAvailableInventoryBySku = (
  brandId: string,
  sku: string
) =>
  createSelector(
    makeSelectInventoryBySku(brandId, sku),
    makeSelectCartItemsByBrand(brandId),
    selectAvailableInventory
  );
export const makeSelectIsAvailableBySku = (brandId: string, sku: string) =>
  createSelector(
    makeSelectAvailableInventoryBySku(brandId, sku),
    (availableInventory) =>
      availableInventory == null || availableInventory >= 1
  );
export const makeSelectInventoriesByProduct = (productId: string) =>
  createSelector(
    selectInventoryState,
    makeSelectCartItemsByProduct(productId),
    (state, cartItems) => {
      const byProduct = state.byProduct[productId];
      if (!byProduct) {
        return {};
      }
      let inventories = byProduct.ids.reduce((prev, id) => {
        const inventory = state.byId[id] ?? {};
        if (!inventory) {
          return prev;
        }
        prev[inventory.sku!] = {
          ...inventory,
          availableInventory: selectAvailableInventory(inventory, cartItems),
        };
        return prev;
      }, {} as Record<string, TInventory>);

      const hasProductType = Object.values(inventories).some(
        ({ product_type_id }) => product_type_id !== null
      );
      inventories = Object.keys(inventories).reduce((prev, key) => {
        const inventory = inventories[key];
        // 商品種類が登録されている場合は、商品自体の在庫数は無視する
        if (hasProductType && inventory.product_type_id === null) {
          return prev;
        }

        return {
          ...prev,
          [key]: inventories[key],
        };
      }, {});
      return inventories;
    }
  );
export const makeSelectInventoriesByProducts = (productIds: string[]) =>
  createSelector(
    productIds.map((id) => makeSelectInventoriesByProduct(id)),
    (...items) =>
      items.reduce(
        (prev, item) => ({ ...prev, ...item }),
        {} as Record<
          string,
          Inventory & {
            isContinueSelling?: boolean;
            isOutOfStock?: boolean;
            availableInventory?: number;
          }
        >
      )
  );

export const makeSelectIsOutOfStockByProduct = (product: Product) => {
  return createSelector(
    makeSelectInventoriesByProduct(product.id!),
    (inventories) => {
      // 予約販売の場合は在庫切れにしない
      if (product.product_preorder === 1) {
        return false;
      }
      if (product.out_of_stock) {
        return true;
      }
      if (product.producttype?.length) {
        return (
          product.producttype.length === Object.values(inventories).length &&
          Object.values(inventories).every(
            (inventory) => inventory.isOutOfStock
          )
        );
      }

      if (!Object.values(inventories)?.length) {
        return false;
      }
      return Object.values(inventories).every(
        (inventory) => inventory.isOutOfStock
      );
    }
  );
};

export const makeSelectIsOutOfStockByProductId = (productId: string) =>
  createSelector(
    (state: RootState) => state,
    makeBrandProductSelector(productId),
    productItemSelector,
    (state, brandProduct, currentProduct) => {
      const product = brandProduct ?? currentProduct;

      if (!product) {
        return false;
      }

      return makeSelectIsOutOfStockByProduct(product)(state);
    }
  );
export const makeSelectInventoryByProduct = (productId: string) =>
  createSelector(makeSelectInventoriesByProduct(productId), (inventories) => {
    if (!Object.values(inventories)?.length) {
      return;
    }
    return Object.values(inventories).reduce(
      (prev, inventory) => prev + (inventory.inventory ?? 0),
      0
    );
  });
export const makeSelectHasInventoryAlertByProduct = (productId: string) =>
  createSelector(
    makeSelectInventoriesByProduct(productId),
    makeSelectCartItemsByProduct(productId),
    (inventories, cartItems) =>
      cartItems.every((cartItem) => cartItem.product.product_preorder !== 1) && // 予約注文の場合は在庫数チェックしない
      Object.values(inventories).some((inventory) => {
        return (
          inventory.availableInventory != null &&
          inventory.availableInventory < 0
        );
      })
  );
export const makeSelectHasInventoryAlertByProducts = (productIds: string[]) =>
  createSelector(
    productIds.map(makeSelectHasInventoryAlertByProduct),
    (...hasAlerts) => hasAlerts.some((hasAlert) => hasAlert)
  );
