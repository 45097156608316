import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  setCollectionList,
  removeCollection,
  clearCollectionList,
} from '../actions';
import { Collection } from '~domain/product/Collection';

const initialState: { byId: Record<string, Collection>; nextToken?: string } = {
  byId: {},
};

const collectionListReducer = createReducer(initialState, {
  [setCollectionList.type]: (
    state,
    {
      payload: { items, nextToken },
    }: PayloadAction<{ items: Collection[]; nextToken?: string }>
  ) => {
    items.forEach((item) => (state.byId[item.id!] = item));
    state.nextToken = nextToken;
  },
  [removeCollection.type]: (state, { payload }) => {
    delete state.byId[payload];
  },
  [clearCollectionList.type]: () => initialState,
});

export default collectionListReducer;
