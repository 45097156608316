import { CONSUMPTION_TAX_RATE } from '~constants/trade';
import { Brand } from '~domain/brand/Brand';
import { BrandDetails, Brand as BrandJson } from '~redux/brand/types';

export const calculatePrice = (basePrice: number, rate: number) => {
  return Math.round(basePrice * (rate / 100));
};

export const calculatePurchasePrice = (
  basePrice: number,
  homulaFeeRate: number
) => {
  return Math.ceil(basePrice * (1 - homulaFeeRate / 100));
};

export const calculateHomulaFee = (
  basePrice: number,
  homulaFeeRate: number
) => {
  return basePrice - calculatePurchasePrice(basePrice, homulaFeeRate);
};

export const calculateTax = (price: number, taxRate = CONSUMPTION_TAX_RATE) =>
  Math.round(price * taxRate);

export const discountPrice = (price: number, rate: number) =>
  price - Math.round(price * (rate / 100));

export const getShippingFee = (
  brand: BrandJson | BrandDetails | Brand,
  subtotal: number
) => {
  return brand.brand_shippinng_fee_criteria > subtotal
    ? brand.brand_shippinng_fee
    : 0;
};
