import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Row,
} from 'reactstrap';
import { TFeaturedContent } from '~domain/cms/FeaturedContent';
import { Tag } from '~redux/tag/actions';
import './styles.scss';

type FeaturedNavMenuProps = {
  features?: TFeaturedContent[];
  tagList?: Tag[];
};

const MENU_TAG_LIST = 1;
const MENU_FEATURES = 2;

const FeaturedNavMenu: React.FC<FeaturedNavMenuProps> = ({
  features,
  tagList,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [subMenu, setSubMenu] = useState(
    features?.length ? MENU_FEATURES : MENU_TAG_LIST
  );

  const submenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const submenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  return (
    <Nav navbar>
      <Dropdown
        nav
        inNavbar
        onMouseEnter={submenuOpen}
        onMouseLeave={submenuClose}
        toggle={toggle}
        isOpen={isMenuOpen}
      >
        <DropdownToggle nav>特集</DropdownToggle>
        <DropdownMenu className="featured-nav-menu" right>
          <Row>
            <Col className="featured-nav-menu-title" xs="3">
              {!!features?.length && (
                <DropdownItem
                  onMouseEnter={() => setSubMenu(MENU_FEATURES)}
                  className={cx({ active: subMenu === MENU_FEATURES })}
                >
                  ピックアップ
                </DropdownItem>
              )}
              {!!tagList?.length && (
                <DropdownItem
                  onMouseEnter={() => setSubMenu(MENU_TAG_LIST)}
                  className={cx({ active: subMenu === MENU_TAG_LIST })}
                >
                  ブランドタグ
                </DropdownItem>
              )}
            </Col>
            {subMenu === MENU_FEATURES && (
              <Col xs="9">
                {features!.map((item, index) => (
                  <DropdownItem
                    key={`brand-${index}`}
                    tag={Link}
                    to={`/features/${encodeURIComponent(item.id)}`}
                  >
                    {item.title}
                  </DropdownItem>
                ))}
              </Col>
            )}
            {subMenu === MENU_TAG_LIST && (
              <Col>
                {tagList!.map((item, index) => (
                  <DropdownItem
                    key={`search-brand-${index}`}
                    tag={Link}
                    to={`/list/search?s=&brand_tags=${encodeURIComponent(
                      item.tag_name
                    )}`}
                  >
                    {item.tag_name}
                  </DropdownItem>
                ))}
              </Col>
            )}
          </Row>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

export default FeaturedNavMenu;
