import type { Product as ProductModel } from '~domain/product/Product';
import type { Product as ProductType } from '~redux/brand/types';
import type { Product, ProductReadModel } from '~types/api';

const NON_RETURNABLE_SUBCATEGORIES = ['食品'];

export const isReturnableProductCategory = (
  product?: ProductModel | ProductType | Product | ProductReadModel | null
) => {
  if (!product) {
    return false;
  }

  return !NON_RETURNABLE_SUBCATEGORIES.includes(
    product.product_subcategory ?? ''
  );
};
