import { combineReducers } from 'redux';
import referrals from './slice/referrals';
import nextToken from './slice/nextToken';

export const referralReducer = combineReducers({
  referrals,
  nextToken,
});

export default referralReducer;
