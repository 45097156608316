import { useCallback, useState } from 'react';
import { executeQuery } from '~utils/graphql';
import { warn } from '~utils/log';

export const useGraphql = <DataType>(
  query: string,
  selector?: (data: any) => DataType,
  isPublic?: boolean
): [
  DataType | undefined,
  (options?: any) => Promise<any>,
  boolean,
  Error | undefined
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<DataType>();

  const request = useCallback(
    async (options?: any) => {
      try {
        setLoading(true);
        setData(undefined);
        setError(undefined);
        const res = await executeQuery(query, options, isPublic);
        if (res && res.data) {
          const d = selector ? selector(res.data) : res.data;
          setData(d);
          return d;
        }
      } catch (e) {
        console.error(e);
        setError(e);
        warn(e);
      } finally {
        setLoading(false);
      }
    },
    [query, selector, isPublic]
  );

  return [data, request, loading, error];
};
