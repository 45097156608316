import { combineReducers } from 'redux';
import loadingReducer from './loading';
import messagesReducer from './messages';
import newMessagesReducer from './newMessages';
import nextTokenReducer from './nextToken';
import threadsReducer from './threads';

const messageReducer = combineReducers({
  messages: messagesReducer,
  threads: threadsReducer,
  loading: loadingReducer,
  newMessages: newMessagesReducer,
  nextToken: nextTokenReducer,
});

export default messageReducer;
