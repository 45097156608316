import { createReducer } from '@reduxjs/toolkit';
import { receiveOrdersCsv } from '../actions/admin';
import { Order } from '~domain/order/Order';

const initialState: {
  list: Order[];
  nextToken: string | null;
} = {
  list: [],
  nextToken: null,
};

const adminOrdersCsvReducer = createReducer(initialState, {
  [receiveOrdersCsv.type]: (_, { payload }) => payload,
});

export default adminOrdersCsvReducer;
