import { createSelector } from 'reselect';
import { RootState } from '~redux/reducer';

const cmsSelector = (state: RootState) => state.cms;

export const featuredContentSelector = createSelector(
  cmsSelector,
  (cms) => cms.featuredContent
);

export const featuredContentsSelector = createSelector(
  cmsSelector,
  (cms) => cms.featuredContents
);
