import { createReducer } from '@reduxjs/toolkit';
import { initializeState, payFailed, payStart, paySuccess } from '../actions';

export enum PaymentState {
  init = 0,
  complete = 1,
  loading = 2,
  failed = 3,
}

const initialState = PaymentState.init;

const stateReducer = createReducer(initialState, {
  [payStart.type]: () => {
    return PaymentState.loading;
  },
  [paySuccess.type]: () => {
    return PaymentState.complete;
  },
  [payFailed.type]: () => {
    return PaymentState.failed;
  },
  [initializeState.type]: () => {
    return initialState;
  },
});

export default stateReducer;
