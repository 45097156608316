import './styles.scss';
import { FiHeart } from '@react-icons/all-files/fi/FiHeart';
import { FiHelpCircle } from '@react-icons/all-files/fi/FiHelpCircle';
import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import logo from '../../../../assets/images/futura_homula_logo_white.svg';
import SearchForm from '../../../../widgets/SearchForm';
import { BrandOrderedButton } from '../BrandOrderedButton';
import GiftIcon from '../GiftIcon';
import CategoryList from './CategoryList';
import CartIcon from './CategoryList/CartIcon';
import BuyerNavDropdown from './NavDropdown';
import SearchFormDialog from '~view/widgets/SearchFormDialog';

type BuyerMenuProps = {};

const BuyerMenu: React.FC<BuyerMenuProps> = () => {
  return (
    <div>
      <div className="d-flex align-items-center h-16 px-4">
        <div className="main-logo pl-2">
          <Link to="/home">
            <img
              style={{ maxWidth: '180px', maxHeight: '54px' }}
              src={logo}
              alt="logo"
            />
          </Link>
        </div>
        <div className="mr-3 flex-grow-1 d-none d-md-block">
          <SearchForm />
        </div>
        <div className="d-none d-lg-block mr-3">
          <GiftIcon />
        </div>
        <div className="d-none d-lg-block mr-3">
          <BrandOrderedButton />
        </div>
        <div className="ml-auto mr-3 flex-shrink-0 d-block d-md-none">
          <SearchFormDialog />
        </div>
        <div className="mr-3 flex-shrink-0">
          <CartIcon />
        </div>
        <div className="m-0 mr-3">
          <Link className="favorite--brands-link" to="/favorite/products">
            <FiHeart id="favorite-icon" size={20} />
            <UncontrolledTooltip target="favorite-icon" placement="bottom">
              お気に入り
            </UncontrolledTooltip>
          </Link>
        </div>
        <div className="d-none d-sm-block m-0 mr-4">
          <a
            className="blog-link"
            href="https://support.homula.jp/help/%E3%83%90%E3%82%A4%E3%83%A4%E3%83%BCfaq"
            target="_blank"
          >
            <FiHelpCircle size={20} id="help-icon" />
            <UncontrolledTooltip target="help-icon" placement="bottom">
              ヘルプ
            </UncontrolledTooltip>
          </a>
        </div>
        <BuyerNavDropdown />
      </div>
      <CategoryList />
    </div>
  );
};

export default BuyerMenu;
