import { createReducer } from '@reduxjs/toolkit';
import { BlogPost, setBlogPosts, clearBlogPosts } from '../actions';

type BlogPostsState = BlogPost[];

const initialState: BlogPostsState = [];

const blogPostsReducer = createReducer<BlogPostsState>(initialState, {
  [setBlogPosts.type]: (_, action) => action.payload,
  [clearBlogPosts.type]: () => initialState,
});

export default blogPostsReducer;
