import { Auth } from 'aws-amplify';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../../graphql/queries';
import { setAccount } from '~redux/account/actions/account';
import { receiveAuthUser } from '~redux/auth/actions/authUser';
import { setAuthenticating } from '~redux/auth/reducers/authenticating';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { debug } from '~utils/log';
import { useGraphql } from '~view/hooks/graphql';

const Authenticator: React.FC = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);

  const [, fetch] = useGraphql(getAccount, (data) => data.getAccount);

  const setUser = useCallback(async () => {
    if (authUser) {
      return;
    }
    try {
      dispatch(setAuthenticating(true));
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const account = await fetch({ id: user.attributes.sub });
      if (account) {
        dispatch(
          setAccount({
            ...account,
            buyerInfo: account.buyerInfo?.items?.[0],
            supplierInfo: account.supplierInfo?.items?.[0],
          })
        );
      }
      dispatch(receiveAuthUser(user));
    } catch (e) {
      debug(e);
    } finally {
      dispatch(setAuthenticating(false));
    }
  }, [authUser]);

  useEffect(() => {
    setUser();
  }, []);

  return null;
};

export default Authenticator;
