/**
 *  HomeFaqBuyers
 */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container } from 'reactstrap';
// import SignUpModal from '../SignUpModal';
import { signup } from '~redux/auth/reducers/signup';
import './styles.scss';

const HomeFaqBuyers = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const handleSignup = useCallback(() => {
    dispatch(signup());
  }, []);

  return (
    <div className="faq">
      <Container>
        <h3 className="txt-c txt-en-m ls-s">FAQ</h3>

        <div className="text-left">
          <h5 className="font-weight-bold">取引開始までの流れ</h5>
          <div className="d-flex mb-5">
            <div className="border-left mr-4">&nbsp;</div>
            <div>
              会員登録を行って頂き弊社審査部が審査を行った後にすぐに取引を開始できます。
              <br />
              60日支払いサイト・返品機能は審査の上、ご提供しております。
            </div>
          </div>

          <h5 className="font-weight-bold">手数料について</h5>
          <div className="d-flex mb-5">
            <div className="border-left mr-4">&nbsp;</div>
            <div>小売店の方は手数料無料でご利用いただけます。</div>
          </div>

          <h5 className="font-weight-bold">商品について</h5>
          <div className="d-flex mb-5">
            <div className="border-left mr-4">&nbsp;</div>
            <div>
              homulaで仕入れたいブランドが出店していない場合、ブランドのリクエストや紹介プログラムにてブランドの出店をリクエストすることが可能です。
            </div>
          </div>

          {/* <h5 className="font-weight-bold">返品について</h5>
          <div className="d-flex mb-5">
            <div className="border-left mr-4">&nbsp;</div>
            <div>
              小売店が様々な新しい商品ラインナップの販売に挑戦して成長していけるよう、homulaは返品サービスを提供しています。
              <br />
              ブランドとの初回取引であれば所定の期限内に返品が可能です。
            </div>
          </div> */}
        </div>

        <div className="txt-c pt-ll mb-ll">
          <Button onClick={handleSignup}>無料バイヤー登録</Button>
        </div>
      </Container>
    </div>
  );
};

export default HomeFaqBuyers;
