import { FieldHookConfig, useField } from 'formik';
import React, { useEffect, useRef } from 'react';

const FieldWithValidity: React.FC<
  FieldHookConfig<string> & { label?: string; readonly?: boolean }
> = ({ readonly, as, component, label, ...props }) => {
  const [field, meta] = useField(props);
  const ref = useRef<HTMLInputElement>(null);
  const Component = component ?? as ?? 'input';

  useEffect(() => {
    ref.current?.setCustomValidity(meta.error ?? '');
  }, [ref.current, meta.error]);

  return (
    <>
      {label && (
        <label className="font-weight-bold" htmlFor={props.id}>
          {label}
        </label>
      )}
      <Component
        {...field}
        {...props}
        className={
          (props.className ?? '') + (meta.error ? ' border-danger' : '')
        }
        onKeyDown={(e) => {
          if (readonly) e.preventDefault();
          //@ts-ignore
          if (props.onKeyDown) props.onKeyDown(e);
        }}
        ref={ref}
      />
    </>
  );
};

export default FieldWithValidity;
