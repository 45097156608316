import { createSelector } from 'reselect';
import { RootState } from '../../reducer';

const brandSelector = (state: RootState) => state.brand;

export const brandsSelector = createSelector(
  brandSelector,
  (brand) => brand.items
);

export const nextTokenSelector = createSelector(
  brandSelector,
  (brand) => brand.nextToken
);

const brandDetailsSelector = createSelector(
  brandSelector,
  (brand) => brand.details
);

export const brandInfoSelector = createSelector(
  brandDetailsSelector,
  (details) => details.info ?? undefined
);

export const brandProductsSelector = createSelector(
  brandDetailsSelector,
  (details) => {
    return details.products;
  }
);

export const makeBrandProductSelector = (productId: string) =>
  createSelector(brandDetailsSelector, (details) => {
    return details.products.find((product) => product.id === productId);
  });

export const brandProductsNextTokenSelector = createSelector(
  brandDetailsSelector,
  (details) => {
    return details.nextToken;
  }
);

export const brandProductsLastItemsSelector = createSelector(
  brandDetailsSelector,
  (details) => {
    return details.lastItems;
  }
);

export const brandProductsLastItemsIndexSelector = createSelector(
  brandDetailsSelector,
  (details) => {
    return details.lastItemsIndex;
  }
);

export const brandShippinngFeeSelector = createSelector(
  brandDetailsSelector,
  (details) => {
    return details.info?.brand_shippinng_fee ?? 0;
  }
);

export const searchBrandsSelector = createSelector(
  brandSelector,
  (brand) => brand.search.items
);

export const searchBrandsNextTokenSelector = createSelector(
  brandSelector,
  (brand) => brand.search.nextToken
);

export const brandNameSelector = createSelector(
  brandSelector,
  (brand) => brand.brandName
);

export const allBrandsSelector = createSelector(brandSelector, (state) => {
  return state.adminAllBrands;
});
