import { createReducer } from '@reduxjs/toolkit';
import { receiveProduct, clearProduct } from '../../actions/admin';
import { Product } from '~domain/product/Product';

type RequestState = Product | null;

const initialState: RequestState = null;

const adminProductReducer = createReducer<RequestState>(initialState, {
  [receiveProduct.type]: (_, action) => action.payload,
  [clearProduct.type]: () => initialState,
});

export default adminProductReducer;
