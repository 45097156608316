import * as queries from '../graphql/queries';
import { executeQuery } from './graphql';

// 与信枠の計算
// 与信枠最大値（buyerInfoテーブルのbuyer_credit）ー 未払いオーダー金額（chrage_statusが０、２のオーダーの金額合計）
// payment_termが3 （プレオーダー）のオーダーは order_statusが4（発送済み）の場合のみ計算に含む
export const getCreditFacility = async (
  buyerId: string,
  ignoreOrderId?: string
) => {
  const {
    data: { getCreditFacility: creditFacility },
  } = await executeQuery(queries.getCreditFacility, { buyerId, ignoreOrderId });
  return Math.max(creditFacility, 0);
};
