import { createSelector } from 'reselect';
import { RootState } from '../../reducer';
import { Product } from '~domain/product/Product';
import { IndividualProduct } from '~domain/product/IndividualProduct';

const productSelector = (state: RootState) => state.product;

export const productItemSelector = createSelector(
  productSelector,
  (product) => product.item
);

export const productModelSelector = createSelector(
  productItemSelector,
  (product) => (product ? Product.create(product) : undefined)
);

export const individualProductModelSelector = createSelector(
  productItemSelector,
  (product) => (product ? IndividualProduct.create(product) : undefined)
);
