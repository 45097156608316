import { createSelector } from 'reselect';
import { RootState } from '../../reducer';

export const signUpSelector = (state: RootState) => state.auth.signup;
export const isSignUpSelector = createSelector(
  signUpSelector,
  (signUp) => signUp.isSignup
);
export const signUpPathSelector = createSelector(
  signUpSelector,
  (signUp) => signUp.nextPath
);
export const referralIdSelector = createSelector(
  signUpSelector,
  (signUp) => signUp.referralId
);
export const contactIdSelector = createSelector(
  signUpSelector,
  (signUp) => signUp.contactId
);
export const brandSelector = createSelector(
  signUpSelector,
  (signUp) => signUp.brand
);
