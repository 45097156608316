import { createReducer } from '@reduxjs/toolkit';
import {
  BuyerInfo,
  clearBuyerInfo,
  receiveBuyerInfo,
  updatedBuyerInfo,
} from './../actions/buyerInfo';

type BuyerInfoState = BuyerInfo | null;

const initialState: BuyerInfoState = null;

const infoReducer = createReducer<BuyerInfoState>(initialState, {
  [receiveBuyerInfo.type]: (_, action) => {
    if (!action.payload) {
      return null;
    }
    const { owner, ...payload } = action.payload;
    return payload;
  },
  [clearBuyerInfo.type]: () => {
    return null;
  },
  [updatedBuyerInfo.type]: (state, { payload: item }) => {
    return { ...state, ...item };
  },
});

export default infoReducer;
