import { createReducer } from '@reduxjs/toolkit';
import { clearAllBrands, setBrands } from '../../actions/supplier';
import { Brand } from '~domain/brand/Brand';

type State = Brand[];

const initialState: State = [];

const supplieritemsReducer = createReducer<State>(initialState, {
  [setBrands.type]: (_, action) => action.payload,
  [clearAllBrands.type]: () => initialState,
});

export default supplieritemsReducer;
