import { createReducer } from '@reduxjs/toolkit';
import { setBrandList } from '../actions';
import { Brand } from '~redux/brand/types';

type BrandListState = Brand[];
const initialState: BrandListState = [];

const brandListReducer = createReducer(initialState, {
  [setBrandList.type]: (_, { payload: { items } }) => items,
});

export default brandListReducer;
