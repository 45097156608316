import React from 'react';
import { Container } from 'reactstrap';
import './styles.scss';

const BuyerCases: React.FC = () => {
  return (
    <section className="supplier-cases">
      <Container>
        <h3 className="txt-c txt-en-s ls-s">Voice of Shops</h3>

        <a
          href="https://blog.homula.jp/homula%E3%81%AE%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%82%92%E5%8F%96%E3%82%8A%E5%85%A5%E3%82%8C%E3%81%9Fshop-report-%E5%B1%B1%E5%BD%A2-gea-"
          target="_brank"
        >
          <div className="d-lg-flex">
            <div className="w-100">
              <img src="/images/buyer/home/gea.webp" alt="" loading="lazy" />
            </div>

            <div className="w-100 p-4 bg-white text-left d-flex flex-column justify-content-center">
              <h5 className="font-weight-bold">
                新商品を小ロットで仕入れられ、お店の新鮮さを保つことができました。
              </h5>
              <p>
                すぐに新商品を小ロットで仕入れられるhomulaのサービスのおかげで、お店の新鮮さを保つことができました。60日超の支払いサイトや、初回に取り組むブランドとの取引は返品が可能といった点も、初めての取り組みへの保険的な意味合いで安心できるので、小売店にとても優しい仕組みだと感じました。
              </p>
              <p className="font-weight-bold">-山形GEA様</p>
            </div>
          </div>
        </a>
      </Container>
    </section>
  );
};

export default BuyerCases;
