import React from 'react';
import AdminPage from '~view/widgets/AdminPage';

const withAdminPage = (Component: React.ComponentType<any>, title?: string) => {
  return (props: any) => {
    return (
      <AdminPage title={title}>
        <Component {...props} />
      </AdminPage>
    );
  };
};

export default withAdminPage;
