import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Dispatch } from 'redux';
import {
  getPublishedProduct,
  listShopCartsByOwnerWithImageKey,
} from '../../../graphql/custom_queries';
import { deleteShopCart } from '../../../graphql/mutations';
import {
  addCartStart,
  addCartItem,
  setCartItems,
  removeCartStart,
  removeCartItem,
  setCartProducts,
  setProductCart,
  updateCartItemsStart,
  updatedCartItems,
} from '../actions';

import { Cart } from '~domain/order/Cart';
import { IndividualCart } from '~domain/order/IndividualCart';
import { CartJson } from '~domain/order/base/CartBase';
import { AuthUser } from '~redux/auth/actions/authUser';
import { selectProfile } from '~redux/auth/selectors/authUser';
import { ProductType } from '~redux/brand/types';
import { BuyerInfo } from '~redux/buyer/actions/buyerInfo';
import { CartItem, CartItem as CartItemType } from '~redux/cart/types';

import { RootState } from '~redux/reducer';
import { request } from '~redux/request/thunk';
import { AppThunkDispatch } from '~types/redux';
import { pushAddToCartEvent, pushRemoveFromCartEvent } from '~utils/gtm';
import { getPublicImageUrl } from '~utils/image';
import { calculateProductPrice, getProductTypeString } from '~utils/product';
import { productAdded, productRemoved } from '~utils/segment';

export const fetchCart = (buyerInfo: BuyerInfo) => {
  return async (dispatch: AppThunkDispatch, getState: () => RootState) => {
    const profile = selectProfile(getState());
    if (profile === 'individual') {
      return await IndividualCart.fetch(buyerInfo);
    }
    return await Cart.fetch(buyerInfo);
  };
};

export const createCart = (
  { items, ...cart }: CartJson,
  buyerInfo: BuyerInfo
) => {
  return async (dispatch: AppThunkDispatch, getState: () => RootState) => {
    const profile = selectProfile(getState());
    if (profile === 'individual') {
      return IndividualCart.create(
        {
          ...cart,
          items,
        },
        buyerInfo
      );
    }
    return Cart.create(
      {
        ...cart,
        items,
      },
      buyerInfo
    );
  };
};

export const addCart = (
  buyerInfo: BuyerInfo,
  item: Omit<CartItemType, 'id'>
) => {
  return async (dispatch: AppThunkDispatch) => {
    dispatch(addCartStart());

    const cart = await dispatch(fetchCart(buyerInfo));
    const newItem = await cart.addItem(item);

    dispatch(
      addCartItem({
        ...newItem.toJson(),
        quantity: item.quantity,
      })
    );

    //tracking
    productAdded(`cart/${buyerInfo.account_id}`, {
      brand: newItem.product.brand.brand_name,
      brand_id: newItem.product.product_brand_id,
      category: newItem.product.product_category,
      category2: newItem.product.product_subcategory,
      image_url: newItem.product.imageUrl,
      name: newItem.product.product_name,
      price: newItem.product.unitPrice,
      product_id: newItem.product_id,
      quantity: item.quantity,
      sku: newItem.product.product_number,
      url: `${document.location.protocol}//${document.location.host}/productdetail/${newItem.product_id}`,
      variant: newItem.productType
        ? getProductTypeString(newItem.productType)
        : undefined,
    });

    return newItem;
  };
};

export const updateCart = (
  user: AuthUser,
  buyerInfo: BuyerInfo,
  cartItems: CartItem[]
) => {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    const cart = await dispatch(fetchCart(buyerInfo));
    const newItems = await cart.updateItems(cartItems);
    newItems.forEach((newItem) => {
      if (!newItem) {
        return;
      }

      const prevQuantity = cart.items.find(({ id }: any) => id === newItem.id)
        ?.quantity;

      //tracking
      const diff = newItem.quantity - (prevQuantity ?? 0);
      if (diff > 0) {
        productAdded(`cart/${buyerInfo.account_id}`, {
          brand: newItem.product.brand.brand_name,
          brand_id: newItem.product.product_brand_id,
          category: newItem.product.product_category,
          category2: newItem.product.product_subcategory,
          image_url: newItem.product.imageUrl,
          name: newItem.product.product_name,
          price: calculateProductPrice(newItem.product, 1),
          product_id: newItem.product_id,
          quantity: diff,
          sku: newItem.product.product_number,
          url: `${document.location.protocol}//${document.location.host}/productdetail/${newItem.product_id}`,
          variant: newItem.productType
            ? getProductTypeString(newItem.productType)
            : undefined,
        });
      } else {
        productRemoved(`cart/${buyerInfo.account_id}`, {
          brand: newItem.product.brand.brand_name,
          brand_id: newItem.product.product_brand_id,
          category: newItem.product.product_category,
          category2: newItem.product.product_subcategory,
          image_url: newItem.product.imageUrl,
          name: newItem.product.product_name,
          price: calculateProductPrice(newItem.product, 1),
          product_id: newItem.product_id,
          quantity: diff * -1,
          sku: newItem.product.product_number,
          url: `${document.location.protocol}//${document.location.host}/productdetail/${newItem.product_id}`,
          variant: newItem.productType
            ? getProductTypeString(newItem.productType)
            : undefined,
        });
      }

      if (!prevQuantity) {
        dispatch(addCartItem(newItem));
        return;
      }

      if (newItem.quantity === 0) {
        dispatch(removeCartItem({ id: newItem.id, quantity: prevQuantity }));
        return;
      }

      dispatch(updatedCartItems([newItem]));
    });

    //tracking(Google Analytics)
    const addedItems = newItems
      .filter((item) => {
        if (!item) {
          return;
        }
        const prevQuantity = cart.items.find(({ id }: any) => id === item.id)
          ?.quantity;
        const diff = item.quantity - (prevQuantity ?? 0);
        return diff > 0;
      })
      .map((item) => ({
        item_name: item!.product.product_name,
        item_id: item!.product.id,
        price: item!.product.unitPrice,
        item_brand: item!.product.brand.brand_name,
        item_category: item!.product.product_category,
        item_category2: item!.product.product_subcategory,
        item_variant: item!.productType
          ? getProductTypeString(item!.productType)
          : undefined,
        item_list_name: document.title,
        item_list_id: document.location.pathname,
        index: 1,
        quantity:
          item!.quantity -
          (cart.items.find(({ id }: any) => id === item!.id)?.quantity ?? 0),
      }));
    const removedItems = newItems
      .filter((item) => {
        if (!item) {
          return;
        }
        const prevQuantity = cart.items.find(({ id }: any) => id === item.id)
          ?.quantity;
        const diff = item.quantity - (prevQuantity ?? 0);
        return diff < 0;
      })
      .map((item) => ({
        item_name: item!.product.product_name,
        item_id: item!.product.id,
        price: item!.product.unitPrice,
        item_brand: item!.product.brand.brand_name,
        item_category: item!.product.product_category,
        item_category2: item!.product.product_subcategory,
        item_variant: item!.productType
          ? getProductTypeString(item!.productType)
          : undefined,
        item_list_name: document.title,
        item_list_id: document.location.pathname,
        index: 1,
        quantity:
          (item!.quantity -
            (cart.items.find(({ id }: any) => id === item!.id)?.quantity ??
              0)) *
          -1,
      }));
    if (addedItems?.length) {
      pushAddToCartEvent(addedItems, user);
    }
    if (removedItems?.length) {
      pushRemoveFromCartEvent(removedItems, user);
    }
  };
};

export const updateCartItems = (
  buyerInfo: BuyerInfo,
  cartItems: CartItem[],
  callback?: (cart: Cart) => void
) => {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    dispatch(
      request({
        startAction: updateCartItemsStart,
        do: async () => {
          const cart = await dispatch(fetchCart(buyerInfo));
          const newItems = await cart.updateItems(cartItems);
          const newCart = await dispatch(
            createCart(
              {
                ...cart.toJson(),
                items: newItems,
              },
              buyerInfo
            )
          );

          callback?.(newCart);

          return newItems;
        },
        completeAction: setCartItems,
      })
    );
  };
};

export const getCart = (buyerInfo: BuyerInfo) => {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    const cart = await dispatch(fetchCart(buyerInfo));

    dispatch(setCartItems(cart.toJson().items));
  };
};

export const getCartByProduct = (productId: string, owner: string) => {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    const res = await API.graphql<any>(
      graphqlOperation(listShopCartsByOwnerWithImageKey, {
        shopcart_owner: owner,
        filter: {
          product_id: { eq: productId },
        },
      })
    );

    let items: any = [];
    if (res?.data?.listShopCartsByOwner?.items) {
      items = await Promise.all(
        res.data.listShopCartsByOwner.items
          .filter(
            (item: any) =>
              !item.product.suspended && !item.producttype?.suspended
          )
          .map(async (item: any) => ({
            id: item.id,
            owner: item.shopcart_owner,
            quantity: item.shopcart_quantity,
            product_id: item.product_id,
            product_type_id: item.product_type_id,
            product: {
              ...item.product,
              producttype: item.product.producttype.items,
            },
            productType: item.producttype,
            image: await Storage.get(
              item.producttype?.imageKey ??
                item.product?.imageKeys?.items[0]?.imageKey
            ),
          }))
      );
    }

    dispatch(setCartProducts(items));
  };
};

export const removeCart = (
  id: string,
  quantity: number,
  checkout?: boolean
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(removeCartStart());

    const input = { id };
    const res = await API.graphql<any>(
      graphqlOperation(deleteShopCart, { input })
    );
    const {
      data: { deleteShopCart: item },
    } = res;

    dispatch(removeCartItem({ id, quantity }));

    if (checkout || !item) {
      return;
    }

    //tracking
    productRemoved(`cart/${item.shopcart_owner}`, {
      brand: item.product.brand.brand_name,
      brand_id: item.product.product_brand_id,
      category: item.product.product_category,
      category2: item.product.product_subcategory,
      image_url: item.product.imageUrl,
      name: item.product.product_name,
      price: calculateProductPrice(item.product, 1),
      product_id: item.product_id,
      quantity: item.shopcart_quantity,
      sku: item.product.product_number,
      url: `${document.location.protocol}//${document.location.host}/productdetail/${item.product_id}`,
      variant: item.productType
        ? getProductTypeString(item.productType)
        : undefined,
    });
  };
};

export const getProductCart = (id: string) => {
  return async (dispatch: Dispatch<any>) => {
    const productData = await API.graphql<any>(
      graphqlOperation(getPublishedProduct, {
        id,
        filter: {
          product_public_status: { eq: '公開' },
          suspended: { ne: true },
        },
      })
    );
    const {
      data: { getProduct: product },
    } = productData;

    const signedImages = await Promise.all(
      product.imageKeys.items.map(async (item: any) => {
        const signedUrl = getPublicImageUrl(item.imageKey);
        item.imageUrl = signedUrl;
        return item;
      })
    );

    const signedTypes = await Promise.all(
      product.producttype?.items
        ?.filter(
          (item: ProductType) => !item.suspended && (item.published ?? true)
        )
        ?.map(async (item: ProductType) => {
          if (item.imageKey) {
            const signedUrl = getPublicImageUrl(item.imageKey);
            item.imageUrl = signedUrl as string;
          }
          return item;
        })
    );

    dispatch(
      setProductCart({
        ...product,
        imageKeys: signedImages,
        producttype: signedTypes,
      })
    );
  };
};

export const addCartAll = (
  buyerInfo: BuyerInfo,
  items: Omit<CartItemType, 'id'>[],
  user: AuthUser
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const newItems = await Promise.all(
      items.map(async (item) => await addCart(buyerInfo, item)(dispatch))
    );
    pushAddToCartEvent(
      newItems.map((item, i) => ({
        item_name: item.product.product_name,
        item_id: item.product_id,
        price: calculateProductPrice(item.product, 1),
        item_brand: item.product.brand.brand_name,
        item_category: item.product.product_category,
        item_category2: item.product.product_subcategory,
        item_variant: item.productType
          ? getProductTypeString(item.productType)
          : undefined,
        item_list_name: document.title,
        item_list_id: document.location.pathname,
        index: i,
        quantity: item.quantity,
        is_direct: item.is_direct,
        brand_id: item.brand_id,
      })),
      user
    );
  };
};
