import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
// import { track } from './segment';

export const executeQuery = async <R = any, V extends {} = {}>(
  query: string,
  variables?: V,
  unAuth?: boolean
): Promise<GraphQLResult<R>> => {
  // try {
  //   const event = (
  //     query.split('query ')[1] ?? query.split('mutation ')[1]
  //   ).split('(')[0];
  //   track(event, variables);
  // } catch (e) {
  //   console.log(e);
  // }
  const res = (await API.graphql({
    query,
    variables,
    authMode: unAuth ? GRAPHQL_AUTH_MODE.AWS_IAM : undefined,
  })) as GraphQLResult<R>;
  if (res.errors?.length) {
    console.error(res.errors[0].message);
    throw new Error(res.errors[0].message);
  }
  return res;
};

export const removeItems = <T = any>(data: any): T => {
  if (typeof data !== 'object' || data === null) {
    return data;
  }

  if (Reflect.has(data, 'items') && Array.isArray(Reflect.get(data, 'items'))) {
    return data.items.map(removeItems);
  }

  const removed: T = Object.keys(data).reduce((prev, key) => {
    if (data[key] === undefined || data[key] === null) {
      return {
        ...prev,
        [key]: undefined,
      };
    }

    if (Array.isArray(data[key])) {
      return {
        ...prev,
        [key]: data[key].map(removeItems),
      };
    }

    if (typeof data[key] !== 'object') {
      return {
        ...prev,
        [key]: data[key],
      };
    }

    return {
      ...prev,
      [key]: removeItems(data[key]),
    };
  }, {} as T);

  return removed;
};
