import { useCallback, useEffect } from 'react';
import { deletePickupBrands, updatePickupBrands } from '~graphql/mutations';
import { listPickupBrandss } from '~graphql/queries';
import { PickupBrands } from '~types/api';
import { executeQuery } from '~utils/graphql';
import { useGraphql } from '~view/hooks/graphql';

export const usePickupList = () => {
  const [pickupList, fetch, isLoading] = useGraphql<PickupBrands[]>(
    listPickupBrandss,
    (data) => data.listPickupBrandss.items
  );

  const updateOrder = useCallback(async (pickupList: PickupBrands[]) => {
    await Promise.all(
      pickupList.map(
        async (pickupList, index) =>
          await executeQuery(updatePickupBrands, {
            input: { id: pickupList.id, display_num: index + 1 },
          })
      )
    );
    fetch();
  }, []);

  const updateVisible = useCallback(async (id: string, visible: boolean) => {
    await executeQuery(updatePickupBrands, {
      input: { id, visible },
    });
    fetch();
  }, []);

  const deleteList = useCallback(async (id: string) => {
    await executeQuery(deletePickupBrands, {
      input: { id },
    });
    fetch();
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  return {
    pickupList: pickupList?.sort(
      (a, b) => (a.display_num ?? 0) - (b.display_num ?? 0)
    ),
    updateOrder,
    updateVisible,
    deleteList,
    isLoading,
  };
};
