import { createReducer } from '@reduxjs/toolkit';
import {
  receiveOrderProducts,
  receiveOrderProductsForCSV,
} from '../actions/index';
import { OrderDetailReadModel } from '~types/api';

const initialState: {
  list: OrderDetailReadModel[];
  csvData: OrderDetailReadModel[] | null;
  nextToken: string | null;
} = {
  list: [],
  csvData: null,
  nextToken: null,
};

const orderProductsReducer = createReducer(initialState, {
  [receiveOrderProducts.type]: (state, { payload }) => ({
    ...state,
    list: [...state.list, ...payload.list],
    nextToken: payload.nextToken,
  }),
  [receiveOrderProductsForCSV.type]: (state, { payload }) => ({
    ...state,
    csvData: payload.csvData,
  }),
});

export default orderProductsReducer;
