import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { formatDate } from '~utils/formatter';
import { GtmEvents, pushGtmEvent } from '~utils/gtm';
import { page } from '~utils/segment';
import { useHeadContent } from '~view/contexts/headContext';

const AccessLog = () => {
  const user = useSelector(authUserSelector);
  const {
    headContent: { title },
  } = useHeadContent();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    if (!user?.attributes.email) {
      return;
    }
    pushGtmEvent({
      event: GtmEvents.UserPageView,
      payload: {
        page: title,
        user: user.attributes.email,
        user_type: user.attributes.profile,
        timestamp: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS', true),
        user_page_view: `${title}  ${user.attributes.email}`,
        page_access_log: `${formatDate(
          new Date(),
          'yyyy-MM-dd HH:mm:ss:SSS',
          true
        )}  ${title}  ${user?.attributes.email}`,
      },
    });
  }, [title, user?.attributes.email]);

  useEffect(() => {
    page(title!);
  }, [title]);

  return <></>;
};

export default AccessLog;
