import { combineReducers } from 'redux';
import collectionItemReducer from './item';
import collectionListReducer from './list';

const collectionReducer = combineReducers({
  collectionList: collectionListReducer,
  item: collectionItemReducer,
});

export default collectionReducer;
