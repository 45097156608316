import './styles.modules.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthContainer from '../AuthContainer';
import ErrorBoundary from '../ErrorBoundary';
import LoadingContainer from '../LoadingContainer';
import Header from './Header';
import { authUserSelector } from '~redux/auth/selectors/authUser';
import { authenticatingSelector } from '~redux/auth/selectors/authenticating';
import { buyerInfoSelector } from '~redux/buyer/selectors';
import { getBuyerInfo } from '~redux/buyer/thunk';
import { clearedCartItems } from '~redux/cart/actions';
import { getCart } from '~redux/cart/thunk';
import { clearedContacts, fetchContacts } from '~redux/contact';
import Head from '~view/pages/common/Head';

type IndividualPageLayoutProps = {};

const IndividualPageLayout: React.FC<IndividualPageLayoutProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);
  const authenticating = useSelector(authenticatingSelector);
  const buyerInfo = useSelector(buyerInfoSelector);

  useEffect(() => {
    if (!authUser || buyerInfo) {
      return;
    }
    dispatch(getBuyerInfo(authUser.attributes.sub));
  }, [authUser, buyerInfo]);

  useEffect(() => {
    if (!authUser || !buyerInfo) return;
    dispatch(getCart(buyerInfo));
    return () => {
      dispatch(clearedCartItems());
    };
  }, [buyerInfo, authUser]);

  useEffect(() => {
    if (!authUser || !buyerInfo) {
      return;
    }
    dispatch(fetchContacts({ buyerId: buyerInfo.id }));
    return () => {
      dispatch(clearedContacts());
    };
  }, [authUser, buyerInfo]);

  if (authenticating) {
    return <LoadingContainer isLoading />;
  }

  if (!authUser) {
    return <AuthContainer />;
  }

  if (authUser.attributes.profile !== 'individual') {
    return null;
  }

  return (
    <>
      <Head />
      <ErrorBoundary>
        <div id="container" styleName="contaienr">
          <Header />
          <main styleName="content-area">{children}</main>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default IndividualPageLayout;
