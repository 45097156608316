import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
//@ts-ignore
import HubspotForm from 'react-hubspot-form';
import { Redirect, useHistory } from 'react-router';
import Spinner from 'react-spinner-material';
import { Button, Container } from 'reactstrap';
import * as Yup from 'yup';
import logo from '../../../../assets/images/futura_homula_logo_white.svg';
import { createAccount, createSupplierInfo } from '~graphql/mutations';
import { executeQuery } from '~utils/graphql';
import { useQuery } from '~view/hooks/route';
import FieldWithValidity from '~view/widgets/FieldWithValidity';
import LoadingContainer from '~view/widgets/LoadingContainer';

const SupplierSignup: React.FC = () => {
  const history = useHistory();
  const [isInputPassword, setInputPassword] = useState(false);
  const [formData, setFormData] = useState<any>();
  const checkedRef = useRef(false);
  const email = useQuery().get('email');

  const onSubmit = useCallback(async (event) => {
    if (checkedRef.current) {
      checkedRef.current = false;
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const btn = event.currentTarget;
    const email = btn.form.elements['email'].value;
    if (email) {
      try {
        await Auth.confirmSignUp(email, 'x', {
          forceAliasCreation: false,
        });
      } catch (e) {
        if (e.code !== 'UserNotFoundException') {
          alert(
            '入力されたメールアドレスはすでに他のユーザーにより利用されています。お手数ですがメールアドレスを変更してください。'
          );
          return;
        }
      }
    }
    checkedRef.current = true;
    btn.click();
  }, []);

  const handleFormEvent = useCallback(
    async (event) => {
      if (
        event.data.type !== 'hsFormCallback' ||
        event.data.id !==
          process.env.REACT_APP_HUBSPOT_SUPPLIER_INFORMATION_FORM_ID
      ) {
        return;
      }
      switch (event.data.eventName) {
        case 'onFormReady': {
          const form = document.getElementById('hs-form-iframe-0');
          const formDoc = (form as HTMLIFrameElement)?.contentWindow?.document;
          formDoc
            ?.querySelector<HTMLButtonElement>('[type="submit"]')
            ?.addEventListener('click', (evt) => onSubmit(evt), false);
          break;
        }
        case 'onFormSubmit': {
          const data = event.data.data.email
            ? event.data.data
            : event.data.data.reduce(
                (prev: any, d: any) => ({ ...prev, [d.name]: d.value }),
                {}
              );
          setFormData(data);
          break;
        }
        case 'onFormSubmitted': {
          setInputPassword(true);
          break;
        }
        default:
          return;
      }
    },
    [onSubmit]
  );

  useEffect(() => {
    window.addEventListener('message', handleFormEvent);
    return () => {
      window.removeEventListener('message', handleFormEvent);
    };
  }, [handleFormEvent, checkedRef]);

  const [isLoading, setLoading] = useState(false);
  const signup = useCallback(
    async (form) => {
      setLoading(true);
      const user = await Auth.signUp({
        username: formData.email,
        password: form.password,
        attributes: {
          profile: 'supplier',
        },
      });
      await Auth.signIn({
        username: formData.email,
        password: form.password,
      });
      const {
        data: { createAccount: account },
      } = await executeQuery(createAccount, {
        input: {
          id: user.userSub,
          first_name: formData.firstname,
          last_name: formData.lastname,
          web_site_URL: formData.website,
          instagram_account: formData.instagram,
          tel: formData.phone,
          company_name: formData['0-2/name'],
          term_approval_status: 1,
          owner: [user.userSub],
        },
      });
      await executeQuery(createSupplierInfo, {
        input: {
          account_id: account.id,
          supplier_contract_type: 1,
          bank_name: formData['0-2/bank'],
          bank_branch_name: formData['0-2/bank_branch'],
          bank_account_number: formData['0-2/account_number'],
          bank_account_name: formData['0-2/account_name'],
          bank_account_type: formData['0-2/account_type'],
          owner: [account.id],
        },
      });
      history.push('/home');
    },
    [formData]
  );

  if (!email) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <header className="p-2 d-flex justify-content-center border-bottom">
        <img src={logo} alt="logo" className="pt-2" width="120px" />
      </header>
      <Container className="p-6 text-center">
        <h3>{!isLoading ? 'ご利用開始フォーム' : 'ログインしています'}</h3>
        {!isLoading && !isInputPassword && (
          <HubspotForm
            portalId="9130201"
            formId={process.env.REACT_APP_HUBSPOT_SUPPLIER_INFORMATION_FORM_ID}
            loading={<LoadingContainer isLoading />}
          />
        )}
        {!isLoading && isInputPassword && (
          <div className="d-flex flex-column align-items-center">
            <Formik
              initialValues={{ password: '' }}
              onSubmit={signup}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('パスワードは必須です。')
                  .matches(/^[^\s]+$/, 'スペースはパスワードに含められません。')
                  .matches(
                    // eslint-disable-next-line no-control-regex
                    /^[\u0000-\u007F]*$/,
                    'ASCII制御文字以外はパスワードに含められません。'
                  )
                  .matches(
                    /^[\p{ASCII}]*$/u,
                    'Non-ASCII charactersはパスワードに含められません。'
                  )
                  .matches(
                    /^[\p{L}\p{N}\p{P}\p{S}]*$/u,
                    '利用できない文字が含まれています。'
                  )
                  .min(8, 'パスワードは8文字以上で入力してください。')
                  .max(20, 'パスワードは20文字以下で入力してください。'),
              })}
            >
              <Form
                className="d-flex flex-column align-items-center"
                style={{ maxWidth: '400px' }}
              >
                <p>ログインパスワードを設定してください。（8文字以上）</p>
                <FieldWithValidity
                  type="password"
                  name="password"
                  className="mb-4"
                  placeholder="パスワード"
                />
                <Button type="submit" block>
                  送信
                </Button>
              </Form>
            </Formik>
          </div>
        )}
        {isLoading && (
          <div className="d-flex flex-column align-items-center">
            <Spinner radius={50} color={'#333'} stroke={2} visible={true} />
          </div>
        )}
      </Container>
    </>
  );
};

export default SupplierSignup;
