import { useEffect } from 'react';
import { useHeadContext } from '~view/contexts/headContext';

export const useTitle = (title?: string) => {
  const { setTitle } = useHeadContext();
  useEffect(() => {
    if (!title) return;
    if (process.env.NODE_ENV === 'development') {
      title = '[DEV] ' + title;
    }
    setTitle(title);
  }, [title]);
};
