import { createReducer } from '@reduxjs/toolkit';
import { clearOrderAccount, receiveOrderAccount } from '../actions';
import { Account } from '~redux/account/actions/account';

type State = Account | null;

const initialState: State = null;

const orderAccountReducer = createReducer<State>(initialState, {
  [receiveOrderAccount.type]: (_, { payload }) => payload,
  [clearOrderAccount.type]: () => initialState,
});

export default orderAccountReducer;
