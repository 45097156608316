import { createSlice } from '@reduxjs/toolkit';
import { TFeaturedContent } from '~domain/cms/FeaturedContent';

const featuredContentsSlice = createSlice({
  initialState: [] as TFeaturedContent[],
  name: 'featuredContents',
  reducers: {
    setFeaturedContents: (state, { payload }) => payload,
    clearFeaturedContents: () => [],
  },
});

export const {
  setFeaturedContents,
  clearFeaturedContents,
} = featuredContentsSlice.actions;

export default featuredContentsSlice.reducer;
