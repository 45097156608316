import { Middleware, Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from './reducer';

export type QueueCallback<
  R,
  S = RootState,
  E = undefined,
  A extends Action = AnyAction
> = (
  next: () => void,
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument?: E
) => R;

export type QueueMiddleware<
  S = RootState,
  A extends Action = AnyAction,
  E = undefined
> = Middleware<ThunkDispatch<S, E, A>, S, ThunkDispatch<S, E, A>>;

const createQueueMiddleware = <E>(
  extraArgument?: E
): QueueMiddleware<RootState, AnyAction, E> => {
  return ({ dispatch, getState }) => {
    const queues: Record<
      string,
      QueueCallback<any, RootState, E, Action>[]
    > = {};
    const dequeue = (key: string) => {
      const callback = queues[key][0];
      callback(
        () => {
          queues[key].shift();
          if (queues[key].length > 0) {
            dequeue(key);
          }
        },
        dispatch,
        getState,
        extraArgument
      );
    };
    return (next) => (action) => {
      const { queue: key, callback } = action || {};
      if (key && typeof callback === 'function') {
        queues[key] = queues[key] || [];
        queues[key].push(callback);
        if (queues[key].length === 1) {
          dequeue(key);
        }
      } else {
        return next(action);
      }
    };
  };
};

const queue: QueueMiddleware & {
  withExtraArgument?<E>(
    extraArgument: E
  ): QueueMiddleware<RootState, AnyAction, E>;
} = createQueueMiddleware();

queue.withExtraArgument = createQueueMiddleware;

export default queue;
