import React from 'react';
import { Route, RouteProps } from 'react-router';
import withAuthPage from '~view/hoc/withAuthPage';
import AuthPage from '../AuthPage';

type PrivateRouteProps = {
  title?: string;
  fullscreen?: boolean;
} & RouteProps;

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  render,
  title,
  fullscreen,
  ...props
}) => {
  return (
    <Route
      {...props}
      component={component && withAuthPage(component, title, fullscreen)}
      render={
        render &&
        ((props) => (
          <AuthPage title={title} fullscreen={fullscreen}>
            {render(props)}
          </AuthPage>
        ))
      }
    />
  );
};

export default PrivateRoute;
