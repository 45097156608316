import { createSelector } from 'reselect';
import { RootState } from '../../reducer';
import { OrderStatusType } from '~redux/buyer/types';

export const orderSelector = (state: RootState) => state.order;

export const ordersSelector = createSelector(
  orderSelector,
  (order) => order.orders
);

export const makeOrdersSelectorByIds = (orderIds: string[]) =>
  createSelector(ordersSelector, (orders) =>
    orders.filter((order) => orderIds.includes(order.id))
  );

export const makeOrderProductsSelectorByIds = (
  orderId: string,
  orderProductIds: string[]
) =>
  createSelector(makeOrdersSelectorByIds([orderId]), ([order]) =>
    order?.orderproducts.filter((op) => orderProductIds.includes(op.id))
  );

export const makeOrdersSelectorByStatus = (status: OrderStatusType[]) =>
  createSelector(ordersSelector, (orders) =>
    orders.filter(
      (order) => status.includes(9) || status.includes(order.status)
    )
  );

export const selectLatestOrders = createSelector(
  orderSelector,
  (order) => order.latestOrder
);

export const isOrderCancelableSelector = createSelector(
  orderSelector,
  (order) => order.isOrderCancelable
);

export const makeIsOrderCancelableSelectorById = (orderId: string) =>
  createSelector(
    isOrderCancelableSelector,
    (isOrderCancelable) => isOrderCancelable[orderId]
  );

export const newOrdersSelector = createSelector(
  orderSelector,
  (order) => order.newOrders
);

export const orderAccountSelector = createSelector(
  orderSelector,
  (state) => state.orderAccount
);

export const nextOrdersTokenSelector = createSelector(
  orderSelector,
  (state) => state.nextOrdersToken
);

export const nextOrderProductsTokenSelector = createSelector(
  orderSelector,
  (state) => state.orderProducts.nextToken
);

export const orderProductsSelector = createSelector(
  orderSelector,
  (state) => state.orderProducts.list
);

export const orderProductsForCSVSelector = createSelector(
  orderSelector,
  (state) => state.orderProducts.csvData
);
