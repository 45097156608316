/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { debug } from './log';
import { AuthUser } from '~redux/auth/actions/authUser';

/* eslint-disable import/no-unresolved */
declare global {
  interface Window {
    analytics: any;
  }
}

if (window.analytics) {
  window.analytics._writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
  window.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);
}

export const anonymousId = (): string => {
  return window.analytics.user().anonymousId();
};

export const page = (title: string) => {
  window.analytics.page(title);
};

export const identify = (
  sub: string,
  email: string,
  firstName?: string,
  lastName?: string,
  profile?: AuthUser['attributes']['profile'],
  companyName?: string,
  shopName?: string,
  flags?: any
) => {
  window.analytics.identify(sub, {
    email,
    name: lastName != null ? `${lastName} ${firstName}` : undefined,
    firstName,
    lastName,
    profile,
    companyName,
    shopName,
    flags,
  });
};

export const group = (name: string) => {
  window.analytics.group(name, {
    name,
  });
};

export const setIndexName = (val: string): void => {
  localStorage.setItem('indexName', val);
};

export const setQueryId = (val: string | undefined): void => {
  if (val) {
    localStorage.setItem('queryId', val);
  } else {
    localStorage.removeItem('queryId');
  }
};

type AlgoliaIndex = {
  indexName: string;
  queryId?: string;
};

const getAlgoliaIndex = (): AlgoliaIndex => {
  return {
    indexName:
      localStorage.getItem('indexName') ||
      process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX_NAME!,
    queryId: localStorage.getItem('queryId') || undefined,
  };
};

const clearAlgoliaIndex = (): void => {
  localStorage.setItem(
    'indexName',
    process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX_NAME!
  );
  localStorage.removeItem('queryId');
};

export const track = (event: string, payload: any) => {
  try {
    debug(event);
    window.analytics.track(event, payload);
  } catch {}
};

export type SegmentProduct = {
  brand_id?: string;
  product_id?: string;
  sku?: string;
  category?: string;
  category2?: string;
  name?: string;
  brand?: string;
  variant?: string;
  price?: number;
  quantity?: number;
  coupon?: string;
  position?: number;
  url?: string;
  image_url?: string;
};

export type AlgoliaAnalistProps = {
  index?: string;
  eventType?: 'view' | 'click' | 'conversion';
  queryID?: string;
  objectIDs?: string[];
  objectID?: string;
  positions?: number[];
};

const splitArray = <T>(array: T[], splitLength: number) => {
  return array.reduce((prev: T[][], element, i) => {
    const index = parseInt(String(i / splitLength));
    prev[index] = [...(prev[index] ?? []), element];
    return prev;
  }, []);
};

export const productSearched = (query: string) => {
  track('Products Searched', {
    query,
  });
};

export const productListViewed = (
  list_id: string,
  category: string,
  products: SegmentProduct[]
) => {
  const algoliaIndex: AlgoliaIndex | undefined = getAlgoliaIndex();
  splitArray(products, 10).forEach((products) => {
    const algoliaAnalistProps: AlgoliaAnalistProps = {};
    if (algoliaIndex) {
      algoliaAnalistProps.index = algoliaIndex.indexName;
      algoliaAnalistProps.queryID = algoliaIndex.queryId;
      algoliaAnalistProps.eventType = 'view';
      algoliaAnalistProps.objectIDs = products.map(
        (product) => product.product_id!
      );
    }
    track('Product List Viewed', {
      list_id,
      category,
      products,
      ...algoliaAnalistProps,
    });
  });
};

export const productListFiltered = (
  list_id: string,
  category: string,
  products: SegmentProduct[],
  filters: ({ type: string; value: any } | undefined)[],
  sorts?: { type: string; value: string }[]
) => {
  const algoliaIndex: AlgoliaIndex | undefined = getAlgoliaIndex();

  splitArray(products, 10).forEach((products) => {
    const algoliaAnalistProps: AlgoliaAnalistProps = {};
    if (algoliaIndex) {
      algoliaAnalistProps.index = algoliaIndex.indexName;
      algoliaAnalistProps.queryID = algoliaIndex.queryId;
      algoliaAnalistProps.eventType = 'view';
      algoliaAnalistProps.objectIDs = products.map(
        (product) => product.product_id!
      );
    }
    track('Product List Filtered', {
      list_id,
      filters,
      sorts,
      category,
      products,
      ...algoliaAnalistProps,
    });
  });
};

export const productClicked = (product: SegmentProduct) => {
  const algoliaIndex: AlgoliaIndex | undefined = getAlgoliaIndex();
  const algoliaAnalistProps: AlgoliaAnalistProps = {};
  if (algoliaIndex) {
    algoliaAnalistProps.index = algoliaIndex.indexName;
    algoliaAnalistProps.queryID = algoliaIndex.queryId;
    algoliaAnalistProps.eventType = 'click';
    algoliaAnalistProps.objectID = product.product_id;
  }
  track('Product Clicked', { ...product, ...algoliaAnalistProps });
};

export const productViewed = (product: SegmentProduct) => {
  const algoliaIndex: AlgoliaIndex | undefined = getAlgoliaIndex();
  const algoliaAnalistProps: AlgoliaAnalistProps = {};
  if (algoliaIndex) {
    algoliaAnalistProps.index = algoliaIndex.indexName;
    algoliaAnalistProps.queryID = algoliaIndex.queryId;
    algoliaAnalistProps.eventType = 'view';
    algoliaAnalistProps.objectID = product.product_id;
  }
  track('Product Viewed', { ...product, ...algoliaAnalistProps });
};

export const productAdded = (cart_id: string, product: SegmentProduct) => {
  const algoliaIndex: AlgoliaIndex | undefined = getAlgoliaIndex();
  const algoliaAnalistProps: AlgoliaAnalistProps = {};
  if (algoliaIndex) {
    algoliaAnalistProps.index = algoliaIndex.indexName;
    algoliaAnalistProps.queryID = algoliaIndex.queryId;
    algoliaAnalistProps.eventType = 'conversion';
    algoliaAnalistProps.objectID = product.product_id;
  }
  track('Product Added', { cart_id, ...product, ...algoliaAnalistProps });
};

export const productRemoved = (cart_id: string, product: SegmentProduct) => {
  track('Product Removed', { cart_id, ...product });
};

export const cartViewed = (cart_id: string, products: SegmentProduct[]) => {
  track('Cart Viewed', { cart_id, products });
};

export const checkoutStarted = (params: {
  order_id?: string;
  affiliation?: string;
  value: number;
  revenue: number;
  shipping: number;
  tax: number;
  discount?: number;
  coupon?: string;
  currency: string;
  products: SegmentProduct[];
}) => {
  track('Checkout Started', params);
};

export const checkoutStepViewed = (params: {
  checkout_id?: string;
  step?: number;
  shipping_method?: string;
  payment_method?: string;
}) => {
  track('Checkout Step Viewed', params);
};

export const checkoutStepCompleted = (params: {
  checkout_id?: string;
  step?: number;
  shipping_method?: string;
  payment_method?: string;
}) => {
  track('Checkout Step Completed', params);
};

export const paymentInfoEntered = (params: {
  checkout_id?: string;
  order_id?: string;
  step?: number;
  shipping_method?: string;
  payment_method?: string;
}) => {
  track('Payment Info Entered', params);
};

export const orderUpdated = (params: {
  order_id?: string;
  affiliation?: string;
  total: number;
  revenue: number;
  shipping: number;
  tax: number;
  discount?: number;
  coupon?: string;
  currency: string;
  products: SegmentProduct[];
}) => {
  track('Order Updated', params);
};

export const orderCompleted = (params: {
  checkout_id?: string;
  order_id?: string;
  affiliation?: string;
  subtotal: number;
  total: number;
  revenue: number;
  shipping: number;
  tax: number;
  discount?: number;
  coupon?: string;
  currency: string;
  products: SegmentProduct[];
}) => {
  const algoliaIndex: AlgoliaIndex | undefined = getAlgoliaIndex();
  const algoliaAnalistProps: AlgoliaAnalistProps = {};
  if (algoliaIndex) {
    algoliaAnalistProps.index = algoliaIndex.indexName;
    algoliaAnalistProps.queryID = algoliaIndex.queryId;
    algoliaAnalistProps.eventType = 'conversion';
    algoliaAnalistProps.objectIDs = params.products.map(
      (product) => product.product_id!
    );
    clearAlgoliaIndex();
  }
  debug('Order Completed');
  debug(algoliaAnalistProps);

  track('Order Completed', { ...params, ...algoliaAnalistProps });
};

export const orderRefunded = (params: {
  checkout_id?: string;
  order_id?: string;
  affiliation?: string;
  subtotal: number;
  total: number;
  revenue?: number;
  shipping?: number;
  tax?: number;
  discount?: number;
  coupon?: string;
  currency: string;
  products: SegmentProduct[];
}) => {
  track('Order Refunded', params);
};

export const orderCancelled = (params: {
  order_id?: string;
  affiliation?: string;
  subtotal: number;
  total: number;
  revenue: number;
  shipping: number;
  tax: number;
  discount?: number;
  coupon?: string;
  currency: string;
  products: SegmentProduct[];
}) => {
  track('Order Cancelled', params);
};

export const brandViewed = (params: {
  brand_id: string;
  name: string;
  category: string;
}) => {
  track('Brand Viewed', params);
};

export const brandFollowed = (params: {
  brand_id: string;
  name: string;
  category: string;
}) => {
  track('Brand Followed', params);
};

export const productSaved = (params: SegmentProduct) => {
  track('Product Saved', params);
};
