import { AnyAction } from 'redux';
import {
  ADD_PRODUCTS,
  SET_PRODUCTS,
  CLEAR_PRODUCTS,
  CLEAR_ALL_PRODUCTS,
} from '../actions';

type Product = any; // TODO 型付け
type ProductItemsState = {
  category: string;
  items: Product[];
  lastItems: Product[];
  lastItemsIndex: number;
}[];

const initialState: ProductItemsState = [];

const itemsReducer = (
  state: ProductItemsState = initialState,
  action: AnyAction
): ProductItemsState => {
  const { payload } = action;
  switch (action.type) {
    case ADD_PRODUCTS: {
      if (!state.find(({ category }) => category === payload.category)) {
        return [
          ...state,
          {
            category: payload.category,
            items: payload.product,
            lastItems: payload.product,
            lastItemsIndex: 0,
          },
        ];
      }
      const newState = state.map((product) => {
        if (product.category === payload.category) {
          return {
            ...product,
            items: [...product.items, ...payload.product],
            lastItems: payload.product,
            lastItemsIndex: product.items.length,
          };
        }
        return product;
      });
      return newState;
    }
    case SET_PRODUCTS: {
      const filtered = state.filter(
        ({ category }) => category !== payload.category
      );
      return [
        ...filtered,
        {
          category: payload.category,
          items: payload.products,
          lastItems: payload.product,
          lastItemsIndex: 0,
        },
      ];
    }
    case CLEAR_PRODUCTS: {
      const filtered = state.filter(
        ({ category }) => category !== payload.category
      );
      return filtered;
    }
    case CLEAR_ALL_PRODUCTS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default itemsReducer;
