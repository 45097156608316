import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { createContact as createContactQuery } from '~graphql/mutations';
import { listContactsByBuyer } from '~graphql/queries';
import { RootState } from '~redux/reducer';
import {
  Contact,
  CreateContactMutation,
  CreateContactMutationVariables,
  ListContactsByBuyerQuery,
  ListContactsByBuyerQueryVariables,
} from '~types/api';
import { executeQuery } from '~utils/graphql';

// contact types
export interface ContactEntity extends Contact {}

// contact state
export interface ContactState extends EntityState<ContactEntity> {
  loadingStatus: 'not loaded' | 'loading' | 'loaded' | 'error';
  error?: string;
}

export const contactAdapter = createEntityAdapter<ContactEntity>();

// contact initial state
const initialState: ContactState = contactAdapter.getInitialState({
  loadingStatus: 'not loaded',
});

// contact thunks
export const fetchContacts = createAsyncThunk<
  ContactEntity[],
  { buyerId: string }
>('contact/fetchContacts', async ({ buyerId }) => {
  const res = await executeQuery<
    ListContactsByBuyerQuery,
    ListContactsByBuyerQueryVariables
  >(listContactsByBuyer, { buyer_id: buyerId, limit: 1000 });
  return (res.data?.listContactsByBuyer?.items ?? []) as ContactEntity[];
});

export const createContact = createAsyncThunk<
  ContactEntity,
  CreateContactMutationVariables['input']
>('contact/createContact', async (input) => {
  const res = await executeQuery<CreateContactMutation>(createContactQuery, {
    input,
  });
  return res.data?.createContact as ContactEntity;
});

// contact slice
export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    clearedContacts: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.pending, (state) => {
      state.loadingStatus = 'loading';
    });
    builder.addCase(fetchContacts.fulfilled, (state, action) => {
      state.loadingStatus = 'loaded';
      contactAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetchContacts.rejected, (state, action) => {
      state.loadingStatus = 'error';
      state.error = action.error.message;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      contactAdapter.addOne(state, action.payload);
    });
  },
});

// contact reducer
export const contactReducer = contactSlice.reducer;

// contact actions
export const { clearedContacts } = contactSlice.actions;

// contact selectors
export const {
  selectAll,
  selectById,
  selectEntities,
  selectIds,
} = contactAdapter.getSelectors();

export const selectContactState = (state: RootState) => state.contact;

export const selectContacts = createSelector(selectContactState, (state) =>
  selectAll(state)
);

export const selectDirectContacts = createSelector(selectContacts, (state) =>
  state.filter((contact) => contact.contact_status === 'direct')
);

export const selectDirectContactOwners = createSelector(
  selectDirectContacts,
  (state) => [...new Set(state.map((contact) => contact.owner!))]
);

// contact export
export default contactSlice.reducer;
