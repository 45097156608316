import { createReducer } from '@reduxjs/toolkit';
import {
  addCartStart,
  addCartItem,
  getCartStart,
  setCartItems,
  restockItemsStart,
  restockItemsEnd,
} from '../actions';

type CartLoadingState = boolean;
const initialState: CartLoadingState = false;

const loadingReducer = createReducer(initialState, {
  [getCartStart.type]: () => true,
  [addCartItem.type]: () => false,
  [addCartStart.type]: () => true,
  [setCartItems.type]: () => false,
  [restockItemsStart.type]: () => true,
  [restockItemsEnd.type]: () => false,
});

export default loadingReducer;
