// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-view-widgets-IndividualPageLayout-Header-CartIcon-___styles-modules__cart-link{display:flex;align-items:center}.src-view-widgets-IndividualPageLayout-Header-CartIcon-___styles-modules__cart-link .src-view-widgets-IndividualPageLayout-Header-CartIcon-___styles-modules__cart-mark{font-weight:500;font-size:24px;padding-bottom:3px}", ""]);
// Exports
exports.locals = {
	"cart-link": "src-view-widgets-IndividualPageLayout-Header-CartIcon-___styles-modules__cart-link",
	"cart-mark": "src-view-widgets-IndividualPageLayout-Header-CartIcon-___styles-modules__cart-mark"
};
module.exports = exports;
