import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  addCartItem,
  clearedCartItems,
  movedToCart,
  removeCartItem,
  removedCartItems,
  savedForLater,
  setCartItems,
  updatedCartItems,
} from '../actions';
import { CartItem } from '../types';

type CartItemsState = CartItem[];
const initialState: CartItemsState = [];

const itemsReducer = createReducer(initialState, {
  [setCartItems.type]: (_, { payload }) => payload,
  [addCartItem.type]: (state, { payload }) => {
    const existingItem = state.find((item) => item.id === payload.id);
    if (existingItem) {
      const newState = state.map((item) => {
        if (item.id === existingItem.id) {
          return {
            ...item,
            quantity: item.quantity + payload.quantity,
          };
        }
        return item;
      });
      return newState;
    }
    return [...state, payload];
  },
  [removeCartItem.type]: (state, { payload }) =>
    state.filter((item) => item.id !== payload.id),
  [savedForLater.type]: (
    state,
    { payload: { brandId } }: PayloadAction<{ brandId: string }>
  ) => {
    return state.map((item) => ({
      ...item,
      saved_for_later:
        item.product.product_brand_id === brandId ? true : item.saved_for_later,
    }));
  },
  [movedToCart.type]: (
    state,
    { payload: { brandId } }: PayloadAction<{ brandId: string }>
  ) => {
    return state.map((item) => ({
      ...item,
      saved_for_later:
        item.product.product_brand_id === brandId
          ? false
          : item.saved_for_later,
    }));
  },
  [clearedCartItems.type]: () => initialState,
  [removedCartItems.type]: (
    items,
    { payload: { brandIds } }: PayloadAction<{ brandIds: string[] }>
  ) =>
    items.filter((item) => !brandIds.includes(item.product.product_brand_id!)),
  [updatedCartItems.type]: (
    items,
    { payload: newItems }: PayloadAction<({ id: string } & Partial<CartItem>)[]>
  ) =>
    items.map((item) => ({
      ...item,
      ...(newItems.find((newItem) => newItem.id === item.id) ?? {}),
    })),
});

export default itemsReducer;
