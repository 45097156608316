import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuyerCoupon } from '~types/api';

type TCouponState = Record<string, BuyerCoupon>;

const initialState: TCouponState = {};

const couponSlice = createSlice({
  name: 'couponById',
  initialState,
  reducers: {
    recievedCoupons(_, { payload: coupons }: PayloadAction<BuyerCoupon[]>) {
      return coupons.reduce(
        (prev, coupon) => ({ ...prev, [coupon.id!]: coupon }),
        {} as TCouponState
      );
    },
    clearedCoupons() {
      return initialState;
    },
    redeemedCoupon(
      coupons,
      { payload: { buyerCouponId } }: PayloadAction<{ buyerCouponId: string }>
    ) {
      coupons[buyerCouponId].times_redeemed =
        (coupons[buyerCouponId].times_redeemed ?? 0) + 1;
      coupons[buyerCouponId].redemptions_left =
        (coupons[buyerCouponId].redemptions_left ?? 0) - 1;
    },
  },
});

export const {
  recievedCoupons,
  clearedCoupons,
  redeemedCoupon,
} = couponSlice.actions;
export default couponSlice.reducer;
